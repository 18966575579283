<template>
    <el-dialog 
        title="填写回款信息" 
        :visible.sync="backDialogVisible"
        width="550px"
        :lock-scroll="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        custom-class="back-dialog"
        :before-close="handleDialogClose"
        >
        <el-form 
            ref="backDialogForm" 
            :model="form" 
            :rules="rules" 
            label-width="102px"
            >
            <el-form-item label="回款日期" prop="refundDate">
                <el-date-picker
                    style="width: 100%"
                    v-model="form.refundDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择回款日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="回款金额" prop="refundAmount">
                <el-input v-model="form.refundAmount" autocomplete="off" maxlength="12"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleDialogClose" :loading="holdLoading">取消</el-button>
            <el-button type="primary" @click="handleHold" :loading="holdLoading">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
import floatingListService from "#/js/service/floatingListService.js";

export default {
    name: 'back-charge-dialog',
	components: {
	},
	data() {
		return {
            form: {
                invoiceId: '',
                refundDate: '',
                refundAmount: '',
            },
            rules: {
                refundDate: [
                    { required: true, message: '请选择回款日期', trigger: 'blur' },
                ],
                refundAmount: [
                    { required: true, message: '请填写回款金额', trigger: 'blur' },
                    { validator: this.validateAmount, trigger: ['blur', 'change'] }
                ],
            },
            backDialogVisible: false,
            type: '',
            isApplyNegative: false, // 是否为负数发票
            holdLoading: false,
        };
    },
    created() {

    },
	mounted() {
		
	},
	methods: {
        handleDialogClose() {
            this.$refs.backDialogForm.resetFields();
            this.backDialogVisible = false;
        },
        validateAmount(rule, value, callback) {
            let matchSalary = Number(value);
            if(isNaN(matchSalary)) {
                callback('必须为数字格式');
            } else {
                const regPos = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/; // 非负
                const regNeg = /^\-\d+(\.\d+)?$/; // 负数
                if(this.isApplyNegative) {
                    if(matchSalary == 0) {
                        callback();
                    }
                    if(!regNeg.test(matchSalary)){
                        callback('负数发票必须为负数');
                    }
                } else {
                    if(!regPos.test(matchSalary)){
                        callback('正数发票必须为零或者正数');
                    }
                }
                callback()
            }
        },
        handleHold() {
            this.$refs['backDialogForm'].validate((valid) => {
                if (valid) {
                    this.receiveFund();
                }
            });
            
        },
        show(id, type, isApplyNegative, invoiceAmount) {
            console.log(invoiceAmount)
            this.backDialogVisible = true;
            this.form.invoiceId = id;
            this.type = type;
            this.isApplyNegative = isApplyNegative;
            this.invoiceAmount = invoiceAmount;
        },
        receiveFund() {
            this.holdLoading = true;
            if(this.form.refundAmount != this.invoiceAmount) {
                shortTips(`回款金额不等于开票金额`);
                this.holdLoading = false;
                return;
            }
            floatingListService
                .receiveFund(this.form)
                .then((res) => {
                    shortTips(`操作成功！`);
                })
                .finally(() => {
                    this.holdLoading = false;
                    if(this.type) {
                        this.$parent.updateSubTable();
                    } else {
                        this.$parent.applySubTable = [];
                        this.$parent.expendsArray = [];
                        this.$parent.getApplyList();
                    }

                    this.$refs.backDialogForm.resetFields();
                    this.backDialogVisible = false;
                });
        },
    }
}
</script>
<style lang="scss" scope>

</style>