var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "开票详情",
        visible: _vm.dialogVisible,
        width: "380px",
        "before-close": _vm.handleClose,
        "custom-class": "invoice-dialog",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "dialog-body-wrap",
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "业绩", name: "performance" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "客户信息", name: "infos" },
              }),
              _vm.isFinancial
                ? _c("el-tab-pane", {
                    attrs: { label: "操作日志", name: "logs" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.activeTab == "performance"
            ? _c("div", { staticClass: "tab-container" }, [
                _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [_vm._v("入职时间：")]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(
                            _vm.formatDate(_vm.detail.entryDate)
                          ),
                        },
                      },
                      [_vm._v("2021-01-04")]
                    ),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [
                      _vm._v("入职确认证明中的入职时间："),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(
                            _vm.formatDate(_vm.detail.actualEntryDate)
                          ),
                        },
                      },
                      [_vm._v("2021-01-04")]
                    ),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [
                      _vm._v("过保(离职)日期："),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(
                            _vm.detail.applicationType == 0
                              ? _vm.formatDate(_vm.detail.overWarrantyDate)
                              : _vm.formatDate(_vm.detail.resignationDate)
                          ),
                        },
                      },
                      [_vm._v("2021-01-04")]
                    ),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [
                      _vm._v("合同约定回款日期："),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(
                            _vm.formatDate(_vm.detail.contractAgreedRefundDate)
                          ),
                        },
                      },
                      [_vm._v("2021-01-04")]
                    ),
                  ]),
                ]),
                _c("hr"),
                _c(
                  "div",
                  { staticClass: "block" },
                  _vm._l(_vm.offerSplits, function (item, index) {
                    return _c(
                      "div",
                      { key: "offerItem_" + index, staticClass: "p-line" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-l ellipsis",
                            attrs: { title: item.name },
                            domProps: { textContent: _vm._s(item.name) },
                          },
                          [_vm._v("BD")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-m ellipsis",
                            attrs: { title: item.userName },
                          },
                          [
                            _vm._v("姓名：\n                    "),
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(item.userName),
                                },
                              },
                              [_vm._v("jerry张攀")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-r ellipsis",
                            attrs: {
                              title:
                                "业绩金额：" +
                                _vm.currencyFilter(
                                  _vm.computedPercent * item.charge
                                ),
                            },
                          },
                          [
                            _vm._v("业绩金额："),
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.currencyFilter(
                                      _vm.computedPercent * item.charge
                                    )
                                  ),
                                },
                              },
                              [_vm._v("43,200.00")]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c("hr"),
                _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [_vm._v("开票比例：")]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(_vm.computedPercent * 100 + "%"),
                        },
                      },
                      [_vm._v("10%")]
                    ),
                  ]),
                  _vm.detail.paymentMethodType != 2
                    ? _c(
                        "div",
                        { staticClass: "row-line" },
                        [
                          _c("div", { staticClass: "row-l" }, [
                            _vm._v("提供入职证明："),
                          ]),
                          _vm.hasAttach
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row-r green",
                                    on: {
                                      click: () =>
                                        _vm.$emit("download-click", _vm.id, 0),
                                    },
                                  },
                                  [_vm._v("入职确认证明")]
                                ),
                              ]
                            : [
                                _c("div", { staticClass: "row-r" }, [
                                  _vm._v("无"),
                                ]),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.detail.applicationType == 1
                    ? _c(
                        "div",
                        { staticClass: "row-line" },
                        [
                          _c("div", { staticClass: "row-l" }, [
                            _vm._v("提供离职证明："),
                          ]),
                          _vm.hasLeavingAttach
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row-r green",
                                    on: {
                                      click: () =>
                                        _vm.$emit("download-click", _vm.id, 3),
                                    },
                                  },
                                  [_vm._v("离职确认证明")]
                                ),
                              ]
                            : [
                                _c("div", { staticClass: "row-r" }, [
                                  _vm._v("无"),
                                ]),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-line" },
                    [
                      _c("div", { staticClass: "row-l" }, [
                        _vm._v("提供付款通知函证明："),
                      ]),
                      _vm.hasPayAttach
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "row-r green",
                                on: {
                                  click: () =>
                                    _vm.$emit("download-click", _vm.id, 2),
                                },
                              },
                              [_vm._v("付款通知函确认证明")]
                            ),
                          ]
                        : [_c("div", { staticClass: "row-r" }, [_vm._v("无")])],
                    ],
                    2
                  ),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [_vm._v("PM备注：")]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(
                            _vm.detail.ownerRemarks
                              ? _vm.detail.ownerRemarks
                              : "无"
                          ),
                        },
                      },
                      [_vm._v("2021-01-04")]
                    ),
                  ]),
                  _vm.detail.applicationType == 1
                    ? _c("div", { staticClass: "row-line" }, [
                        _c("div", { staticClass: "row-l" }, [
                          _vm._v("负数发票抵扣方式："),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "row-r",
                            domProps: {
                              textContent: _vm._s(_vm.detail.deductionTypeName),
                            },
                          },
                          [_vm._v("抵扣其它单")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.detail.applicationType == 1 &&
                  _vm.detail.deductionType == 1
                    ? _c("div", { staticClass: "row-line" }, [
                        _c("div", { staticClass: "row-l" }, [
                          _vm._v("负数发票关联订单："),
                        ]),
                        _c("div", { staticClass: "row-r" }, [
                          _vm._v(
                            "候选人 " +
                              _vm._s(_vm.detail.deductionCandidateName) +
                              "——顾问 " +
                              _vm._s(_vm.detail.deductionOwnerName)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm.activeTab == "infos"
            ? _c("div", { staticClass: "tab-container" }, [
                _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "row-line bold" }, [
                    _vm._v("收件人信息"),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [_vm._v("收件人：")]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(_vm.detail.expressReceiver),
                        },
                      },
                      [_vm._v("小明小明小明")]
                    ),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [_vm._v("联系电话：")]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(_vm.detail.expressPhone),
                        },
                      },
                      [_vm._v("18118778552")]
                    ),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [
                      _vm._v("发票快递地址："),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(_vm.detail.expressAddress),
                        },
                      },
                      [
                        _vm._v(
                          "广东省深圳市南山区深圳湾科技生态园9栋B1座13层1312室"
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("hr"),
                _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "row-line bold" }, [
                    _vm._v("公司信息"),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [_vm._v("公司名称：")]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(_vm.detail.companyName),
                        },
                      },
                      [_vm._v("深圳八爪科技网络有限公司")]
                    ),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [
                      _vm._v("纳税人识别号："),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(_vm.detail.companyTaxpayerNo),
                        },
                      },
                      [_vm._v("678323724687")]
                    ),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [_vm._v("注册地址：")]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(_vm.detail.companyRegisterAddr),
                        },
                      },
                      [
                        _vm._v(
                          "广东省深圳市南山区深圳湾科技生态园9栋B1座13层1312室"
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [_vm._v("联系电话：")]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(_vm.detail.companyPhone),
                        },
                      },
                      [_vm._v("13609874657")]
                    ),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [_vm._v("开户银行：")]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(_vm.detail.companyBankofDeposit),
                        },
                      },
                      [_vm._v("中国人民银行")]
                    ),
                  ]),
                  _c("div", { staticClass: "row-line" }, [
                    _c("div", { staticClass: "row-l" }, [_vm._v("银行账号：")]),
                    _c(
                      "div",
                      {
                        staticClass: "row-r",
                        domProps: {
                          textContent: _vm._s(_vm.detail.companyBankCardNo),
                        },
                      },
                      [_vm._v("1098238498745638")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.activeTab == "logs"
            ? _c("div", { staticClass: "tab-container" }, [
                _c("div", { staticClass: "block" }, [
                  _c(
                    "div",
                    { staticClass: "body-scroll" },
                    [
                      _vm._l(_vm.operationLogs, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: "operationLog_" + index,
                            staticClass: "record-item",
                          },
                          [
                            _c("div", { staticClass: "pic" }, [
                              _c("img", {
                                attrs: { src: item.operatorAvatarUrl, alt: "" },
                              }),
                            ]),
                            _c("div", { staticClass: "content-list" }, [
                              _c("div", { staticClass: "item-row small" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "gray",
                                    domProps: {
                                      textContent: _vm._s(item.created),
                                    },
                                  },
                                  [_vm._v("2021-04-08 12:38:34")]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "item-row ellipsis",
                                  attrs: {
                                    title:
                                      item.operatorName +
                                      "@" +
                                      item.operatorNickName,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "black",
                                      domProps: {
                                        textContent: _vm._s(item.operatorName),
                                      },
                                    },
                                    [_vm._v("查明123")]
                                  ),
                                  _c("span", { staticClass: "gray" }, [
                                    _vm._v("@" + _vm._s(item.operatorNickName)),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "item-row" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "black",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.generateLogText(item)
                                      ),
                                    },
                                  },
                                  [_vm._v("申请开全款发票")]
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      _vm.operationLogs.length == 0
                        ? _c("div", { staticClass: "empty-data" }, [
                            _c("span", { staticClass: "empty-img" }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }