// [Display(Name =“已申请”)]
// ApplicationCreated = 0.
// [Display(Name =“已确认”)]
// ApplicationComfirmed = 1.
// [Display(Name =“已驳回”)]
// Rejected = 3,
// [Display(Name =“未回款”)]
// InvoiceCreated = 9.
// DDisplay(Name =“已回款”)]
// FundReceived = 13
// IDisplay(Name =“已取消”)]
// ApplicationCancel = 19.
// [Display(Name =“已作废”)]
// Voided = 29

// paymentMethodType:0  0全款,1首款,2尾款
// applicationType: 0, 0为正数，1为负数

// public enum InvoiceApplicationActionType : byte
// {
//     [Display(Name = "创建")]
//     Create = 0,

//     [Display(Name = "重新申请")]
//     ReApplication = 1,

//     [Display(Name = "编辑")]
//     EditApplication = 2,

//     [Display(Name = "开票")]
//     FillInvoice = 3,

//     [Display(Name = "回款")]
//     ReceiveFund = 4,

//     [Display(Name = "驳回")]
//     Reject = 5,

//     [Display(Name = "取消")]
//     Cancel = 6,

//     [Display(Name = "移交")]
//     Transfer = 7,

//     [Display(Name = "确认")]
//     Confirm = 8,

//     [Display(Name = "作废")]
//     Void = 9

export const invoiceStatusMap = {
    "0": "待确认",
    "1": "已确认待开票",
    "3": "已驳回",
    "9": "待回款",
    "13": "已回款",
    "19": "已取消",
    "29": "已作废",
};
export const paymentMethodTypeMap = {
    "0": "全款",
    "1": "首款",
    "2": "尾款",
};