<template>
<div class="work-table-container">
    <div class="work-table">
        <div class="filter-wrap" v-if="[3, 4].includes(filterIndex)">
        <!-- <div class="filter-wrap" v-if="[4].includes(filterIndex) || ([3].includes(filterIndex) && !isFinancial)"> -->
            <floatingListBubble
                id="bubble"
                ref="bubble"
                :bubbleType="filterIndex"
                :bubbleCount="statusBubbleCount"
                :selectedUserIds="selectedUserIds"
                :perDate="performanceDate"
                @bubble-click="bubbleClick"
                @bubble-clear="bubbleClear"
                @timeLimit-change="timeLimitChange"
                @member-change="memberChange"
                @export-detail="exportDetail"
                @select-change="selectChange"
                @candidata-search="candidataSearch"
                :export-loading="exportLoading"
            ></floatingListBubble>
        </div>
       
        <div class="select-container">
            <div class="float-left">
                <span>共
                    <span class="color-orange">{{ page.total }}</span>
                    条记录
                </span>
            </div>

            <div class="select-tag-list">
                <div class="select-tag" v-for="(item,tag) in applyLabelShowList" :key="tag" v-show="applyLabelShowList.length > 0">
                    <el-tooltip placement="top-start" effect="light" popper-class="tooltip-label">
                        <div slot="content" v-if="item == 'InvoiceAmount' || item == 'invoiceNumber' || item == 'courierNumber' || item == 'InvoiceActualTaxAmount' || item == 'CandidateName' || item == 'CompanyName'|| item == 'candidateName'|| item == 'recommendOwnerName'">{{ labelList[item][0].label }}</div>
                        <div slot="content" v-else>{{labelList[item] | labelsTip(item)}}</div>
                        <div class="text-ellipsis">
                            <span>{{ searchParamTitle[item] ? `${searchParamTitle[item]}：` : '' }} </span>
                            <span class="content" v-for="(param, index) in labelList[item]" :key="index">
                                <!-- {{ param.text }} -->
                                {{ param.label }}
                                <span v-if="labelList[item].length > 0 && index !== (labelList[item].length - 1)"> {{item == 'performanceDate' || item == 'financeInvoiceDate' ? ' ~ ' : ' , '}} </span>
                            </span>
                        </div>
                    </el-tooltip>
                    <span class="btn-close" @click="handleCleanLabel(item)"></span>
                </div>
            </div>
        </div>
        <!-- applyMultipleTable -->
        <el-table
            border
            height="700"
            style="width: 100%"
            ref="multipleTable"
            tooltip-effect="light"
            :data="applyTable"
            v-loading="applyTableLoading"
            @selection-change="handleApplySelectionChange"
            @expand-change="handleApplyExpandChange"
            :expand-row-keys="expendsArray"
            @current-change="toggleRowExpansion"
            :row-key="getRowKeys" >
            <!-- lazy
        :load="loadChildren"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}" -->
            <!-- 未申请表格 -->
            <template v-if="[2].includes(filterIndex)">
                <template v-for="(item, index) in applyTableTitle">
                    <el-table-column
                        :key="'applyTableTitle_0_' + index"
                        v-if="(item.prop != 'currencyType') ||  (item.prop == 'currencyType' && isTsdnuser)"
                        :label="item.label"
                        :width="item.width"
                        :min-width="item.minWidth"
                        :show-overflow-tooltip="item.showOverflowTooltip"
                        :label-class-name="item.labelClassName"
                        :align="item.label == '服务费' ? 'right': 'left'"
                        header-align="left"
                    >
                        <template slot="header">
                            <template v-if="item.prop == 'candidateName'">
                                <select-popover
                                    :enableCount="false"
                                    :enableSearch="true"
                                    :maxSelect="5"
                                    :selectItem="[]"
                                    @selectChange="unApplySelectDataChange"
                                    label="candidateName"
                                    title="候选人"
                                    type="SearchOnly"
                                ></select-popover>
                            </template>
    
                            <template v-else-if="item.prop == 'recommendOwnerName'">
                                <select-popover
                                    :enableCount="false"
                                    :enableSearch="true"
                                    :maxSelect="5"
                                    :selectItem="[]"
                                    @selectChange="unApplySelectDataChange"
                                    label="recommendOwnerName"
                                    title="推荐者"
                                    type="SearchOnly"
                                ></select-popover>
                            </template>
    
                            <template v-else>
                                <div class="table-slot">
                                    {{ item.label }}
                                </div>
                            </template>
                            
                        </template>
                        <template slot-scope="{ row }">
                            <!-- {{row[item.prop]}} -->
                            {{ columnFormatter(item.prop, row[item.prop]) }}
                        </template>
                    </el-table-column>
                </template>
            </template>

            <!-- Offer列表 开始 -->
            <template v-else-if="[4].includes(filterIndex)">
                <template v-for="(item, index) in applyTableTitle">
                    <el-table-column
                        :key="'applyTableTitle_1_' + index"
                        v-if="(item.prop != 'currencyType') ||  (item.prop == 'currencyType' && isTsdnuser)"
                        :label="item.label"
                        :width="item.width"
                        :min-width="item.minWidth"
                        :show-overflow-tooltip="item.showOverflowTooltip"
                        :label-class-name="item.labelClassName"
                        :align="item.label == '服务费' ? 'right': 'left'"
                        header-align="left"
                    >
                        <template slot="header">
                            <div class="table-slot">
                                {{ item.label }}
                            </div>
                        </template>
                        <template slot-scope="scope">
    
                            <template v-if="item.prop == 'yearOfExperience'">
                                <div class="table-slot table-slot-content">
                                    {{ scope.row.yearOfExperience + '年' }}
                                </div>
                            </template>
                            
                            <template v-else-if="item.prop == 'recommendDate'">
                                <div class="table-slot table-slot-content">
                                    {{ scope.row.recommendDate | formatDate }}
                                </div>
                            </template>
                            <template v-else-if="item.prop == 'recommendPM'">
                                <div class="center recommend-avatar">
                                    <avatar
                                        :userId="scope.row.jobOwnerId"
                                        :enableLink="false"
                                        :src="scope.row.jobOwnerAvatarUrl | avatarUrlFilter"
                                        size="sm"
                                    ></avatar>
                                </div>
                            </template>
                            <template v-else-if="item.prop == 'recommender'">
                                <div class="center recommend-avatar">
                                    <avatar
                                        :userId="scope.row.ownerId"
                                        :enableLink="false"
                                        :src="scope.row.recommenderAvatarUrl | avatarUrlFilter"
                                        size="sm"
                                    ></avatar>
                                </div>
                            </template>
                            <template v-else-if="item.prop == 'status'">
                                <span v-if="filterIndex == 1 && scope.row.status == 0 ">
                                    推荐待反馈
                                </span>
                                <span v-else>
                                    {{scope.row.status | formatStatus(scope.row)}}
                                </span>
                            </template>
                            <template v-else>
                                {{scope.row[item.prop]}}
                            </template>
    
                            <!-- {{ columnFormatter(item.prop, scope.row[item.prop]) }} -->
                        </template>
                    </el-table-column>
                </template>
                <!-- 业务分类 -->
                <el-table-column
                    v-if="isCFUser"
                    header-align="left"
                    min-width="120"
                    prop="businessClassificationName"
                    :show-overflow-tooltip="true"
                    label="业务分类">
                    <template slot-scope="scope">
                        {{ scope.row.businessClassificationName || '-' }}
                    </template>
                </el-table-column>
            </template>
            <!-- Offer列表 结束 -->


            <!-- 申请表格 -->
            <template v-else>
                <!-- 财务才有全选功能 -->
                <!-- fixed -->
                <el-table-column
                    v-if="isFinancial"
                    width="42"
                    align="center"
                    type="selection"
                    header-align="center"
                ></el-table-column>

                <template v-for="(item, index) in applyTableTitle.slice(0, 11)">
                    <el-table-column
                        :key="'applyTableTitle' + index"
                        v-if="(item.prop != 'currencyType') ||  (item.prop == 'currencyType' && isTsdnuser)"
                        :label="item.label"
                        :width="item.width"
                        :min-width="item.minWidth"
                        :show-overflow-tooltip="item.showOverflowTooltip"
                        :label-class-name="item.labelClassName"
                        :resizable="false"
                    >
                        <template slot="header" slot-scope="scope">
                            <template v-if="!isFinancial || item.label == '开票原因' || item.label == '收款责任人'|| item.label == '申请时间' || item.label == '币种'">
                                <div class="table-slot">
                                    {{ item.label }}
                                </div>
                            </template>
                            <template v-else>
                                <el-popover
                                    v-if="item.label == '更新时间'"
                                    placement="bottom"
                                    width="100%"
                                    trigger="click"
                                    ref="popoverTime"
                                    :popper-class="`el-time-popover ${popoverClass}`"
                                >
                                    <div class="date-range">
                                        <el-date-picker
                                            size="small"
                                            align="left"
                                            type="daterange"
                                            value-format="yyyy-MM-dd"
                                            :editable="false"
                                            :clearable="false"
                                            range-separator="-"
                                            class="performance-date-input"
                                            v-model="performanceDate"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            @change="changePerformanceDate('created')"
                                            :picker-options="performanceDateOption"
                                            popper-class="performance-date-range"
                                        ></el-date-picker>
                                        <div class="date-input-icon"></div>
                                    </div>
                                    <div class="table-slot" slot="reference">
                                        {{ item.label }}
                                        <i class="el-icon-arrow-down el-icon--right"></i>
                                    </div>
                                </el-popover>
                                <!-- <select-popover
                                    v-else-if="item.label == '客户名称'"
                                    :enableSearch="true"
                                    :enableCount="true"
                                    :label="'customerNames'"
                                    :selectItem="headerFilter.customers"
                                    :title="item.label"
                                    :maxSelect="5"
                                    @selectChange="selectDataChange"
                                ></select-popover> -->
                                <selectPopover
                                    v-else-if="item.label == '客户名称'"
                                    :enableSearch="true"
                                    :enableCount="true"
                                    :selectItem="[]"
                                    type="SearchOnly"
                                    :label="'CompanyName'"
                                    :title="item.label"
                                    :arrowShow="applyTableTitle.length > 0"
                                    @selectChange="selectDataChange"
                                ></selectPopover>
                                <select-popover
                                    v-else-if="item.label == '收款责任人Leader'"
                                    :enableSearch="true"
                                    :label="'responsibleLeaders'"
                                    :selectItem="headerFilter.responsibleLeaders"
                                    :title="item.label"
                                    :maxSelect="5"
                                    @selectChange="selectDataChange"
                                ></select-popover>
                                <selectPopover
                                    v-else-if="item.label == '服务费'"
                                    :enableSearch="true"
                                    :enableCount="true"
                                    :selectItem="[]"
                                    type="SearchOnly"
                                    :label="'InvoiceAmount'"
                                    :title="item.label"
                                    :arrowShow="applyTableTitle.length > 0"
                                    @selectChange="selectDataChange"
                                ></selectPopover>
                                <el-dropdown
                                    v-else
                                    trigger="click"
                                    class="table-header-dropdown"
                                    @command="handleCommand"
                                    placement="bottom"
                                >
                                    <span class="table-slot el-dropdown-link">
                                        {{ item.label }}
                                        <i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
    
                                    <el-dropdown-menu 
                                        slot="dropdown" 
                                        class="table-header-dropdown-menu" 
                                        :visible-arrow="false" 
                                        v-if="item.label == '合同主体'">
                                        <el-dropdown-item 
                                            :class="invoiceEntity == '' ? 'el-dropdown-item-selected' : ''" :command="composeValue('','invoiceEntity')"
                                            >全部</el-dropdown-item>
                                        <el-dropdown-item 
                                            v-for="(item,index) in invoiceEntityArray" 
                                            :key="'entiry_'+ index" 
                                            :class="invoiceEntity == item ? 'el-dropdown-item-selected' : ''" 
                                            :command="composeValue(item,'invoiceEntity')"
                                        >
                                            {{ item }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                    <el-dropdown-menu slot="dropdown" class="table-header-dropdown-menu" :visible-arrow="false" v-else-if="item.label == '发票类型'">
                                        <el-dropdown-item :class="invoiceType == '' ? 'el-dropdown-item-selected' : ''" :command="composeValue('','invoiceType')">全部</el-dropdown-item>
                                        <el-dropdown-item :class="invoiceType == '0' ? 'el-dropdown-item-selected' : ''" :command="composeValue('0','invoiceType')">普票</el-dropdown-item>
                                        <el-dropdown-item :class="invoiceType == '1' ? 'el-dropdown-item-selected' : ''" :command="composeValue('1','invoiceType')">专票</el-dropdown-item>
                                    </el-dropdown-menu>
                                    <el-dropdown-menu slot="dropdown" class="table-header-dropdown-menu" :visible-arrow="false" v-else-if="item.label == '开票内容'">
                                        <el-dropdown-item :class="InvoiceContent == '' ? 'el-dropdown-item-selected' : ''" :command="composeValue('','InvoiceContent')">全部</el-dropdown-item>
                                        <el-dropdown-item :class="InvoiceContent == '服务费' ? 'el-dropdown-item-selected' : ''" :command="composeValue('服务费','InvoiceContent')">服务费</el-dropdown-item>
                                        <el-dropdown-item :class="InvoiceContent == '招聘服务费' ? 'el-dropdown-item-selected' : ''" :command="composeValue('招聘服务费','InvoiceContent')">招聘服务费</el-dropdown-item>
                                        <el-dropdown-item :class="InvoiceContent == '咨询服务费' ? 'el-dropdown-item-selected' : ''" :command="composeValue('咨询服务费','InvoiceContent')">咨询服务费</el-dropdown-item>
                                        <el-dropdown-item :class="InvoiceContent == '其他人力资源服务费' ? 'el-dropdown-item-selected' : ''" :command="composeValue('其他人力资源服务费','InvoiceContent')">其他人力资源服务费</el-dropdown-item>
                                        <el-dropdown-item :class="InvoiceContent == '信息服务费4' ? 'el-dropdown-item-selected' : ''" :command="composeValue('信息服务费','InvoiceContent')">信息服务费</el-dropdown-item>
                                        <el-dropdown-item :class="InvoiceContent == '软件服务费' ? 'el-dropdown-item-selected' : ''" :command="composeValue('软件服务费','InvoiceContent')">软件服务费</el-dropdown-item>
                                        <el-dropdown-item :class="InvoiceContent == '技术服务费' ? 'el-dropdown-item-selected' : ''" :command="composeValue('技术服务费','InvoiceContent')">技术服务费</el-dropdown-item>
                                    </el-dropdown-menu>
    
                                    <!-- <el-dropdown-menu 
                                        slot="dropdown" 
                                        class="table-header-dropdown-menu" 
                                        :visible-arrow="false" 
                                        v-else-if="item.label == '更新时间'"
                                        >
                                        <el-dropdown-item 
                                            :class="updateInvoiceDate == '1' ? 'el-dropdown-item-selected' : ''" :command="composeValue('1','updateInvoiceDate')"
                                            >更新时间 降序排列</el-dropdown-item>
                                        <el-dropdown-item 
                                            :class="updateInvoiceDate == '2' ? 'el-dropdown-item-selected' : ''" 
                                            :command="composeValue('2','updateInvoiceDate')"
                                            >更新时间 升序排列
                                        </el-dropdown-item>
                                    </el-dropdown-menu> -->
                                </el-dropdown>
                            </template> 
                        </template>
                        <template slot-scope="{ row }">
                            <template v-if="item.prop == 'created'">
                                <!-- {{ columnFormatter(item.prop, row[item.prop]) }} -->
                                {{ columnFormatter(item.prop, row.minApplicationTime) }}
                            </template>
                            <!-- 服务费 -->
                            <template v-else-if="item.prop == 'coverCharge'">
                                {{ columnFormatter('coverCharge', row.invoiceAmount || 0) }}
                            </template>
                            <template v-else-if="item.prop == 'invoiceReason'">
                                <span v-if="row[item.prop] == 0">候选人离职</span>
                                <span v-if="row[item.prop] == 1">客户信息有误</span>
                                <span v-if="row[item.prop] == 2">其他</span>
                            </template>
                            <template v-else-if="item.prop == 'pmLastOperationTime'">
                                {{columnFormatter('pmLastOperationTime', row.minPMLastOperationTime)}} ~ {{columnFormatter('pmLastOperationTime', row.maxPMLastOperationTime)}}
                            </template>
                            <template v-else-if="item.prop == 'invoiceTypeName'">
                                {{ row.invoiceTypeNames }}
                            </template>
                            <template v-else>
                                {{ row[item.prop] }}
                            </template>
                        </template>
                    </el-table-column>
                </template>


                <el-table-column
                    align="center"
                    class-name="hide-expand-cell"
                    width="1"
                    type="expand"
                    :resizable="false"
                    :key="Math.random()">
                    <template slot-scope="{ row, $index }">
                        <!-- 申请行expand组件 -->
                        <!-- 两种类型 sub-table-expand -->
                        <template v-if="expandType == 'candidate' && currentIndex == $index">
                            <apply-table-row-expand
                                :row-data="row"
                                @download-click="handleDownloadClick"
                            />
                        </template>

                        <template v-if="expandType == 'invoicingType' && currentIndex == $index">

                            <!-- ------------------------------------ -->
                            <!-- row-class-name="sub-row" -->
                            <!-- 子表格 -->
                            <el-table
                                border
                                style="width: 100%"
                                ref="multipleTableSub"
                                tooltip-effect="light"
                                :show-header="false"
                                :data="applySubTable"
                                v-loading="applySubTableLoading"
                                :row-class-name="tableRowClassName"
                                class="sub-table"
                                
                                :expand-row-keys="subExpendsArray"
                                :row-key="getRowKeys" >
                                <el-table-column
                                    v-if="isFinancial"
                                    width="42"
                                    align="center"
                                    type="selection"
                                    :selectable="()=> {return false}"
                                    header-align="center"
                                ></el-table-column>
                                 <!-- + (isFinancial ? 42: 0) -->
                                <template v-for="(item, index) in applyTableTitle.slice(0, 11)">
                                    <el-table-column
                                        :key="'applyTableTitle_sub1_' + index"
                                        v-if="(item.prop != 'currencyType') ||  (item.prop == 'currencyType' && isTsdnuser)"
                                        :label="item.label"
                                        :width="(index == 0) ? (+item.width -1) : item.width"
                                        :min-width="item.minWidth"
                                        :show-overflow-tooltip="item.showOverflowTooltip"
                                        :label-class-name="item.labelClassName" >
                                        <template slot="header">
                                            <div class="table-slot">
                                                {{ item.label }}
                                            </div>
                                        </template>
                                        
                                        <template slot-scope="{ row }">
                                            <template v-if="item.prop == 'created'">
                                                {{ columnFormatter(item.prop, row[item.prop]) }}
                                            </template>
                                            <!-- 服务费 -->
                                            <template v-else-if="item.prop == 'coverCharge'">
                                                {{ columnFormatter('coverCharge', row.invoiceAmount || 0) }}
                                            </template>
                                            <template v-else-if="item.prop == 'invoiceReason'">
                                                <span v-if="row[item.prop] == 0">候选人离职</span>
                                                <span v-if="row[item.prop] == 1">客户信息有误</span>
                                                <span v-if="row[item.prop] == 2">其他</span>
                                            </template>
                                            <template v-else>{{ row[item.prop] }}</template>
                                        </template>
                                    </el-table-column>
                                </template>

                                <el-table-column
                                    align="center"
                                    class-name="hide-expand-cell"
                                    width="1"
                                    type="expand" >
                                    <template slot-scope="{ row }">
                                        <apply-table-row-expand
                                            :row-data="row"
                                            @download-click="handleDownloadClick" />
                                    </template>
                                    
                                </el-table-column>

                                <el-table-column
                                    align="left"
                                    :show-overflow-tooltip="true"
                                    :label="applyTableTitle[11].label"
                                    :width="applyTableTitle[11].width"
                                    :min-width="applyTableTitle[11].minWidth"
                                    class-name="reset-expand-cell"
                                    :prop="applyTableTitle[11].prop" 
                                    :row-key="getRowKeys">
                                    <template slot="header">
                                        <div class="table-slot">
                                            {{ applyTableTitle[11].label }}
                                        </div>
                                    </template>
                                    <template slot-scope="{ row, $index }">
                                        <!-- <span
                                            class="ex-cell"
                                            :class="{
                                                'cell-expanded': (subExpandType == 'candidate' && subCurrentIndex == $index),
                                            }"
                                            @click="handSubCellExpand(row, 'candidate', $index)"
                                            >
                                            <i class="el-icon el-icon-arrow-right"></i>
                                            {{ row[applyTableTitle[10].prop] }}
                                        </span> -->

                                        <span
                                            class="ex-cell"
                                            @click="candidateClick(row, $index)"
                                            >
                                            <img class="doc-icon" src="@src/assets/images/icon/doc-icon.png" alt="doc">{{row[applyTableTitle[11].prop]}}
                                        </span>
                                    </template>
                                </el-table-column>

                                <!-- 推荐ID -->
                                <el-table-column
                                    prop="recommendId"
                                    label="推荐ID"
                                    width="350">
                                    <template slot="header">
                                        <div class="table-slot">
                                            推荐ID
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    align="left"
                                    :show-overflow-tooltip="true"
                                    :label="applyTableTitle[12].label"
                                    :width="applyTableTitle[12].width"
                                    :min-width="applyTableTitle[12].minWidth"
                                    class-name="reset-expand-cell"
                                    :prop="applyTableTitle[12].prop" >
                                    <template slot="header">
                                        <div class="table-slot">
                                            {{ applyTableTitle[12].label }}
                                        </div>
                                    </template>
                                    <template slot-scope="{ row }">
                                        <!-- {{ row[applyTableTitle[12].prop] }} -->
                                        {{getInvoiceStatus(row)}}
                                        <span class="ex-cell">
                                            <span class="tip-icon color-c" v-if="row.hasEdit">改</span>
                                        </span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    align="left"
                                    class-name="reset-expand-cell"
                                    :show-overflow-tooltip="true"
                                    :label="applyTableTitle[13].label"
                                    :width="applyTableTitle[13].width"
                                    :min-width="applyTableTitle[13].minWidth"
                                    :prop="applyTableTitle[13].prop"
                                >   
                                    <template slot-scope="{ row }">
                                        <span
                                            class="ex-cell"
                                            v-html="generateInvoiceTypeCell(row, 'sub')" >
                                        </span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    align="left"
                                    :show-overflow-tooltip="true"
                                    :label="applyTableTitle[14].label"
                                    :width="applyTableTitle[14].width"
                                    :min-width="applyTableTitle[14].minWidth"
                                    class-name="name-cell"
                                    :prop="applyTableTitle[14].prop"
                                    label-class-name="bg-orange-dark"
                                >

                                    <template slot-scope="{ row }"  v-if="row.invoiceActualAmount">
                                        {{ columnFormatter('invoiceAmount' ,(row.invoiceActualAmount || 0) + (row.invoiceTaxAmount || 0) ) }}
                                        <el-dropdown
                                            ref="resumeOperation"
                                            placement="bottom-start"
                                            trigger="hover"
                                            :hide-on-click="false" >
                                            <span class="operation-icon">
                                                <font-icon
                                                    class="table-body-icon operation-icon-default"
                                                    href="#icon-ic_act_grey"
                                                ></font-icon>
                                                <font-icon
                                                    class="table-body-icon operation-icon-hover"
                                                    href="#icon-ic_act_green"
                                                ></font-icon>
                                            </span>
                                            <el-dropdown-menu
                                                class="operation-menu"
                                                slot="dropdown" >
                                                <el-dropdown-item command="addToTrackingList" >
                                                    服务费 {{columnFormatter('invoiceAmount', row.invoiceActualAmount || 0) }}
                                                </el-dropdown-item>
                                                <el-dropdown-item command="addToTrackingList" >
                                                    税金 {{columnFormatter('invoiceAmount', row.invoiceTaxAmount || 0) }}
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    v-for="(item, index) in applyTableTitle.slice(15)"
                                    :key="'applyTableTitle_2_' + index"
                                    :label="item.label"
                                    :width="item.width"
                                    :min-width="item.minWidth"
                                    :show-overflow-tooltip="item.showOverflowTooltip"
                                    :label-class-name="item.labelClassName">
                                    <template slot="header">
                                        <div class="table-slot">
                                            {{ item.label }}
                                        </div>
                                    </template>
                                    <!-- <template slot-scope="{ row }">
                                        {{ row[item.prop] }}
                                    </template> -->
                                    <template slot-scope="{ row }">
                                        <template v-if="['financeInvoiceDate', 'refundDate', 'refundAmount'].includes(item.prop)"> 
                                            {{ columnFormatter(item.prop, row[item.prop]) }}
                                        </template>
                                        <template v-else-if="item.prop == 'financeAttmatchs'">
                                            <div class="cell-btn-wrap">
                                                <a href="javascript:;" 
                                                    class="apply-ticket" 
                                                    v-if="row.hasFinanceAttachments"
                                                    @click="seeAttachments(row.id)"
                                                    >查看附件</a>
                                                <span v-else>-</span>
                                            </div>
                                        </template>
                                        <template v-else>
                                            {{ row[item.prop] }}
                                        </template>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    label="操作"
                                    :width="isShrink ? '79' : '319'"
                                    fixed="right"
                                    class-name="btn-menu"
                                    
                                >
                                    <template slot="header">
                                        <div class="table-slot">操作</div>
                                    </template>
                                    <template slot-scope="{ row }">
                                        <div class="sm-drop" v-if="isShrink && ( (([0,3, 9].includes(row.applicationStatus)|| row.isCanCreateNegative || row.isCanCreateBalance) && isFinancial) || (([0, 3, 19].includes(row.applicationStatus) || row.isCanCreateNegative || row.isCanCreateBalance) && isPM) )">
                                            <el-dropdown>
                                                <span class="el-dropdown-link name-cell" >
                                                    <span class="operation-icon">
                                                        <font-icon class="table-body-icon-big operation-icon-default tip-margin" href="#icon-ic_act_grey"></font-icon>
                                                        <font-icon class="table-body-icon-big operation-icon-hover tip-margin" href="#icon-ic_act_green"></font-icon>
                                                    </span>
                                                </span>
                                                <el-dropdown-menu class="shrink-dropdown-menu" slot="dropdown" >
                                                    <!-- 未处理或推荐待反馈状态 -->
                                                    <template v-if="isFinancial">
                                                        <el-dropdown-item 
                                                            v-if="row.applicationStatus ==0"
                                                            @click.native="applicationConfirm(row, 'isSub')"
                                                            >确认开票信息</el-dropdown-item>
                                                        <el-dropdown-item 
                                                            v-if="row.applicationStatus ==1"
                                                            @click.native="fillInvoiceInfo(row, 'isSub')"
                                                            >填写开票信息</el-dropdown-item>
                                                        <el-dropdown-item 
                                                            v-if="row.applicationStatus ==9"
                                                            @click.native="fillBackInfo(row, 'isSub')"
                                                            >填写回款信息</el-dropdown-item>
                                                        <el-dropdown-item 
                                                            v-if="row.applicationStatus ==1"
                                                            @click.native="rejectApply(row, 'isSub')"
                                                            >驳回</el-dropdown-item>
                                                        <el-dropdown-item 
                                                            v-if="[0, 3, 9].includes(row.applicationStatus)"
                                                            @click.native="editApply(row, 'isSub')"
                                                            >修改</el-dropdown-item>
                                                            
                                                        <!-- <el-dropdown-item 
                                                            v-if="[0, 3, 9].includes(row.applicationStatus)"
                                                            @click.native="editApply(row, 'isSub')"
                                                            >修改</el-dropdown-item>
                                                        <el-dropdown-item 
                                                            v-if="row.isCanCreateNegative"
                                                            @click.native="handleApplyNegative(row, 'isSub')"
                                                            >申请负数发票</el-dropdown-item>
                                                        <el-dropdown-item 
                                                            v-if="row.isCanCreateBalance"
                                                            @click.native="handleApplyBalance(row, 'isSub')"
                                                            >申请尾款发票</el-dropdown-item> -->
                                                    </template>
                                                    <template v-if="isPM">
                                                        <el-dropdown-item 
                                                            v-if="[0].includes(row.applicationStatus)"
                                                            @click.native="cancelApply(row, 'isSub')"
                                                            >取消申请</el-dropdown-item>
                                                        <el-dropdown-item 
                                                            v-if="[0].includes(row.applicationStatus)"
                                                            @click.native="editApply(row, 'isSub')"
                                                            >修改</el-dropdown-item>
                                                        <el-dropdown-item 
                                                            v-if="[3, 19].includes(row.applicationStatus)"
                                                            @click.native="reApply(row, 'isSub')"
                                                            >重新申请</el-dropdown-item>
                                                        <el-dropdown-item 
                                                            v-if="[29].includes(row.applicationStatus) && [0,1].includes(row.paymentMethodType) && row.isCanReCreateApplication"
                                                            @click="reCreateApply(row, 'isSub')"
                                                            >重新开票</el-dropdown-item>
                                                        <el-dropdown-item 
                                                            v-if="[9, 13].includes(row.applicationStatus) && row.applicationType==0 && row.isCanCreateNegative"
                                                            @click.native="handleApplyNegative(row, 'isSub')"
                                                            >申请负数发票</el-dropdown-item>
                                                        <el-dropdown-item 
                                                            v-if="[1, 9, 13].includes(row.applicationStatus) && row.paymentMethodType==1 && row.applicationType == 0 && row.isCanCreateBalance"
                                                            @click.native="handleApplyBalance(row, 'isSub')"
                                                            >申请尾款发票</el-dropdown-item>
                                                    </template>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </div>

                                        <div class="cell-btn-wrap" v-else>
                                            <template>
                                                <template v-if="isFinancial">
                                                    <a href="javascript:;" 
                                                        class="apply-ticket" 
                                                        v-if="row.applicationStatus == 0"
                                                        @click="applicationConfirm(row, 'isSub')"
                                                        >确认开票信息</a >
                                                    <a href="javascript:;" 
                                                        class="apply-ticket" 
                                                        v-if="row.applicationStatus ==1"
                                                        @click="fillInvoiceInfo(row, 'isSub')"
                                                        >填写开票信息</a >
                                                    <a href="javascript:;" 
                                                        class="apply-ticket" 
                                                        v-if="row.applicationStatus ==9"
                                                        @click="fillBackInfo(row, 'isSub')"
                                                        >填写回款信息</a >
                                                    <a href="javascript:;" 
                                                        class="apply-ticket orange" 
                                                        v-if="row.applicationStatus ==1"
                                                        @click="rejectApply(row, 'isSub')"
                                                        >驳回</a >

                                                     <a href="javascript:;" 
                                                        v-if="[0, 3].includes(row.applicationStatus)"
                                                        class="apply-ticket" 
                                                        @click="editApply(row, 'isSub')">修改</a >
                                                    <!--
                                                    <a href="javascript:;" 
                                                        v-if="row.isCanCreateNegative"
                                                        class="apply-ticket orange" 
                                                        @click="handleApplyNegative(row, 'isSub')"
                                                        >申请负数发票</a>
                                                    <a href="javascript:;" 
                                                        v-if="row.isCanCreateBalance"
                                                        class="apply-ticket" 
                                                        @click="handleApplyBalance(row, 'isSub')"
                                                        >申请尾款发票</a>  -->
                                                </template>
                                                <template v-if="isPM">
                                                    <a href="javascript:;" 
                                                        class="apply-ticket" 
                                                        v-if="[0].includes(row.applicationStatus)"
                                                        @click="cancelApply(row, 'isSub')">取消申请</a >
                                                    <a href="javascript:;" 
                                                        v-if="[0].includes(row.applicationStatus)"
                                                        class="apply-ticket" 
                                                        @click="editApply(row, 'isSub')">修改</a >
                                                    <a href="javascript:;" 
                                                        v-if="[3, 19].includes(row.applicationStatus)"
                                                        class="apply-ticket" 
                                                        @click="reApply(row, 'isSub')">重新申请</a >
                                                    <a href="javascript:;" 
                                                        v-if="[29].includes(row.applicationStatus) && [0,1].includes(row.paymentMethodType) && row.isCanReCreateApplication"
                                                        class="apply-ticket" 
                                                        @click="reCreateApply(row, 'isSub')">重新开票</a>
                                                    <a href="javascript:;" 
                                                        v-if="[9, 13].includes(row.applicationStatus) && row.applicationType==0 && row.isCanCreateNegative"
                                                        class="apply-ticket orange" 
                                                        @click="handleApplyNegative(row, 'isSub')"
                                                        >申请负数发票</a>
                                                    <a href="javascript:;" 
                                                        v-if="[1, 9, 13].includes(row.applicationStatus) && row.paymentMethodType==1 && row.applicationType == 0 && row.isCanCreateBalance"
                                                        class="apply-ticket" 
                                                        @click="handleApplyBalance(row, 'isSub')"
                                                        >申请尾款发票</a>
                                                </template>
                                            </template>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- ------------------------------------ -->


                        </template>
                    </template>
                </el-table-column>
                
                <!-- 候选人 -->
                <el-table-column
                    align="left"
                    :show-overflow-tooltip="true"
                    :label="applyTableTitle[11].label"
                    :width="applyTableTitle[11].width"
                    :min-width="applyTableTitle[11].minWidth"
                    class-name="reset-expand-cell"
                    :resizable="false"
                    :prop="applyTableTitle[11].prop">
                    <template slot="header" slot-scope="scope">
                        <template v-if="!isFinancial">
                            <div class="table-slot">
                                {{ applyTableTitle[11].label }}
                            </div>
                        </template>
                        <selectPopover
                            v-else
                            :enableSearch="true"
                            :enableCount="true"
                            :selectItem="[]"
                            type="SearchOnly"
                            :label="'CandidateName'"
                            :title="applyTableTitle[11].label"
                            :arrowShow="applyTableTitle.length > 0"
                            @selectChange="selectDataChange"
                        ></selectPopover>
                    </template>
                    <template slot-scope="{ row, $index }">
                        <span
                            class="ex-cell"
                            @click="candidateClick(row, $index)"
                            >
                            <img class="doc-icon" src="@src/assets/images/icon/doc-icon.png" alt="doc">{{row[applyTableTitle[11].prop]}}
                        </span>
                    </template>
                </el-table-column>

                <!-- 推荐ID -->
                <el-table-column
                    prop="recommendId"
                    label="推荐ID"
                    :resizable="false"
                    width="350">
                    <template slot="header">
                        <div class="table-slot">
                            推荐ID
                        </div>
                    </template>
                </el-table-column>

                <!-- 当前状态 -->
                <el-table-column
                    align="left"
                    :show-overflow-tooltip="true"
                    :label="applyTableTitle[12].label"
                    :width="applyTableTitle[12].width"
                    :min-width="applyTableTitle[12].minWidth"
                    :resizable="false"
                    class-name="reset-expand-cell"
                    :prop="applyTableTitle[12].prop">
                    <template slot="header" slot-scope="scope">
                        <template v-if="!isFinancial">
                            <div class="table-slot">
                                {{ applyTableTitle[12].label }}
                            </div>
                        </template>
                        <template v-else>
                            <el-dropdown
                                trigger="click"
                                class="table-header-dropdown"
                                @command="handleCommand"
                                placement="bottom"
                            >
                                <span class="table-slot el-dropdown-link">
                                    {{ applyTableTitle[12].label }}
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" class="table-header-dropdown-menu" :visible-arrow="false">
                                    <el-dropdown-item :class="ApplicationStatus == '' ? 'el-dropdown-item-selected' : ''" :command="composeValue('','ApplicationStatus')">全部</el-dropdown-item>
                                    <el-dropdown-item :class="ApplicationStatus == '0' ? 'el-dropdown-item-selected' : ''" :command="composeValue('0','ApplicationStatus')">待确认</el-dropdown-item>
                                    <el-dropdown-item :class="ApplicationStatus == '1' ? 'el-dropdown-item-selected' : ''" :command="composeValue('1','ApplicationStatus')">已确认待开票</el-dropdown-item>
                                    <el-dropdown-item :class="ApplicationStatus == '9' ? 'el-dropdown-item-selected' : ''" :command="composeValue('9','ApplicationStatus')">待回款</el-dropdown-item>
                                    <el-dropdown-item :class="ApplicationStatus == '13' ? 'el-dropdown-item-selected' : ''" :command="composeValue('13','ApplicationStatus')">已回款</el-dropdown-item>
                                    <el-dropdown-item :class="ApplicationStatus == '3' ? 'el-dropdown-item-selected' : ''" :command="composeValue('3','ApplicationStatus')">已驳回</el-dropdown-item>
                                    <el-dropdown-item :class="ApplicationStatus == '19' ? 'el-dropdown-item-selected' : ''" :command="composeValue('19','ApplicationStatus')">已取消</el-dropdown-item>
                                    <el-dropdown-item :class="ApplicationStatus == '29' ? 'el-dropdown-item-selected' : ''" :command="composeValue('29','ApplicationStatus')">已作废</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </template>
                    <template slot-scope="{ row }">
                        <!-- {{ row[applyTableTitle[12].prop] }} -->
                        <!-- {{getInvoiceStatus(row)}} -->
                        <span class="ex-cell">
                            <span class="tip-icon color-c" v-if="row.hasEdit">改</span>
                            
                            <span class="tip-icon color-a" v-if="row.refApplicationStatus.includes(0)">待确认</span>
                            <span class="tip-icon color-b" v-if="row.refApplicationStatus.includes(1)">待开票</span>
                            <span class="tip-icon color-c" v-if="row.refApplicationStatus.includes(9)">待回款</span>
                            
                            <span class="tip-icon color-d" v-if="row.refApplicationStatus.includes(3)">已驳回</span>
                            <span class="tip-icon" v-if="row.refApplicationStatus.includes(13)">已回款</span>
                            <span class="tip-icon color-d" v-if="row.refApplicationStatus.includes(19)">已取消</span>
                            <span class="tip-icon color-void" v-if="row.refApplicationStatus.every(it => it==29)">已作废</span>
                        </span>
                    </template>
                </el-table-column>

                <!-- 开票类型 -->
                <el-table-column
                    align="left"
                    :show-overflow-tooltip="true"
                    :label="applyTableTitle[13].label"
                    :width="applyTableTitle[13].width"
                    :min-width="applyTableTitle[13].minWidth"
                    class-name="reset-expand-cell"
                    :resizable="false"
                    :prop="applyTableTitle[13].prop">   
                    <template slot="header" slot-scope="scope">
                        <el-dropdown
                            v-if="isFinancial"
                            trigger="click"
                            class="table-header-dropdown"
                            @command="handleCommand"
                            placement="bottom"
                        >
                            <span class="table-slot el-dropdown-link table-header-tip-cell">
                                <el-popover
                                    :offset="5"
                                    width="156"
                                    trigger="hover"
                                    :visible-arrow="false"
                                    placement="bottom-start"
                                    popper-class="talentpool-name-popover talentpool-popover"
                                >
                                    <div class="popover-card">
                                        <div class="card-tip">
                                            <span class="row-l"><span class="tip-icon">尾</span></span>
                                            尾款
                                        </div>
                                        <div class="card-tip">
                                            <span class="row-l"><span class="tip-icon color-a">负</span></span>
                                            负数发票
                                        </div>
                                        <div class="card-tip">
                                            <span class="row-l"><span class="tip-icon color-b">取消</span></span>
                                            取消申请
                                        </div>
                                        <div class="card-tip">
                                            <span class="row-l"><span class="tip-icon color-c">驳回</span></span>
                                            驳回申请
                                        </div>
                                    </div>
                                    <span slot="reference" class="icon-question">
                                        <font-icon class="table-header-tip-icon question-hover" href="#icon-talent_ql"></font-icon>
                                        <font-icon class="table-header-tip-icon question-no-hover" href="#icon-talent_qd"></font-icon>
                                    </span>
                                </el-popover>
                                {{ applyTableTitle[13].label }}
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="table-header-dropdown-menu" :visible-arrow="false">
                                <el-dropdown-item :class="PaymentMethodType == '' ? 'el-dropdown-item-selected' : ''" :command="composeValue('','PaymentMethodType')">全部</el-dropdown-item>
                                <el-dropdown-item :class="PaymentMethodType == '0' ? 'el-dropdown-item-selected' : ''" :command="composeValue('0','PaymentMethodType')">全款</el-dropdown-item>
                                <el-dropdown-item :class="PaymentMethodType == '1' ? 'el-dropdown-item-selected' : ''" :command="composeValue('1','PaymentMethodType')">首款</el-dropdown-item>
                                <el-dropdown-item :class="PaymentMethodType == '2' ? 'el-dropdown-item-selected' : ''" :command="composeValue('2','PaymentMethodType')">尾款</el-dropdown-item>
                                <el-dropdown-item :class="PaymentMethodType == '-1' ? 'el-dropdown-item-selected' : ''" :command="composeValue('-1','PaymentMethodType')">负数发票</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <div class="table-slot" v-else>
                            <span class="table-header-tip-cell">
                                {{ applyTableTitle[13].label }}
                                <el-popover
                                    :offset="5"
                                    width="156"
                                    trigger="hover"
                                    :visible-arrow="false"
                                    placement="bottom-start"
                                    popper-class="talentpool-name-popover talentpool-popover"
                                >
                                    <div class="popover-card">
                                        <div class="card-tip">
                                            <span class="row-l"><span class="tip-icon">尾</span></span>
                                            尾款
                                        </div>
                                        <div class="card-tip">
                                            <span class="row-l"><span class="tip-icon color-a">负</span></span>
                                            负数发票
                                        </div>
                                        <div class="card-tip">
                                            <span class="row-l"><span class="tip-icon color-b">取消</span></span>
                                            取消申请
                                        </div>
                                        <div class="card-tip">
                                            <span class="row-l"><span class="tip-icon color-c">驳回</span></span>
                                            驳回申请
                                        </div>
                                        <!-- <div class="card-tip">
                                            <span class="tip-icon color-a">尾</span>
                                            尾款未回款
                                        </div> -->
                                        <!-- <div class="card-tip">
                                            <span class="tip-icon color-c">负</span>
                                            负数发票未回款
                                        </div> -->
                                        <!-- <div class="card-tip">
                                            <span class="tip-icon color-d">负</span>
                                            尾款负数发票已回款
                                        </div>
                                        <div class="card-tip">
                                            <span class="tip-icon color-e">负</span>
                                            尾款负数发票未回款
                                        </div> -->
                                        
                                    </div>
                                    <span slot="reference" class="icon-question">
                                        <font-icon class="table-header-tip-icon question-hover" href="#icon-talent_ql"></font-icon>
                                        <font-icon class="table-header-tip-icon question-no-hover" href="#icon-talent_qd"></font-icon>
                                    </span>
                                </el-popover>
                            </span>
                        </div>
                    </template> 
                    <!-- 付款方式 -->
                    <!-- 'hidden': !(row.isBalance || row.isNegative), 
                    (row.isBalance || row.isNegative) && 
                    -->
                    <template slot-scope="{ row, $index}">
                        <span
                            class="ex-cell"
                            :class="{ 
                                'cell-expanded': expandType == 'invoicingType' && currentIndex == $index ,
                                'hidden': row.refApplicationCount == 1,
                                }"
                            @click="row.refApplicationCount > 1 && handCellExpand(row, 'invoicingType', $index)"
                            v-html="generateInvoiceTypeCell(row)"
                            >
                            <!-- @click="(row.isBalance || row.isNegative) && handCellExpand(row, 'invoicingType', $index)" -->
                            <!-- <i class="el-icon el-icon-arrow-right"></i>
                            {{ row[applyTableTitle[10].prop] }}
                            <span class="tip-icon color-a">尾</span> -->
                        </span>
                    </template>
                </el-table-column>

                <!-- 价税合计 -->
                <el-table-column
                    align="left"
                    :show-overflow-tooltip="true"
                    :label="applyTableTitle[14].label"
                    :width="applyTableTitle[14].width"
                    :min-width="applyTableTitle[14].minWidth"
                    class-name="name-cell"
                    :prop="applyTableTitle[14].prop"
                    label-class-name="bg-orange-dark"
                    :resizable="false"
                >
                    <template slot="header" slot-scope="scope">
                        <template v-if="!isFinancial">
                            <div class="table-slot">
                                {{ applyTableTitle[14].label }}
                            </div>
                        </template>
                        <selectPopover
                            v-else
                            :enableSearch="true"
                            :enableCount="true"
                            :selectItem="[]"
                            type="SearchOnly"
                            :label="'InvoiceActualTaxAmount'"
                            :title="applyTableTitle[14].label"
                            :arrowShow="applyTableTitle.length > 0"
                            @selectChange="selectDataChange"
                        ></selectPopover>
                    </template>
                    <template slot-scope="{ row }" v-if="row.invoiceActualAmount">
                        {{ columnFormatter('invoiceAmount' ,(row.invoiceActualAmount || 0) + (row.invoiceTaxAmount || 0)) }}
                        <el-dropdown
                            ref="resumeOperation"
                            placement="bottom-start"
                            trigger="hover"
                            :hide-on-click="false">
                            <span class="operation-icon">
                                <font-icon
                                    class="table-body-icon operation-icon-default"
                                    href="#icon-ic_act_grey"
                                ></font-icon>
                                <font-icon
                                    class="table-body-icon operation-icon-hover"
                                    href="#icon-ic_act_green"
                                ></font-icon>
                            </span>
                            <el-dropdown-menu
                                class="operation-menu"
                                slot="dropdown">
                                <el-dropdown-item command="addToTrackingList" >
                                    服务费 {{columnFormatter('invoiceAmount', row.invoiceActualAmount || 0) }}
                                </el-dropdown-item>
                                <el-dropdown-item command="addToTrackingList" >
                                    税金 {{columnFormatter('invoiceAmount', row.invoiceTaxAmount || 0) }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>

                <el-table-column
                    v-for="(item, index) in applyTableTitle.slice(15)"
                    :key="'applyTableTitle_2_' + index"
                    :label="item.label"
                    :width="item.width"
                    :min-width="item.minWidth"
                    :show-overflow-tooltip="item.showOverflowTooltip"
                    :label-class-name="item.labelClassName"
                    :resizable="false"
                >
                    <template slot="header">
                        <template v-if="!isFinancial">
                            <div class="table-slot">
                                {{ item.label }}
                            </div>
                        </template>
                        <template v-else>
                            <el-popover
                                v-if="item.label == '开票日期'"
                                placement="bottom"
                                width="100%"
                                trigger="click"
                                ref="financeInvoiceTime"
                                :popper-class="`el-time-popover ${popoverClass}`"
                            >
                                <div class="date-range">
                                    <el-date-picker
                                        size="small"
                                        align="left"
                                        type="daterange"
                                        value-format="yyyy-MM-dd"
                                        :editable="false"
                                        :clearable="false"
                                        range-separator="-"
                                        class="performance-date-input"
                                        v-model="financeInvoiceDate"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        @change="changePerformanceDate('financeInvoiceDate')"
                                        :picker-options="financeInvoicePerformanceDateOption"
                                        popper-class="performance-date-range"
                                    ></el-date-picker>
                                    <div class="date-input-icon"></div>
                                </div>
                                <div class="table-slot" slot="reference">
                                    {{ item.label }}
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </div>
                            </el-popover>
                            <selectPopover
                                v-else-if="item.label == '发票号码' || item.label == '快递单号'"
                                :enableSearch="true"
                                :enableCount="true"
                                :selectItem="[]"
                                type="SearchOnly"
                                :label="item.label == '发票号码'?'invoiceNumber':'courierNumber'"
                                :title="item.label"
                                :arrowShow="applyTableTitle.length > 0"
                                @selectChange="selectDataChange"
                            ></selectPopover>
                        </template>
                    </template>
                    <template slot-scope="{ row }">
                        <template v-if="['refundAmount'].includes(item.prop)"> 
                            {{ columnFormatter(item.prop, row[item.prop]) }}
                        </template>
                        <template v-else-if="item.prop == 'financeInvoiceDate'">
                            {{ row.minFinanceInvoiceDate ? `${columnFormatter('financeInvoiceDate', row.minFinanceInvoiceDate)} ~ ${columnFormatter('financeInvoiceDate', row.maxFinanceInvoiceDate)}`: ''}}
                        </template>
                        <template v-else-if="item.prop == 'refundDate'">
                            {{ row.minRefundDate ? `${columnFormatter('refundDate', row.minRefundDate)} ~ ${columnFormatter('refundDate', row.maxRefundDate)}`: ''}}
                        </template>
                        <template v-else-if="item.prop == 'financeAttmatchs'">
                            <!-- <span class="see-attachments">查看附件</span> -->
                            <div class="cell-btn-wrap">
                                <a href="javascript:;" 
                                    class="apply-ticket" 
                                    v-if="row.hasFinanceAttachments"
                                    @click="seeAttachments(row.id)"
                                    >查看附件</a>
                                <span v-else>-</span>
                            </div>
                        </template>
                        <template v-else>
                            {{ row[item.prop] }}
                        </template>
                    </template>
                </el-table-column>
            </template>

            <el-table-column
                label="操作"
                :width="isShrink ? '80' : '320'"
                fixed="right"
                class-name="btn-menu"
                :show-overflow-tooltip="true"
                
            >
            <!-- v-if="filterIndex == 2" -->
            <!-- v-if="!(filterIndex == 2 && isFinancial)" -->
                <template slot="header">
                    <div class="table-slot">操作</div>
                </template>
                <template slot-scope="{ row, $index }">

                    <div class="sm-drop" v-if="isShrink && ( (([0,1, 9].includes(row.applicationStatus)|| row.isCanCreateNegative || row.isCanCreateBalance) && isFinancial) || (([0, 1,3,9,13, 19, 29].includes(row.applicationStatus) || row.isCanCreateNegative || row.isCanCreateBalance) && isPM) )">
                        <el-dropdown>
                            <span class="el-dropdown-link name-cell" >
                                <span class="operation-icon">
                                    <font-icon class="table-body-icon-big operation-icon-default tip-margin" href="#icon-ic_act_grey"></font-icon>
                                    <font-icon class="table-body-icon-big operation-icon-hover tip-margin" href="#icon-ic_act_green"></font-icon>
                                </span>
                            </span>
                            <el-dropdown-menu class="shrink-dropdown-menu" slot="dropdown" >
                                <!-- 未处理或推荐待反馈状态 -->
                                <template v-if="isFinancial">
                                    <el-dropdown-item 
                                        v-if="row.refApplicationCount > 1"
                                        @click="handCellExpand(row, 'invoicingType', $index)"
                                        >展开明细</el-dropdown-item>
                                    
                                    <template v-if="row.refApplicationCount == 1">
                                        <el-dropdown-item 
                                            v-if="row.applicationStatus == 0"
                                            @click.native="fillInvoiceInfo(row)"
                                            >确认开票信息</el-dropdown-item>
                                        <el-dropdown-item 
                                            v-if="row.applicationStatus == 1"
                                            @click.native="fillInvoiceInfo(row)"
                                            >填写开票信息</el-dropdown-item>
                                        <el-dropdown-item 
                                            v-if="row.applicationStatus ==9"
                                            @click.native="fillBackInfo(row)"
                                            >填写回款信息</el-dropdown-item>
                                        <el-dropdown-item 
                                            v-if="row.applicationStatus ==1"
                                            @click.native="rejectApply(row)"
                                            >驳回</el-dropdown-item>
                                        <el-dropdown-item 
                                            v-if="[0, 3].includes(row.applicationStatus)"
                                            @click.native="editApply(row)"
                                            >修改</el-dropdown-item>
                                    </template>
                                </template>
                                <!-- <el-dropdown-item 
                                        v-if="[0, 3].includes(row.applicationStatus)"
                                        @click.native="editApply(row)"
                                        >修改</el-dropdown-item>
                                    <el-dropdown-item 
                                        v-if="row.isCanCreateNegative"
                                        @click.native="handleApplyNegative(row)"
                                        >申请负数发票</el-dropdown-item>
                                    <el-dropdown-item 
                                        v-if="row.isCanCreateBalance"
                                        @click.native="handleApplyBalance(row)"
                                        >申请尾款发票</el-dropdown-item> -->
                                <template v-if="isPM">
                                    <template v-if="row.refApplicationCount == 1">
                                        <el-dropdown-item 
                                            v-if="[0].includes(row.applicationStatus)"
                                            @click.native="cancelApply(row)"
                                            >取消申请</el-dropdown-item>
                                        <el-dropdown-item 
                                            v-if="[0].includes(row.applicationStatus)"
                                            @click.native="editApply(row)"
                                            >修改</el-dropdown-item>
                                        <el-dropdown-item 
                                            v-if="[3, 19].includes(row.applicationStatus)"
                                            @click.native="reApply(row)"
                                            >重新申请</el-dropdown-item>
                                        <el-dropdown-item 
                                            v-if="[9,13].includes(row.applicationStatus) && row.applicationType==0 && row.isCanCreateNegative"
                                            @click.native="handleApplyNegative(row)"
                                            >申请负数发票</el-dropdown-item>
                                        <el-dropdown-item 
                                            v-if="[1, 9, 13].includes(row.applicationStatus) && row.paymentMethodType==1 && row.isCanCreateBalance"
                                            @click.native="handleApplyBalance(row)"
                                            >申请尾款发票</el-dropdown-item>
                                    </template>
                                    <el-dropdown-item 
                                        v-if="[29].includes(row.applicationStatus) && row.isCanReCreateApplication"
                                        @click.native="reApply(row)"
                                        >重新开票</el-dropdown-item>
                                </template>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>

                    <div class="cell-btn-wrap" v-else>
                        <template v-if="filterIndex == 2">
                            <!-- v-if="isPM" -->
                            <a
                                href="javascript:;"
                                class="apply-ticket"
                                @click="handleApplyTicket($index)">申请开票
                            </a>
                        </template>
                        <template v-else>
                            <!-- [Display(Name = "未开票")] -->
                            <!-- ApplicationCreated = 0, -->
                            <!-- [Display(Name = "已驳回")] -->
                            <!-- Rejected = 3, -->
                            <!-- [Display(Name = "未回款")] -->
                            <!-- InvoiceCreated = 9, -->
                            <!-- [Display(Name = "已回款")] -->
                            <!-- FundReceived = 13, -->
                            <!-- [Display(Name = "已取消")] -->
                            <!-- ApplicationCancel = 19 -->

                            <!-- [Display(Name =“已申请”)] -->
                            <!-- ApplicationCreated = 0. -->
                            <!-- [Display(Name =“已确认”)] -->
                            <!-- ApplicationComfirmed = 1. -->
                            <!-- [Display(Name =“已驳回”)] -->
                            <!-- Rejected = 3, -->
                            <!-- [Display(Name =“未回款”)] -->
                            <!-- InvoiceCreated = 9. -->
                            <!-- DDisplay(Name =“已回款”)] -->
                            <!-- FundReceived = 13 -->
                            <!-- IDisplay(Name =“已取消”)] -->
                            <!-- ApplicationCancel = 19. -->
                            <!-- [Display(Name =“已作废”)] -->
                            <!-- Voided = 29 -->
                            <template v-if="isFinancial">
                                <a href="javascript:;" 
                                    class="apply-ticket orange" 
                                    v-if="row.refApplicationCount > 1"
                                    @click="handCellExpand(row, 'invoicingType', $index)"
                                    >展开明细</a >
                                <template v-if="row.refApplicationCount == 1">
                                    <a href="javascript:;" 
                                        class="apply-ticket" 
                                        v-if="row.applicationStatus == 0"
                                        @click="applicationConfirm(row)"
                                        >确认开票信息</a >
                                    <a href="javascript:;" 
                                        class="apply-ticket" 
                                        v-if="row.applicationStatus == 1"
                                        @click="fillInvoiceInfo(row)"
                                        >填写开票信息</a >
                                    <a href="javascript:;" 
                                        class="apply-ticket" 
                                        v-if="row.applicationStatus ==9"
                                        @click="fillBackInfo(row)"
                                        >填写回款信息</a >
                                    <a href="javascript:;" 
                                        class="apply-ticket orange" 
                                        v-if="row.applicationStatus ==1"
                                        @click="rejectApply(row)"
                                        >驳回</a >
                                    <a href="javascript:;" 
                                        v-if="[0, 3].includes(row.applicationStatus)"
                                        class="apply-ticket" 
                                        @click="editApply(row)">修改</a >
                                </template>
                                
                                <!-- <a href="javascript:;" 
                                    v-if="[0, 3].includes(row.applicationStatus)"
                                    class="apply-ticket" 
                                    @click="editApply(row)">修改</a >
                                <a href="javascript:;" 
                                    v-if="row.isCanCreateNegative"
                                    class="apply-ticket orange" 
                                    @click="handleApplyNegative(row)"
                                    >申请负数发票</a>
                                <a href="javascript:;" 
                                    v-if="row.isCanCreateBalance"
                                    class="apply-ticket" 
                                    @click="handleApplyBalance(row)"
                                    >申请尾款发票</a> -->
                            </template>
                            <template v-if="isPM">
                                <a href="javascript:;" 
                                    class="apply-ticket orange" 
                                    v-if="row.refApplicationCount > 1"
                                    @click="handCellExpand(row, 'invoicingType', $index)"
                                    >展开明细</a >
                                <template v-if="row.refApplicationCount == 1">
                                    <a href="javascript:;" 
                                        class="apply-ticket" 
                                        v-if="[0].includes(row.applicationStatus)"
                                        @click="cancelApply(row)">取消申请</a >
                                    <a href="javascript:;" 
                                        v-if="[0].includes(row.applicationStatus)"
                                        class="apply-ticket" 
                                        @click="editApply(row)">修改</a >
                                    <a href="javascript:;" 
                                        v-if="[3, 19].includes(row.applicationStatus)"
                                        class="apply-ticket" 
                                        @click="reApply(row)">重新申请</a >
                                    <a href="javascript:;" 
                                        v-if="[9,13].includes(row.applicationStatus) && row.applicationType==0 && row.isCanCreateNegative"
                                        class="apply-ticket orange" 
                                        @click="handleApplyNegative(row)"
                                        >申请负数发票</a>
                                    <a href="javascript:;" 
                                        v-if="[1, 9, 13].includes(row.applicationStatus) && row.paymentMethodType==1 && row.isCanCreateBalance"
                                        class="apply-ticket" 
                                        @click="handleApplyBalance(row)"
                                        >申请尾款发票</a>
                                </template>
                                <a href="javascript:;" 
                                    v-if="[29].includes(row.applicationStatus) && row.isCanReCreateApplication"
                                    class="apply-ticket" 
                                    @click="reCreateApply(row)">重新开票</a >
                            </template>
                            <template v-if="isOfferList && filterIndex == 4">
                                <span 
                                    class="option-btn text-gray" 
                                    @click="performanceEdit(row)" 
                                    >编辑业绩</span>
                                <span 
                                    class="option-btn text-gray" 
                                    v-if="isCFUser"
                                    @click="businessClassficationEdit(row)" 
                                    >编辑业务分类</span>
                            </template>
                            
                        </template>
                    </div>
                </template>
            </el-table-column>

            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </template>
        </el-table>

        <!-- 切换Table的nav组件 -->
        <table-page-tab
            :filterIndex="filterIndex"
            :pageTabs="pageTabJson"
            :unApplyNumber="unApplyNumber"
        ></table-page-tab>

        <div class="work-table-footer">
            <div class="footer-left" v-if="filterIndex == 3 && isFinancial">
                <div class="footer-select">
                    <input hidden v-model="allSelect" type="checkbox">
                    <span class="all-select-checkbox" @click="handleAllSelect"></span>
                    <span>全选</span>
                    <span class="select-count">已选择
                        <span class="color-orange">{{multipleCount}}</span>
                        条记录
                    </span>
                </div>
                <button
                    v-if="filterIndex == 3 && multipleCount > 0"
                    class="work-table-btn work-table-btn-blue"
                    :loading="exportNoticeLetterLoading" 
                    :disabled="exportNoticeLetterLoading"
                    @click="generateInform">
                    <i class="el-icon-loading" v-show="exportNoticeLetterLoading"></i>生成付款通知函
                </button>
            </div>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.current + 1"
                :page-sizes="[20, 30, 50]"
                :page-size="page.size"
                class="el-pagination-workTable"
                layout="total, sizes, prev, pager, next, slot"
                :total="page.total">
                <span class="pagination-text">
                    <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                    <span @click="handlePagerJump">跳转</span>
                </span>
            </el-pagination>
        </div>
        
        <!-- 附件弹出窗口 -->
        <attachment-dialog
            ref="attachmentDialog"
         />

        <!-- 填写开票信息 -->
        <ticket-info-dialog
            ref="ticketInfoDialog"
         />

        <!-- 填写回款信息 -->
        <back-charge-dialog
            ref="backChargeDialog"
         />

        <offer-dialog
            ref="offerDialog"
            from="offerList"
         />

        <!-- 编辑业务分类弹窗 -->
        <business-classfication-dialog
            ref="businessClassficationDialog"
            @refresh="getOfferListRecommendations"
         />

        <invoice-detail-dialog
            ref="invoiceDetailDialog"
            @download-click="handleDownloadClick"
         />
    </div>
</div>
</template>

<script>
    import SelectPopover from '#/component/common/select-popover.vue';
    import moment from "moment";
    import floatingListBubble from "./floatingListBubble.vue";
    import TablePageTab from "#/component/workTable/table-page-tab.vue";
    
    import floatingListService from "#/js/service/floatingListService.js";
    import emitter from "@src/js/mixins/emitter";
    import { currency as currencyFilter } from "#/js/filters/number.js";
    import {downloadFile} from '#/js/util/fileUtil.js'
    import {
        invoiceStatusMap,
        paymentMethodTypeMap,
    } from "../config-data/common-data.js";

    import applyTableTitle from "../config-data/applyTableTitle.js";
    import {recommendHashMap} from '#/js/config/recommend-detail.json';
    import ApplyTableRowExpand from "../component/apply-table-row-expand.vue";
    import AttachmentDialog from "../component/attachment-dialog.vue";
    import TicketInfoDialog from "../component/ticket-info-dialog.vue";
    import BackChargeDialog from "../component/back-charge-dialog.vue";
    import InvoiceDetailDialog from "../component/invoice-detail-dialog.vue";
    import OfferDialog from '#/component/common/dialog/offer-dialog.vue';
    import BusinessClassficationDialog from '#/component/common/dialog/business-classfication-dialog.vue';
    import Avatar from '#/component/common/avatar.vue';
    import invoiceSubFlowStatus from '#/js/config/invoiceSubFlowStatus.js';
    import TeamMemberSelect from '#/component/common/team-member-select.vue';
    const YESTODAY = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const TODAY = moment().format('YYYY-MM-DD');
    const lastMonth = moment().subtract(1, 'months').format("YYYY-MM-DD");
    const dataRangeData = {
                    startDate: lastMonth,
                    endDate: TODAY
                };

    export default {
        name: "applyWorkTable",
        mixins: [emitter],
        components: {
            SelectPopover,

            floatingListBubble,
            TablePageTab,
           
            ApplyTableRowExpand,
            AttachmentDialog,
            TicketInfoDialog,
            BackChargeDialog,
            OfferDialog,
            BusinessClassficationDialog,
            Avatar,
            InvoiceDetailDialog,
            TeamMemberSelect
        },
        props: {
            filterIndex: 0,
        },
        data() {
            let _minTime = null,
                _maxTime = new Date().getTime();
            return {
                searchLock: false,
                applyLabelShowList: [],
                searchParamTitle: {
                    performanceDate: '更新时间',
                    financeInvoiceDate: '开票日期',
                    invoiceEntity: '合同主体',
                    invoiceType: '发票类型',
                    InvoiceContent: '开票内容',
                    ApplicationStatus: '当前状态',
                    PaymentMethodType: '开票类型',
                    // customerNames: '客户名称',
                    CompanyName: '客户名称',
                    InvoiceAmount: '服务费',
                    ResponsibleUsers: '收款责任人',
                    responsibleLeaders: '收款责任人Leader',
                    CandidateName: '候选人',
                    InvoiceActualTaxAmount: '价税合计', 
                    invoiceNumber: '发票号码', 
                    courierNumber: '快递单号',

                    candidateName: '候选人',
                    recommendOwnerName: '推荐者',
                },
                labelList: {
                    performanceDate: [], // 申请时间
                    financeInvoiceDate: [], // 开票日期
                    invoiceEntity: [], // 合同主体
                    invoiceType: [], // 发票类型
                    InvoiceContent: [], // 开票内容
                    ApplicationStatus: [], // 当前状态
                    PaymentMethodType: [], // 开票类型
                    // customerNames: [], // 客户名称
                    CompanyName: [], // 客户名称
                    InvoiceAmount: [], // 服务费
                    ResponsibleUsers: [], // 收款责任人
                    responsibleLeaders: [], // 收款责任人Leader
                    CandidateName: [], // 候选人
                    InvoiceActualTaxAmount: [], // 价税合计
                    invoiceNumber: [], // 发票号码
                    courierNumber: [], // 快递单号

                    candidateName: [],
                    recommendOwnerName: [],
                },
                applyLabelMap: new Set(),
                headerFilter: {
                    customers: [],
                    responsibleLeaders: [],
                },

                invoiceEntityArray: [],
                popoverClass: '',
                performanceDate: [dataRangeData.startDate || YESTODAY, dataRangeData.endDate || TODAY], // 申请时间
                financeInvoiceDate: ['',''], // 开票时间
                financeInvoicePerformanceDateOption: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    },
                    shortcuts: [
                        {
                            text: '今天',
                            onClick(picker) {
                                const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                                const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '昨天',
                            onClick(picker) {
                                const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                                const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '最近7天',
                            onClick(picker) {
                                const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                                const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '最近30天',
                            onClick(picker) {
                                const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                                const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '本月',
                            onClick(picker) {
                                const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                                const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '上月',
                            onClick(picker) {
                                const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                                const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '今年',
                            onClick(picker) {
                                const start = moment().format('YYYY') + '-01-01';
                                const end = moment().format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '最近半年',
                            onClick(picker) {
                                const start = moment().subtract(6, 'months').format('YYYY-MM-DD');
                                const end = moment().format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '最近一年',
                            onClick(picker) {
                                const start = moment().subtract(1, 'years').format('YYYY-MM-DD');
                                const end = moment().format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }
                    ]
                },
                performanceDateOption: {
                    onPick: time => {
                        // 如果选择了只选择了一个时间
                        if (!time.maxDate) {
                            let _nDays = this.key == 'trackingListDetails' ? 31: 365;
                            let timeRange = _nDays*24*60*60*1000, // *天
                                _dNow = new Date().getTime();

                            _minTime = time.minDate.getTime() - timeRange; // 最小时间
                            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
                            _maxTime = moment(_maxTime).isSameOrAfter(_dNow) ? _dNow: _maxTime;
                        // 如果选了两个时间，那就清空本次范围判断数据，以备重选
                        } else {
                            _minTime = _maxTime = null;
                        }
                    },
                    disabledDate: time => {
                        // onPick后触发
                        // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
                        if(_minTime && _maxTime){
                            return time.getTime() < _minTime || time.getTime() > _maxTime;
                        }
                        if(!_minTime && _maxTime) {
                            return time.getTime() > _maxTime;
                        }
                    },
                    // disabledDate(date) {
                    //     return date.getTime() > Date.now();
                    // },
                    shortcuts: [
                        {
                            text: '今天',
                            onClick(picker) {
                                const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                                const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '昨天',
                            onClick(picker) {
                                const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                                const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '最近7天',
                            onClick(picker) {
                                const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                                const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '最近30天',
                            onClick(picker) {
                                const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                                const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '本月',
                            onClick(picker) {
                                const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                                const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '上月',
                            onClick(picker) {
                                const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                                const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '今年',
                            onClick(picker) {
                                const start = moment().format('YYYY') + '-01-01';
                                const end = moment().format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '最近半年',
                            onClick(picker) {
                                const start = moment().subtract(6, 'months').format('YYYY-MM-DD');
                                const end = moment().format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '最近一年',
                            onClick(picker) {
                                const start = moment().subtract(1, 'years').format('YYYY-MM-DD');
                                const end = moment().format('YYYY-MM-DD');
                                picker.$emit('pick', [start, end]);
                            }
                        }
                    ]
                },

                statusBubbleCount: [
                    {status: 101, name: '未开票', count: 0},
                    {status: 102, name: '未回款', count: 0},
                    {status: 103, name: '已回款', count: 0},
                    {status: 104, name: '已驳回', count: 0},
                    {status: 105, name: '取消申请', count: 0},

                    {status: 106, name: '首款', count: 0},
                    {status: 107, name: '全款', count: 0},
                ],
                page: {
                    total: 0,
                    current: 0,
                    size: 20,
                },
                pagerJump: 0,
                isShrink: false,
                multipleSelection: [],
                dateRange: {
                    startDate: dataRangeData.startDate,
                    endDate: dataRangeData.endDate,
                    financeInvoiceStartDate: '',
                    financeInvoiceEndDate: ''
                },

                applyTable: [],
                applyTableLoading: false,
                unApplyNumber: 0,

                expendsArray: [],
                expandType: "", // 展开类型 candidate, invoicingType, taxTatal
                selectedUserIds: [],
                ApplicationStatus: '', // 当前状态
                PaymentMethodType: '', // 开票类型
                InvoiceApplicationType: '', // 负数发票、整数发票、全部
                invoiceType: '', // 发票类型
                InvoiceContent: '', // 开票内容
                InvoiceAmount: '', // 服务费
                invoiceEntity: '', // 合同主体
                updateInvoiceDate: '1', // 更新时间排序(默认降序)
                
                CompanyName: '', // 客户名称
                CandidateName: '', // 候选人
                invoiceNumber: '', // 发票号码
                courierNumber: '', // 快递单号
                InvoiceActualTaxAmount: '', // 价税合计

                currentIndex: null,

                applySubTable: [], // 子表数据
                applySubTableLoading: false,
                subExpendsArray: [],
                subExpandType: "", 
                subCurrentIndex: null,

                exportLoading: false,
                exportNoticeLetterLoading: false,

                originOfferListData: [],
                originOfferListTotal: 0,
                cacheArgs: null,

                unApplyFilter: {    // 未申请开票的筛选条件数据
                    candidateName: '',
                    recommendOwnerName: '',
                },
            };
        },
        watch: {
            searchParams() {
                this.$nextTick(() => {
                    if (!this.searchLock) {
                        console.log('执行了----------')
                        this.getApplyList();
                        if(this.isFinancial) {
                            this.getInvoiceApplicationSearch();
                        }
                    }
                })
            },
        },
        computed: {
            isCFUser() {
                return this.$store.state.user.userInfo.isCFUser;
            },
            isTsdnuser(){
                return this.$store.state.user.userInfo.tsdnuser;
            }, 
            searchParams() {
                let keyName = 'itemValue';
                const params = {
                    Start: this.page.current * this.page.size,
                    Take: this.page.size,
                    ResponsibleUserIds: this.selectedUserIds,
                    // 申请时间
                    StartDate: this.dateRange.startDate,
                    EndDate: this.dateRange.endDate,
                    // 开票时间
                    FinanceInvoiceStartDate: this.dateRange.financeInvoiceStartDate,
                    FinanceInvoiceEndDate: this.dateRange.financeInvoiceEndDate,
                    
                    ApplicationStatus: this.ApplicationStatus, // 当前状态（发票状态）
                    PaymentMethodType: this.PaymentMethodType, // 开票类型（发票种类）
                    InvoiceApplicationType: this.InvoiceApplicationType, // 正数发票、负数发票
                    invoiceType: this.invoiceType, // 发票类型（专票、普票）
                    InvoiceContent: this.InvoiceContent, // 开票原因
                    InvoiceAmount: this.InvoiceAmount, // 服务费
                    invoiceEntity: this.invoiceEntity, // 合同主体（开票主体）
                    CandidateName: this.CandidateName, // 候选人
                    InvoiceNo: this.invoiceNumber, // 发票号码
                    ExpressNo: this.courierNumber, // 快递单号
                    InvoiceActualTaxAmount: this.InvoiceActualTaxAmount, // 价税合计
                    // CustomerIds: this.getLabelListParam('customerNames', keyName), // 客户名称
                    CompanyName: this.CompanyName, // 客户名称
                    ResponsibleLeaderIds: this.getLabelListParam('responsibleLeaders', keyName), // 收款责任人Leader
                };
                return params;
            },
            multipleCount() {
                return this.multipleSelection.length;
            },
            allSelect() {
                return this.applyTable.length > 0 ? this.applyTable.length == this.multipleSelection.length : false;
            },
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            // 是PM还是财务人员
            isFinancial () {
                // return this.userInfo.roleCodeList.includes('Financial');
                return this.userInfo.privilegeCodeList && this.userInfo.privilegeCodeList.includes('Financial');
            },
            isPM () {
                return this.userInfo.roleCodeList && (this.userInfo.roleCodeList.includes('HeadHunters') || this.userInfo.roleCodeList.includes('InternalControl')) && !this.isFinancial;
                // return this.userInfo.roleCodeList.includes('HeadHunters');
            },
            isOfferList () {
                return this.userInfo.privilegeCodeList && this.userInfo.privilegeCodeList.includes('OfferList');
            },
            pageTabJson() {
                let pageTabs = [];
                if (this.isFinancial) {
                    pageTabs = [
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/unApply",
                            title: "未申请的开票",
                            tabIndex: 2,
                            aliveComponent: "FloatingListUnApplyList",
                        },
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/apply",
                            title: "开票申请",
                            tabIndex: 3,
                            aliveComponent: "FloatingListApplyList",
                        },
                    ]; 
                } else {
                    pageTabs = [
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList",
                            title: "收到的推荐",
                            tabIndex: 0,
                            aliveComponent: "FloatingListRecommendToMe",
                        },
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/myRecommend",
                            title: "我的推荐",
                            tabIndex: 1,
                            aliveComponent: "FloatingListMyRecommend",
                        },
                        // {
                        //     enTitle: "Floating List",
                        //     cnTitle: "流程管理",
                        //     path: "/floatingList/unApply",
                        //     title: "未申请的开票",
                        //     tabIndex: 2,
                        //     aliveComponent: "FloatingListUnApplyList",
                        // },
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/apply",
                            title: "开票申请",
                            tabIndex: 3,
                            aliveComponent: "FloatingListApplyList",
                        },
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/recommendAudit",
                            title: "HR直招推荐审批",
                            tabIndex: 5,
                            aliveComponent: "FloatingListRecommendAudit",
                        },
                    ];
                }

                if(this.isOfferList) {
                    const tabOjb = {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/offerList",
                            title: "Offer列表",
                            tabIndex: 4,
                            aliveComponent: "FloatingListOfferList",
                        };
                    pageTabs.push(tabOjb);
                }
                return pageTabs;
            },
            // 申请表头
            applyTableTitle() {
                if ([2].includes(this.filterIndex)) {
                    return applyTableTitle.unApplyTitle;
                }
                if ([3].includes(this.filterIndex)) {
                    return applyTableTitle.applyTitle;
                }
                if ([4].includes(this.filterIndex)) {
                    return applyTableTitle.offerList;
                }
            },
            computedOfferListData() {
                if(this.CandidateName == '') {
                    return this.originOfferListData;
                } else {
                    return this.originOfferListData.filter(item => item.candidateName.includes(this.CandidateName));
                }
            }
        },
        filters: {
            labelsTip(labels,item){
                // console.log(labels)
                // console.log(item)
                let tip =[];
                labels.forEach(item => {
                    tip.push(item.text)
                });
                if(item == 'performanceDate' || item == 'financeInvoiceDate') {
                    return tip.join(' ~ ');
                } else {
                    return tip.join(' , ');
                }
            },

            formatDate(string) {
                return moment(string).format('YYYY-MM-DD');
            },
            formatStatus(status, row) {
                if([24, 26].includes(status)) {
                    return invoiceSubFlowStatus[row.flowStepName];
                } else {
                    return status == 6 ? recommendHashMap['5'].text : recommendHashMap[status].text;
                }
            },
            avatarUrlFilter(url) {
                if(url) {
                    url = url.replace(/^https?:/, location.protocol);
                }
                return url;
            },
        },
        created() {
            this.init();
            // window.floatingTable = this;
        },
        activated() {},
        updated() {},
        mounted() {
            let _this = this;
            _this.resize();
            window.onresize = function () {
                _this.resize();
            }

            // 监听postMessage事件
            window.addEventListener("message", function (e) {  // 监听 message 事件
                // console.log(e)
                // if (e.origin !== "https://www.runoob.com") {  // 验证消息来源地址
                //     return;
                // }
            });
        },
        methods: {
            selectDataChange(label, result){
                // console.log("label：",label);
                // console.log("result：",result);
                if(result.length > 0){
                    // return this.$emit('handleAddLabel', label, result, true);
                    this.handleAddLabel(label, result, true);
                } else {
                    // return this.$emit("handleCleanLabel", label);
                    this.handleCleanLabel(label);
                }
            },
            handleAddLabel(label, params, isCover) {
                const applyList = this.labelList[label];
                isCover = isCover || false;
                params = params.constructor === Object ? [params] : params;
                // console.log('params' + params);
                applyList.forEach(item => {
                    params.filter(param => {
                        return param.text !== item.text;
                    });
                });
                if (params.length <= 0) {
                    return false;
                }
                this.applyLabelMap.add(label);
                this.applyLabelShowList = Array.from(this.applyLabelMap);
                if (isCover) {
                    this.labelList[label] = params;
                } else {
                    this.labelList[label] = this.labelList[label].concat(params);
                }

                // console.log(this.labelList[label])
                
                switch(label) {
                    case 'InvoiceAmount':
                        this.InvoiceAmount = this.labelList[label][0].label;
                        break;
                    case 'invoiceNumber':
                        this.invoiceNumber = this.labelList[label][0].label;
                        break;
                    case 'courierNumber':
                        this.courierNumber = this.labelList[label][0].label;
                        break;
                    case 'InvoiceActualTaxAmount':
                        this.InvoiceActualTaxAmount = this.labelList[label][0].label;
                        break;
                    case 'CandidateName':
                        this.CandidateName = this.labelList[label][0].label;
                        break;
                    case 'CompanyName':
                        this.CompanyName = this.labelList[label][0].label;
                        break;
                }
                // 解决点击申请时间刷新两次的问题：点申请时间的时候先不调用接口，等成员接口请求成功之后再刷新开票申请的接口
                if(label != 'performanceDate') {
                    // console.log(this.applyLabelShowList);
                    if(this.filterIndex == 3) {
                        this.page.current = 0;
                        this.getApplyList();
                    }
                    if(this.isFinancial) {
                        this.getInvoiceApplicationSearch();
                    }
                }
            },
            handleCleanLabel(label) {
                if (this.labelList[label].length <= 0) {
                    return false;
                }
                console.log(label)
                
                if(label == 'performanceDate') { // 申请时间
                    this.performanceDate = [dataRangeData.startDate || YESTODAY, dataRangeData.endDate || TODAY];
                    this.dateRange.startDate = dataRangeData.startDate || YESTODAY;
                    this.dateRange.endDate = dataRangeData.endDate || TODAY;
                }
                if(label == 'financeInvoiceDate') { // 开票时间
                    this.financeInvoiceDate = ['',''];
                    this.dateRange.financeInvoiceStartDate = '';
                    this.dateRange.financeInvoiceEndDate = '';
                }
                if(label == 'invoiceEntity') { // 合同主体
                    this.invoiceEntity = '';
                }
                if(label == 'invoiceType') { // 发票类型
                    this.invoiceType = '';
                }
                if(label == 'InvoiceContent') { // 开票内容
                    this.InvoiceContent = '';
                }
                if(label == 'InvoiceAmount') { // 服务费
                    this.InvoiceAmount = '';
                }
                if(label == 'ApplicationStatus') { // 当前状态
                    this.ApplicationStatus = '';
                }
                if(label == 'PaymentMethodType') { // 开票类型
                    this.PaymentMethodType = '';
                }
                if(label == 'invoiceNumber') { // 发票号码
                    this.invoiceNumber = '';
                }
                if(label == 'courierNumber') { // 快递单号
                    this.courierNumber = '';
                }
                if(label == 'InvoiceActualTaxAmount') { // 价税合计
                    this.InvoiceActualTaxAmount = '';
                }
                if(label == 'CandidateName') { // 候选人
                    this.CandidateName = '';
                }
                if(label == 'CompanyName') { // 客户名称
                    this.CompanyName = '';
                }
                if(label == 'candidateName') { 
                    this.unApplyFilter.candidateName = '';
                }
                if(label == 'recommendOwnerName') { 
                    this.unApplyFilter.recommendOwnerName = '';
                }
                
                this.applyLabelMap.delete(label);
                this.applyLabelShowList = Array.from(this.applyLabelMap);
                this.labelList[label] = [];
                // console.log(this.applyLabelShowList);

                // 清理已选标签
                // if(label == 'customerNames' || label == 'responsibleLeaders') {
                //     let list;
                //     if(label == 'customerNames') {
                //         list = this.headerFilter['customers'] || [];
                //     } else if(label == 'responsibleLeaders') {
                //         list = this.headerFilter['responsibleLeaders'] || [];
                //     }
                //     for (let key in list){
                //         list[key].isSelect = false;
                //     }
                // }
                if(label == 'responsibleLeaders') {
                    let list = this.headerFilter['responsibleLeaders'] || [];
                    for (let key in list){
                        list[key].isSelect = false;
                    }
                }

                this.applyTableLoading = true;
                // 解决点击申请时间刷新两次的问题：点申请时间的时候先不调用接口，等成员接口请求成功之后再刷新开票申请的接口
                if(label != 'performanceDate') {
                    if(this.filterIndex == 2) {
                        this.getPMUnApplyList();
                    }else if(this.filterIndex == 3) {
                        this.getApplyList();
                    }
                    if(this.isFinancial) {
                        this.getInvoiceApplicationSearch();
                    }
                }
            },

            composeValue(item, type) {
                return {
                    'item': item,
                    'type': type
                }
            },
            handleCommand(value) {
                console.log('command:'+value);
                let label = '';
                let result = [{
                    label: '',
                    text: ''
                }];
                if(value.type == 'invoiceEntity') { // 合同主体筛选
                    label = 'invoiceEntity';
                    if(value.item == '') { // 全部
                        result = [];
                    } else {
                        result[0] = {
                            label: value.item,
                            text: value.item
                        };
                    }
                    
                    this.invoiceEntity = value.item;
                } else if(value.type == 'invoiceType') { // 发票类型
                    label = 'invoiceType';
                    if(value.item == '') { // 全部
                        result = [];
                    } else if(value.item == '0') {
                        result[0] = {
                            label: '普票',
                            text: '普票'
                        };
                    } else if(value.item == '1') {
                        result[0] = {
                            label: '专票',
                            text: '专票'
                        };
                    }

                    this.invoiceType = value.item;
                } else if(value.type == 'InvoiceContent') { // 开票内容
                    label = 'InvoiceContent';
                    if(value.item == '') { // 全部
                        result = [];
                    } else if(value.item == '服务费') {
                        result[0] = {
                            label: '服务费',
                            text: '服务费'
                        };
                    } else if(value.item == '招聘服务费') {
                        result[0] = {
                            label: '招聘服务费',
                            text: '招聘服务费'
                        };
                    } else if(value.item == '咨询服务费') {
                        result[0] = {
                            label: '咨询服务费',
                            text: '咨询服务费'
                        };
                    } else if(value.item == '其他人力资源服务费') {
                        result[0] = {
                            label: '其他人力资源服务费',
                            text: '其他人力资源服务费'
                        };
                    } else if(value.item == '信息服务费') {
                        result[0] = {
                            label: '信息服务费',
                            text: '信息服务费'
                        };
                    } else if(value.item == '软件服务费') {
                        result[0] = {
                            label: '软件服务费',
                            text: '软件服务费'
                        };
                    } else if(value.item == '技术服务费') {
                        result[0] = {
                            label: '技术服务费',
                            text: '技术服务费'
                        };
                    }

                    this.InvoiceContent = value.item;
                } else if(value.type == 'ApplicationStatus') { // 当前状态
                    label = 'ApplicationStatus';
                    if(value.item == '') { // 全部
                        result = [];
                    } else if(value.item == '0') {
                        result[0] = {
                            label: '待确认',
                            text: '待确认'
                        };
                    } else if(value.item == '9') {
                        result[0] = {
                            label: '未回款',
                            text: '未回款'
                        };
                    } else if(value.item == '13') {
                        result[0] = {
                            label: '已回款',
                            text: '已回款'
                        };
                    } else if(value.item == '3') {
                        result[0] = {
                            label: '已驳回',
                            text: '已驳回'
                        };
                    } else if(value.item == '1') {
                        result[0] = {
                            label: '已确认',
                            text: '已确认'
                        };
                    } else if(value.item == '19') {
                        result[0] = {
                            label: '已取消',
                            text: '已取消'
                        };
                    } else if(value.item == '29') {
                        result[0] = {
                            label: '已作废',
                            text: '已作废'
                        };
                    }

                    this.ApplicationStatus = value.item;
                } else if(value.type == 'PaymentMethodType') { // 开票类型
                    label = 'PaymentMethodType';
                    if(value.item == '') { // 全部
                        result = [];
                    } else if(value.item == '0') {
                        result[0] = {
                            label: '全款',
                            text: '全款'
                        };
                    } else if(value.item == '1') {
                        result[0] = {
                            label: '首款',
                            text: '首款'
                        };
                    } else if(value.item == '2') {
                        result[0] = {
                            label: '尾款',
                            text: '尾款'
                        };
                    } else if(value.item == '-1') { // 开票类型——负数发票
                        result[0] = {
                            label: '负数发票',
                            text: '负数发票'
                        };
                    }

                    this.PaymentMethodType = value.item;
                } else if(value.type == 'updateInvoiceDate') {
                    this.updateInvoiceDate = value.item;
                }
                
                this.selectDataChange(label, result);
            },
            // 获取合同主体数据
            getInvoiceEntity() {
                floatingListService.getInvoiceEntity().then(res => {
                    this.invoiceEntityArray = res;
                }).catch(err => {
                    console.log(err);
                });
            },
            // 开票申请表头聚合筛选
            getInvoiceApplicationSearch() {
                let params = Object.assign({},this.searchParams);
                delete params.ResponsibleLeaderIds;

                if(params.PaymentMethodType == '-1') {
                    params.PaymentMethodType = '';
                    params.InvoiceApplicationType = '1';
                }
                if(this.isPM) {
                    params.StartDate = '1970-01-01';
                    params.OwnerIds = [this.userInfo.id];
                }
                floatingListService.getInvoiceApplicationSearch(params).then(res => {
                    // console.log('-------------------')
                    // console.log(res)

                    // 判断是否选中：判断旧值是否为空（是否有值）
                    let responsibleLeadersOld = this.headerFilter.responsibleLeaders;
                    // console.log(responsibleLeadersOld)
                    let arr = res.responsibleLeaders.map( el => {
                        let isSelect = false;
                        if (responsibleLeadersOld && responsibleLeadersOld.length) {
                              responsibleLeadersOld.some( item => {
                                  if (item.itemValue == el.itemValue) {
                                    // 有旧值且旧值里面的isSelect为true，那就把isSelect设置为true，否则为false
                                    isSelect = item.isSelect ? true : false;
                                    return true;
                                  }
                              })  
                        }
                        return {
                            ...el,
                            isSelect: isSelect,
                            label: el.itemText,
                            text: el.itemText
                        }
                    });
                    this.headerFilter.responsibleLeaders  = arr;
                    // console.log(arr)
                    
                }).catch(err => {
                    console.log(err);
                });
            },
            // 选中的value/id数组
            getLabelListParam(label, keyName) {
                const resultArr = [], target = this.labelList[label];
                // console.log(target)
                target.map(item => {
                    resultArr.push(item[keyName]);
                });
                // console.log(resultArr)
                return resultArr;
            },
            // 日期change事件
            changePerformanceDate(prop) {
                // console.log(this.performanceDate);
                // console.log(this.$refs)
                // console.log(this.$refs.popoverTime)
                // console.log(prop)
                this.applyTableLoading = true;
                if(prop == 'created') {
                    this.$refs.popoverTime[0].doClose();
                    this.$refs.popoverTime[1].doClose();
                    this.dateRange.startDate = this.performanceDate[0];
                    this.dateRange.endDate = this.performanceDate[1];
                    let _createdDate = [
                        {
                            label: this.performanceDate[0],
                            text: this.performanceDate[0]
                        },
                        {
                            label: this.performanceDate[1],
                            text: this.performanceDate[1]
                        },
                    ]
                    this.handleAddLabel('performanceDate', _createdDate, true);
                } else if(prop == 'financeInvoiceDate') {
                    this.$refs.financeInvoiceTime[0].doClose();
                    this.$refs.financeInvoiceTime[1].doClose();
                    this.dateRange.financeInvoiceStartDate = this.financeInvoiceDate[0];
                    this.dateRange.financeInvoiceEndDate = this.financeInvoiceDate[1];
                    let _financeInvoiceDate = [
                        {
                            label: this.financeInvoiceDate[0],
                            text: this.financeInvoiceDate[0]
                        },
                        {
                            label: this.financeInvoiceDate[1],
                            text: this.financeInvoiceDate[1]
                        },
                    ]
                    this.handleAddLabel('financeInvoiceDate', _financeInvoiceDate, true);
                }
                
                // this.getApplyList();
                // if(this.isFinancial) {
                //     this.getInvoiceApplicationSearch();
                // }
            },


            init() {
                if (this.filterIndex == 2) {
                    this.getPMUnApplyList();
                } else if(this.filterIndex == 3) {
                    this.getInvoiceEntity();
                    // this.getInvoiceApplicationSearch();
                    this.getUnapplyNumber();
                    // if(this.isPM) {
                    //     this.getApplyList();
                    // }
                    this.getApplyList();
                } else if(this.filterIndex == 4) {
                    this.getUnapplyNumber();
                    this.applyTableLoading = true;
                }
            },
            requestData() {
                if (this.filterIndex == 2) {
                    this.getPMUnApplyList();
                    document.querySelector('.el-table__body-wrapper').scrollTop = 0;
                } else if(this.filterIndex == 3) {
                    this.getUnapplyNumber();
                    this.getApplyList();
                    if(this.isFinancial) {
                        this.getInvoiceApplicationSearch();
                    }
                    document.querySelector('.el-table__body-wrapper').scrollTop = 0;
                    if(this.applySubTable.length>0) {
                        this.applySubTable = [];
                    }
                }
            },
            getOfferListRecommendations() {
                const params = {
                    startDate: this.dateRange.startDate,
                    endDate: this.dateRange.endDate,
                    userIds: this.selectedUserIds,
                };

                this.applyTableLoading = true;
                floatingListService
                    .getOfferListRecommendations(params)
                    .then((res) => {
                        this.originOfferListData = res.items || [];
                        this.originOfferListTotal = res.total;
                        this.pageination();
                    }).finally(() => {
                        this.applyTableLoading = false;
                    });
            },
            getUnapplyNumber (){
                floatingListService
                    .getPMUnapplyList({
                        Start: 0,
                        Take: 20,
                    })
                    .then((res) => {
                        this.unApplyNumber = res.total;
                    })
            },
            getPMUnApplyList() {
                console.log("🚀 ~ getPMUnApplyList ~ getPMUnApplyList:")
                const params = {
                    Start: this.page.current * this.page.size,
                    Take: this.page.size,
                    candidateName: this.unApplyFilter.candidateName,
                    recommendOwnerName: this.unApplyFilter.recommendOwnerName,
                };
                this.applyTableLoading = true;
                floatingListService
                    .getPMUnapplyList(params)
                    .then((res) => {
                        this.applyTable = res.list;
                        this.page.total = res.total;
                        this.unApplyNumber = res.total;
                    })
                    .finally(() => {
                        this.applyTableLoading = false;
                    });
            },
            // 获取申请列表
            getApplyList() {
                // let params = this.searchParams;
                let params = Object.assign({},this.searchParams);
                if(this.isPM) {
                    params.StartDate = '1970-01-01';
                    params.OwnerIds = [this.userInfo.id];
                }
                if(this.filterIndex ==5) {
                    params.IsHandedOver = true;
                }
                if(params.PaymentMethodType == '-1') { // 选择的开票类型是负数发票
                    params.PaymentMethodType = '';
                    params.InvoiceApplicationType = '1';
                }

                this.applyTableLoading = true;
                this.searchLock = true;
                floatingListService
                    .getApplyList(params)
                    .then((res) => {
                        this.applyTable = res.list;
                        this.page.total = res.total;
                        
                        // this.getBubbleData();
                    })
                    .finally(() => {
                        this.applyTableLoading = false;
                        this.searchLock = true;
                    });
            },
            // getBubbleData() {
            //     const params = {
            //         OwnerIds: this.selectedUserIds,
            //         StartDate: this.dateRange.startDate,
            //         EndDate: this.dateRange.endDate,
            //     };
            //     if(this.isPM) {
            //         params.StartDate = '1970-01-01';
            //         params.OwnerIds = [this.userInfo.id];
            //     }

            //     floatingListService
            //         .getBubblesData(params)
            //         .then((res) => {
            //             this.setBubblesCount(res);
            //         })
            //         .finally(() => {
            //         });
            // },
            // setBubblesCount(res) {
            //     this.statusBubbleCount[0].count = res.applicationCreatedCount;
            //     this.statusBubbleCount[1].count = res.invoiceCreatedCount;

            //     this.statusBubbleCount[2].count = res.fundReceivedCount;
            //     this.statusBubbleCount[3].count = res.rejectedCount;
            //     this.statusBubbleCount[4].count = res.applicationCancelCount;
            //     this.statusBubbleCount[5].count = res.headerPaymentCount;
            //     this.statusBubbleCount[6].count = res.fullPaymentCount;
            // },
            

            // 获取申请页面的泡泡数据
            getApplyBubbleCount() {

        // [Display(Name = "未开票")]
        // ApplicationCreated = 0,

        // [Display(Name = "已驳回")]
        // Rejected = 3,

        // [Display(Name = "未回款")]
        // InvoiceCreated = 9,

        // [Display(Name = "已回款")]
        // FundReceived = 13,

        // [Display(Name = "已取消")]
        // ApplicationCancel = 19
        
                this.statusBubbleCount = [
                        {status: 101, name: '未开票', count: 0},
                        {status: 102, name: '未回款', count: 0},
                        {status: 103, name: '已回款', count: 0},
                        {status: 104, name: '已驳回', count: 0},
                        {status: 105, name: '取消申请', count: 0},
                        // {status: 103, name: '取消申请', count: 0},
                        {status: 106, name: '首款', count: 0},
                        {status: 107, name: '全款', count: 0},
                    ];
                this.applyTable.forEach(item => {
                    if(item.applicationStatus == 0) {
                        this.statusBubbleCount[0].count++;
                    } else if(item.applicationStatus == 9) {
                        this.statusBubbleCount[1].count++;
                    } else if(item.applicationStatus == 13) {
                        this.statusBubbleCount[2].count++;
                    } else if(item.applicationStatus == 3) {
                        this.statusBubbleCount[3].count++;
                    } else if(item.applicationStatus == 19) {
                        this.statusBubbleCount[4].count++;
                    }
                    if(item.paymentMethodType == 0) {
                        this.statusBubbleCount[6].count++;
                    } else if(item.paymentMethodType == 1) {
                        this.statusBubbleCount[5].count++;
                    }
                });
            },

            resize() {
                if ($('.tab-view').width() < 1344) {
                    this.isShrink = true;
                } else {
                    this.isShrink = false;
                }
            },
            
            handleSizeChange(size) {
                this.page.size = size;
                this.page.current = 0;
                if(this.filterIndex == 4) {
                    this.pageination();
                } else{
                    this.requestData();
                }
            },
            handleCurrentChange(current) {
                this.page.current = (current - 1);
                if(this.filterIndex == 4) {
                    this.pageination();
                } else{
                    this.requestData();
                }
            },
            handlePagerJump() {
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total/this.page.size);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.handleCurrentChange(currentPager);
                } else {
                    this.pagerJump = currentPageTotal;
                    this.handleCurrentChange(currentPageTotal);
                }
            },
            pageination() {
                if(this.computedOfferListData.length <= this.page.size) {
                    this.applyTable = this.computedOfferListData;
                } else {
                    this.applyTable = this.computedOfferListData.slice(this.page.current * this.page.size, this.page.current * this.page.size + this.page.size);
                }
                document.querySelector('.el-table__body-wrapper').scrollTop = 0;
                this.page.total = this.computedOfferListData.length;
            },
            candidataSearch(name) {
                this.CandidateName = name;
                this.pageination();
            },
            
            handleApplySelectionChange(val) {
                this.multipleSelection = val;
            },
            handleAllSelect() {
                this.$refs.multipleTable.toggleAllSelection();
            },
            
            timeLimitChange(param, isSearch = true) {
                this.dateRange = param.time;
                // if(param.bubble.status != '') {
                //     switch (param.bubble.status) {
                //         case 101:
                //             this.ApplicationStatus = 0;
                //             break;
                //         case 102:
                //             this.ApplicationStatus = 9;
                //             break;
                //         case 103:
                //             this.ApplicationStatus = 13;
                //             break;
                //         case 104:
                //             this.ApplicationStatus = 3;
                //             break;
                //         case 105:
                //             this.ApplicationStatus = 19;
                //             break;
                //         case 106:
                //             this.PaymentMethodType = 1;
                //             break;
                //         case 107:
                //             this.PaymentMethodType = 0;
                //             break;
                //         default: 
                //             break;
                //     }
                // }
                if(isSearch) {
                    this.page.current = 0;
                    this.getApplyList();
                    if(this.isFinancial) {
                        this.getInvoiceApplicationSearch();
                    }
                }
            },
            timeChangedBubbleCount() {
                //气泡选中后改变时间后气泡聚合
                if (this.isTimeChange) {
                    this.isTimeChange = false;
                    let bubble = this.timeChangedBubble;
                    this.bubbleClick(bubble);
                }
            },
            bubbleClick(param) {
                if(param && param.status && this.filterIndex == 3) {
                    this.ApplicationStatus = null;
                    this.PaymentMethodType = null;
                    console.log(param)
                    switch (param.status) {
                        case 101:
                            this.ApplicationStatus = 0;
                            break;
                        case 102:
                            this.ApplicationStatus = 9;
                            break;
                        case 103:
                            this.ApplicationStatus = 13;
                            break;
                        case 104:
                            this.ApplicationStatus = 3;
                            break;
                        case 105:
                            this.ApplicationStatus = 19;
                            break;
                        case 106:
                            this.PaymentMethodType = 1;
                            break;
                        case 107:
                            this.PaymentMethodType = 0;
                            break;
                        default: 
                            break;
                    }
                    this.page.current = 0;
                    this.getApplyList();
                    if(this.isFinancial) {
                        this.getInvoiceApplicationSearch();
                    }
                }
            },
            bubbleClear(param) {
                this.ApplicationStatus = null;
                this.PaymentMethodType = null;
                this.page.current = 0;
                this.getApplyList();
                if(this.isFinancial) {
                    this.getInvoiceApplicationSearch();
                }
            },

            
            handleApplyExpandChange(row, expandedRows) {
                console.log(arguments);
            },
            // 下载点击弹出下载窗口
            handleDownloadClick(invoiceId,type) {
                // this.downloadDialogVisible = true;
                // this.getInvoicingAttachmentsList();
                // this.$refs.attachmentDialog.show(invoiceId, 0);
                this.$refs.attachmentDialog.show(invoiceId, type);
            },
            seeAttachments(invoiceId) {
                this.$refs.attachmentDialog.show(invoiceId, 1);
            },
            handleDownload() {},
            // 申请开票
            handleApplyTicket(index) {
                const recommendId = this.applyTable[index].recommendId,
                    jobOwnerId = this.applyTable[index].jobOwnerId;
                // isEdit,
                // window.open(`/Headhunting/MyCompany.html#/applyFormPage?recommendId=${recommendId}&jobOwnerId=${jobOwnerId}`);
                // window.open(`/Headhunting/MyCompany.html#/applyFormPage?recommendId=${recommendId}&jobOwnerId=${jobOwnerId}`);
                window.open(`/#/applyFormPage?recommendId=${recommendId}&jobOwnerId=${jobOwnerId}`);
            },
            // (row, column, cellValue, index)
            columnFormatter(column, cellValue) {
                if (['onboardDate', 'created', 'financeInvoiceDate', 'refundDate', 'pmLastOperationTime', ].includes(column)) {
                    return cellValue ? moment(cellValue).format("YYYY-MM-DD") : "";
                } else if (['serviceCharge', 'invoiceAmount', 'refundAmount', 'coverCharge'].includes(column)) {
                    return currencyFilter(cellValue, "", 2);
                } else {
                    return cellValue;
                }
            },

            getRowKeys(row) {
                return row.id;
            }, //获取当前页的信息
            toggleRowExpansion(row) {
                // this.expendsArray=[];
                // this.expendsArray.push(row.recommendId)//展开当前行的信息
            },

            handCellExpand1(row, expandType, index) {
                console.log(`arg:`, arguments)
                if (this.expandType == expandType) {
                    this.expandType = "";
                    this.expendsArray = [];
                    this.currentIndex = -1;
                } else {
                    this.currentIndex = index;
                    this.expandType = expandType;
                    this.expendsArray = [row.id]; //展开当前行的信息
                    // candidate
                    if(expandType == 'invoicingType') {
                        this.getSubTableData(row)
                    }
                }
            },
            handCellExpand(row, expandType, index) {
                if(this.currentIndex != index) {
                    this.currentIndex = index;
                    this.expandType = expandType;
                    this.expendsArray = [row.id]; //展开当前行的信息
                    this.getSubTableData(row);
                } else {
                    this.expandType = "";
                    this.expendsArray = [];
                    this.currentIndex = -1;
                }
            },
            triggerSubTableReload(row) {
                this.expendsArray = [row.id];
                this.getSubTableData(row);
            },
            handSubCellExpand(row, subExpandType, index) {
                this.subCurrentIndex = index;
                this.subExpandType = subExpandType;

                if(this.subExpendsArray.includes(row.id)) {
                    this.subExpendsArray = [];
                    this.subCurrentIndex = -1;
                } else {
                    this.subExpendsArray = [row.id];
                }
            },
            getSubTableData(row) {
                this.applySubTableLoading = true;
                // this.applySubTable = [];
                let tempSubTable = [];
                floatingListService
                    .getInvoiceItemDetailList(row.id)
                    .then(res => {
                        // applicationType: 0, // 0为正数，1为负数
                        // paymentMethodType: 0全款,1首款,2尾款

                        let mark = 0;
                        for(let i  = 0; i< res.length; i++) {
                            const group = res[i];
                            tempSubTable.push({
                                ...group,
                                mark: mark,
                            });
                            if(group.negativeInvoiceApplications && group.negativeInvoiceApplications?.length>0) {
                                tempSubTable.push({
                                    ...group.negativeInvoiceApplications[0],
                                    mark: mark,
                                });
                            }
                            mark++;

                            if(group.paymentMethodType != 0) {
                                group.balanceInvoiceApplications?.length>0 && group.balanceInvoiceApplications.forEach(it => {
                                    tempSubTable.push({
                                        ...it,
                                        mark: mark,
                                    });
                                    if(it.negativeInvoiceApplications && it.negativeInvoiceApplications?.length>0) {
                                        tempSubTable.push({
                                            ...it.negativeInvoiceApplications[0],
                                            mark: mark,
                                        });
                                    }
                                    mark++;
                                });
                            }
                        }
                        console.log(`tempSubTable:`, tempSubTable)

                        // 将更新分组按更新时间倒序
                        this.applySubTable = this.orderGroup(tempSubTable);
                    }).finally(() => {
                        this.applySubTableLoading = false;
                    });
            },
            // 正序【最新的时间排在后面】
            sortDate(arr, keyName) {
                arr.sort(function (per, next) {
                    if (+moment(per[keyName]) < +moment(next[keyName])) {
                        return 1;
                    } else if (+moment(per[keyName]) > +moment(next[keyName])) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                return arr;
            },
            orderGroup(list) {
                let result = [],
                groups = [];
                for(let i=0; i < list.length; i++) {
                    if(list[i+1] && list[i].mark == list[i+1].mark) {
                        groups.push([list[i], list[i+1]]);
                        i++;
                    } else {
                        groups.push([list[i]]);
                    }
                }
                // console.log(`groups:`, groups)
                let timeGroup = [];
                for(let i=0; i<groups.length; i++) {
                    let _time = '';
                    if(groups[i].length ==1) {
                        _time = groups[i][0].pmLastOperationTime;
                    } else {
                        _time = moment(groups[i][0].pmLastOperationTime).isAfter(groups[i][1].pmLastOperationTime) ? groups[i][0].pmLastOperationTime: groups[i][1].pmLastOperationTime;
                    }
                    timeGroup.push({
                        index: i,
                        time: _time,
                    });
                }
                // console.log(`timeGroup:`, timeGroup)
                const sortGroup = this.sortDate(timeGroup, 'time');
                // console.log(`sortGroup:`, sortGroup)
           
                sortGroup.forEach((it, index) => {
                    const _groupsItem = groups[it.index].map(subItem => ({
                        ...subItem,
                        mark: index,
                    }));
                    result = result.concat(_groupsItem);
                });
                console.log(`result:`, result);

                return result;
            },

            applicationConfirm(row, type) {
                this.warningConfirm(`确认开票`).then(() => {
                    floatingListService
                        .invoiceApplicationConfirm({id: row.id})
                        .then(res => {
                            console.log(`applicationConfirm:`, res)
                            shortTips(`操作成功！`);
                            if(type) {
                                this.updateSubTable();
                            } else {
                                this.applySubTable = [];
                                this.expendsArray = [];
                                this.getApplyList();
                                if(this.isFinancial) {
                                    this.getInvoiceApplicationSearch();
                                }
                            }
                        });
                });
            },

            fillInvoiceInfo(row, type) {
                // isApplyNegative=${row.applicationType==1}
                this.$refs.ticketInfoDialog.show(row.id, type, row.applicationType==1, row.invoiceAmount, row.invoiceType);
            },
            fillBackInfo(row, type) {
                this.$refs.backChargeDialog.show(row.id, type, row.applicationType==1, row.invoiceAmount);
            },
            rejectApply(row, type) {
                this.warningConfirm(`驳回`).then(() => {
                    floatingListService.rejectApplication({id: row.id}).then(res => {
                        shortTips(`操作成功！`)
                        if(type) {
                            this.updateSubTable();
                        } else {
                            this.applySubTable = [];
                            this.expendsArray = [];
                            this.getApplyList();
                            if(this.isFinancial) {
                                this.getInvoiceApplicationSearch();
                            }
                        }
                    });
                });
            },
            cancelApply(row, type) {
                let _cancelAllText = '';
                if([0, 1].includes(row.paymentMethodType) && !type) {
                    _cancelAllText = '此操作将取消该申请及其所有相关申请，是否确认继续？';
                }

                this.warningConfirm(`取消`, _cancelAllText).then(() => {
                    floatingListService.cancelApplication({id: row.id}).then(res => {
                        shortTips(`操作成功！`)
                        if(type) {
                            this.updateSubTable();
                        } else {
                            this.applySubTable = [];
                            this.expendsArray = [];
                            this.getApplyList();
                            if(this.isFinancial) {
                                this.getInvoiceApplicationSearch();
                            }
                        }
                    });
                });
            },
            warningConfirm(text, resetAllText) {
                const _tipText = resetAllText ? resetAllText: `此操作将${text}该申请, 是否继续?`;
                return new Promise(resolve => {
                    this.$confirm(_tipText, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        resolve();
                    }).catch(() => {
                        // shortTips(`已取消删除！`)
                    });
                });
            },
            // 编辑
            editApply(row) {
                window.open(`/#/applyFormPage?recommendId=${row.recommendId}&jobOwnerId=${row.ownerId}&invoiceId=${row.id}&isEdit=1&isApplyNegative=${row.applicationType==1}&isApplyBalance=${row.paymentMethodType == 2}&paymentMethodType=${row.paymentMethodType}`);
            },
            // 重新申请
            reApply(row, isSub) {
                window.open(`/#/applyFormPage?recommendId=${row.recommendId}&jobOwnerId=${row.ownerId}&invoiceId=${row.id}&isReApplication=${true}&isApplyNegative=${row.applicationType==1}&isApplyBalance=${row.paymentMethodType == 2}&paymentMethodType=${row.paymentMethodType}`);
            },
            // 重新开票
            reCreateApply(row, isSub) {
                window.open(`/#/applyFormPage?recommendId=${row.recommendId}&jobOwnerId=${row.ownerId}&invoiceId=${row.id}&isReApplication=${true}&isReCreateApplication=${true}&isApplyNegative=${row.applicationType==1}&isApplyBalance=${row.paymentMethodType == 2}&paymentMethodType=${row.paymentMethodType}&refApplicationId=${row.refApplicationId}`);
            },
            // 申请负数发票
            handleApplyNegative(row) {
                // window.open(`/Headhunting/MyCompany.html#/applyFormPage?recommendId=${row.recommendId}&jobOwnerId=${row.ownerId}&invoiceId=${row.id}&isApplyNegative=${true}`);
                window.open(`/#/applyFormPage?recommendId=${row.recommendId}&jobOwnerId=${row.ownerId}&invoiceId=${row.id}&isApplyNegative=${true}`);
            },
            // 申请尾款发票
            handleApplyBalance(row) {
                // window.open(`/Headhunting/MyCompany.html#/applyFormPage?recommendId=${row.recommendId}&jobOwnerId=${row.ownerId}&invoiceId=${row.id}&isApplyBalance=${true}`);
                // window.open(`/Headhunting/MyCompany.html#/applyFormPage?recommendId=${row.recommendId}&jobOwnerId=${row.ownerId}&invoiceId=${row.id}&isApplyBalance=${true}&isAttachment=${false}`);
                window.open(`/#/applyFormPage?recommendId=${row.recommendId}&jobOwnerId=${row.ownerId}&invoiceId=${row.id}&isApplyBalance=${true}&isAttachment=${false}`);
            },
            // 开票类型单元格内容
            generateInvoiceTypeCell(row, type) {
                let _tagText = '', 
                    _tagsStr = '',
                    _class= '', 
                    _tagInner = '';

                // IsNegativeRefund
                // IsBalanceRefund
                // IsBalanceNegativeRefund
                // NegativeStatus
                // BalanceStatus
                // BalanceNegativeStatus

                if(row.isBalance || (row.paymentMethodType == 33 && type)) {
                    _class = 'color-0';
                    _tagInner = '尾';
                    _tagsStr += `<span class="tip-icon ${_class}">${_tagInner}</span>`;
                }
                if(row.isNegative || (row.applicationType==1 && type)) {
                    _class = 'color-a';
                    _tagInner = '负';
                    _tagsStr += `<span class="tip-icon ${_class}">${_tagInner}</span>`;
                }
                if(row.applicationStatus == 19) {
                    _class = 'color-b';
                    _tagInner = '取消';
                    _tagsStr += `<span class="tip-icon ${_class}">${_tagInner}</span>`;
                }
                if(row.applicationStatus == 3) {
                    _class = 'color-c';
                    _tagInner = '驳回';
                    _tagsStr += `<span class="tip-icon ${_class}">${_tagInner}</span>`;
                }

                if("" + row.paymentMethodType) {
                    switch(row.paymentMethodType){
                        case 0: 
                            _tagText = '全款'; break;
                        case 1: 
                            _tagText = '首款'; break;
                        case 2: 
                            _tagText = '尾款'; break;
                        default: break;
                    }
                }
                
                if(!type) {
                    let _tagArray = [];
                    if(row.refPaymentMethodTypeIds && row.refPaymentMethodTypeIds?.length>0) {
                        _tagArray = row.refPaymentMethodTypeIds.map(it => {
                            return paymentMethodTypeMap[String(it)];
                        });
                    }
                    if(_tagArray.length>0) {
                        _tagText = _tagArray.join("/");
                    }
                }
              
                const _arrow = type ? `<i class="el-icon el-icon-arrow-right hidden"></i>`: `<i class="el-icon el-icon-arrow-right"></i>`;
                return `${_arrow}${_tagText}${_tagsStr}`;
            },
            getTag(row) {
                let _class= '', 
                    _tagInner = '尾';
                if(row.isBalanceRefund) {
                    _class = 'color-0';
                } else if(row.isBalance) {
                    _class = 'color-a';
                } else if(row.isNegativeRefund) {
                    _class = 'color-b';
                } else if(row.isNegative) {
                    _class = 'color-c';
                } else if(row.isBalanceNegativeRefund) {
                    _class = 'color-d';
                } else if(row.isBalanceNegative) {
                    _class = 'color-e';
                } else if(row.applicationStatus == 19) {
                    _class = 'color-f';
                    _tagInner = '取消';
                }
                const _tag = _class ? `<span class="tip-icon ${_class}">${_tagInner}</span>` : ``;
                return _tag;
            },
            memberChange(userIds) {
                this.selectedUserIds = userIds;
                this.page.current = 0;
                if(this.filterIndex == 4) {
                    this.getOfferListRecommendations();
                } else {
                    this.getApplyList();
                    if(this.isFinancial) {
                        this.getInvoiceApplicationSearch();
                    }
                }
            },
            
            // 生成通知函
            generateInform() {
                const params = {
                    OwnerIds: this.selectedUserIds,
                    StartDate: this.dateRange.startDate,
                    EndDate: this.dateRange.endDate,
                    ApplicationStatus: this.ApplicationStatus,
                    PaymentMethodType: this.PaymentMethodType,
                    invoiceIds: this.multipleSelection.map(item=> item.id)
                };

                this.exportNoticeLetterLoading = true;
                floatingListService
                .noticeLetterExport(params)
                .then(res => {
                    if(res.status == 200) {
                        downloadFile(res, '通知函.docx');
                    } else {
                        shortTips(`网络错误，导出失败！`)
                    }
                })
                .finally(() => {
                    this.exportNoticeLetterLoading = false;
                });
            },
            // 更新展开的子表内容（信息提交成功后）【如果是把表格的情况】
            updateSubTable() {
                const row = this.applyTable[this.currentIndex];
                console.log(`row:`, row)
                // this.getSubTableData(row);

                // this.handCellExpand(row, 'invoicingType', this.currentIndex);
                this.triggerSubTableReload(row);
            },
            // 导出明细
            exportDetail() {
                const params = {
                    OwnerIds: this.selectedUserIds,
                    // OwnerIds: [],
                    StartDate: this.dateRange.startDate,
                    EndDate: this.dateRange.endDate,
                    ApplicationStatus: this.ApplicationStatus,
                    PaymentMethodType: this.PaymentMethodType,
                };
                this.exportLoading = true;
                floatingListService
                .exportDetail(params)
                .then(res => {
                    downloadFile(res, '开票明细.xlsx');
                }).finally(() => {
                    shortTips(`导出成功！`)
                    this.exportLoading = false;
                });
            },
            selectChange({time, selectData}) {
                this.dateRange = time;

                this.ApplicationStatus = selectData.invoiceStatus == '-1' ? '' : selectData.invoiceStatus;
                this.InvoiceApplicationType = selectData.invoiceType == '-1' ? '' : selectData.invoiceType;
                this.PaymentMethodType = selectData.PaymentMethodType == '-1' ? '' : selectData.PaymentMethodType;
                this.invoiceEntity = selectData.invoiceEntity == '-1' ? '' : selectData.invoiceEntity;
                
                this.page.current = 0;
                this.getApplyList();
                if(this.isFinancial) {
                    this.getInvoiceApplicationSearch();
                }
            },
            // Offer列表编辑业绩
            performanceEdit(row) {
                this.$refs.offerDialog.showHandle(row.id, null, '编辑业绩', true, false, false);
            },
            // offer列表编辑业务分类
            businessClassficationEdit(row) {
                this.$refs.businessClassficationDialog.show(row);
            },
            candidateClick(row) {
                console.log(arguments)
                this.$refs.invoiceDetailDialog.show(row.id);
            },

            unApplySelectDataChange(label, result) {
                if(result.length > 0){
                    this.unApplyFilter[label] = result[0].label || '';
                    this.handleAddLabel(label, result, true);
                } else {
                    this.unApplyFilter[label] = '';
                    this.handleCleanLabel(label);
                }
                this.page.current = 0;
                this.getPMUnApplyList();
            },

            getInvoiceStatus(row) {
                return invoiceStatusMap[String(row.applicationStatus)] || '未知';
            },

            tableRowClassName({row, rowIndex}) {
                if(row.mark%2==1) {
                    return 'double-row';
                } else {
                    return 'sub-row';
                }
            },


        },
        watch: {
            'this.$store.state.workbench.isActive': () => {
                this.resize();
            },
            // ApplicationStatus() {
            //     this.getApplyList();
            // }
        },
    };
</script>

<style lang="scss" scoped>
    .color-orange {
        color: $orange;
    }

    .center {
        display: table;
        margin: auto;
    }

    .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon-market {
        position: absolute;
        display: inline-block;
        left: -31px;
        top: -8px;
        background-color: #ff6564;
        border-radius: 4px;
        padding: 3px;
        font-size: 22px;
        color: #fff;
        transform: scale(0.5);

        &:before {
            content: "市场";
            display: inline;
        }
    }
    .await-confirm {
        position: absolute;
        display: inline-block;
        left: 25px;
        top: -8px;
        background-color: #fef6e8;
        border-radius: 4px;
        padding: 3px;
        font-size: 22px;
        color: #f5a623;
        transform: scale(0.5);

        &:before {
            content: "待确认";
            display: inline;
        }
    }

/*
        .bg-gray{

            box-shadow: 4px 0 4px -2px #CCCCCC;
        }
    */

    .select-container {
        // margin-top: 16px;
        margin-top: 6px;
        margin-bottom: 6px;
        // line-height: 19px;
        line-height: 44px;
        position: relative;
        padding-right: 40px;

        &-point-tip {
            margin-left: 10px;
            font-size: 14px;
            color: #ee6e4f;
            line-height: 28px;
            float: left;
        }

        // > span:first-child {
        //     float: left;
        //     line-height: 28px;
        // }

        .float-left{
            float: left;
        }
        .select-tag-list {
            display: block;
            padding: 10px 0 5px 10px;
            overflow: hidden;
            .select-tag {
                display: inline-block;
                position: relative;
                float: left;
                margin-right: 10px;
                margin-bottom: 5px;
                padding: 0 28px 0 8px;
                max-width: 486px;
                height: 24px;
                line-height: 24px;
                color: #999;
                background: #F8F8F8;
                border: 1px solid #ddd;
                border-radius: 4px;
                &:last-child {
                    margin-right: 0;
                }
                .btn-close {
                    float: right;
                    position: absolute;
                    right: 10px;
                    top: 0;
                    &:after {
                        display: inline-block;
                        content: '+';
                        float: right;
                        width: 14px;
                        height: 24px;
                        transform: rotate(-45deg);
                        font-size: 20px;

                        text-align: center;
                        line-height: 22px;
                        cursor: pointer;
                    }
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        // 再次操作确认进入面试列表相关代码
        .show-confirm-list {
            position: absolute;
            top: 0;
            right: 40px;
        }
        .show-confirm-list-btn {
            background-color: #fff;
            border-color: #ccc;
            color: #666;
            position: relative;
            z-index: 1000;
            &:hover {
                background-color: #48caac;
                border-color: #48caac;
                color: #fff;
            }
        }
        .show-confirm-list-guide {
            position: absolute;
            bottom: 40px;
            left: -72px;
            z-index: 1000;
            width: 254px;
            height: 64px;
            border-radius: 6px;
            background-color: #fff;
            color: #333;
            font-size: 16px;
            text-align: center;
            line-height: 64px;
            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 136px;
                width: 10px;
                height: 10px;
                background-color: #fff;
                transform: rotate(-45deg);
                transform-origin: 0 0;
            }
            .hide-guide-btn {
                position: absolute;
                right: 6px;
                top: 6px;
                font-size: 14px;
                font-weight: bold;
                color: #999;
                cursor: pointer;
                &:hover {
                    color: #333;
                }
            }
        }
        .show-confirm-list-modal {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 999;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 再次操作确认进入面试列表相关代码 --end
        .floating-list-table-title {
            position: absolute;
            top: 5px;
            right: 0px;
        }
    }

.work-table-container {
    height: 100%;
    overflow-x: hidden;
    .table-slot.el-popover__reference {
        cursor: pointer;
        &:hover {
            color: #38BC9D;
        }
    }
    .table-slot.el-dropdown-link {
        cursor: pointer;
        &:hover {
            color: #38BC9D;
        }
    }
    .table-header-dropdown-menu {
        .sort-icon {
            margin-left: 5px;
        }
    }
    .work-table /deep/ {
        height: 100%;
        min-height: 500px;
        padding: 10px 20px 0 20px;
        background: url("~@src/assets/images/home/home_bg.png") bottom center
            no-repeat;
        display: flex;
        flex-direction: column;

        .el-table {
            height: 60.6% !important;
            min-height: 244px;
            overflow-y: auto;
            &.sub-table {
                height: auto !important;
                min-height: 20px;
                overflow-y: hidden;
                // .el-table__body-wrapper {
                //     overflow-x: scroll !important; // 设置横轴滚动条
                // }
                /deep/ .el-table__fixed {
                height: 100% !important; //设置高优先，以覆盖内联样式  但是会遮挡滚动条
                }
                /deep/ .el-table__fixed-right {
                    height: 100%!important;
                }
                .el-table__fixed-right {
                    height: 100%!important;
                }
                .el-table__empty-block{
                    min-height: 42px;
                }
                .cell{
                    .el-checkbox{
                        visibility: hidden;
                    }
                }
            }

            .el-loading-mask {
                z-index: 900;
            }

            th {
                padding: 0;
                height: 42px;
                line-height: 42px;
                background-color: #e8e8e8;
                border-color: #cccccc;
                color: #666666;

                .el-checkbox {
                    padding: 0 10px;
                }

                .cell {
                    padding: 0;

                    .caret-wrapper {
                        display: none;
                    }

                    .el-dropdown {
                        float: left;
                        width: 100%;
                        padding: 0;
                        text-align: center;
                        &.time-sort-dropdown {
                            line-height: unset;
                            margin-top: -7px;
                        }
                    }

                    .el-checkbox {
                        display: none;
                    }
                }

                &.name-cell .table-slot {
                    text-indent: 0;
                }
            }

            td {
                padding: 0;
                height: 40px;
                line-height: 40px;

                .cell {
                    white-space: nowrap;

                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #fff;
                        border-color: $primary;

                        &::after {
                            border-color: $primary;
                        }
                    }
                }

                .table-avatar {
                    display: block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }

                &.btn-menu .cell {
                    padding: 0;
                }

                &.avatar-cell .table-slot {
                    text-indent: 0;
                }

                .recommend-avatar {
                    position: relative;
                }
            }

            .el-table__row {
                .el-checkbox__inner {
                    border-color: #999;
                }

                &.lazy {
                    td {
                        color: #bbbbbb;

                        &.name-cell .cell .candidate-link {
                            color: #bbb;

                            &:hover {
                                color: $primary;
                            }
                        }

                        &.expand-cell {
                            .el-table__expand-icon {
                                color: #bbb;
                            }

                            .el-table__expand-icon--expanded {
                                color: $primary;
                            }
                        }
                    }

                    .el-checkbox__inner {
                        border-color: #bbbbbb;
                    }
                }

                &.sub-row {
                    // background: #f8f8f8;
                    background: white;
                }
                &.double-row{
                    // background: #c9c6c6;
                    background: #f0f9eb;
                }
            }

            .table-slot {
                padding: 0;
                text-indent: 10px;
                line-height: unset;
            }

            .table-slot-content {
                text-indent: 0;
            }

            .is-center .table-slot {
                text-indent: 0;
            }

            .time-sort-cell .cell {
                line-height: 40px;
            }

            .expand-cell {
                border-right: none;

                .el-table__expand-icon--expanded {
                    color: $primary;
                }

                .el-table__expand-icon > .el-icon {
                    z-index: 10;

                    &.el-icon-arrow-right {
                        font-weight: bold;
                    }
                }
            }
            .hide-expand-cell {
                border-right: none;
                .cell {
                    display: none;
                }
            }

            .name-cell .cell {
                padding-left: 5px;

                .candidate-link {
                    color: #666;

                    &:hover {
                        color: $primary;
                    }
                }
            }
            .reset-expand-cell {
                .ex-cell {
                    cursor: pointer;
                    > i {
                        font-weight: bold;
                    }
                    .hidden{
                        visibility: hidden;
                    }
                    .doc-icon{
                        vertical-align: bottom;
                    }
                    
                    
                    .tip-icon{
                        display: inline-block;
                        // width: 35px;
                        text-align: right;
                        // margin-right: 10px;
                        height: 16px;
                        line-height: 15px;
                        background: #38BC9D;
                        border-radius: 4px;
                        font-size: 12px;
                        color: white;
                        padding: 0 4px;
                        margin-left: 5px;
                    }
                    // .color-d{
                    //     background-color: #38AEBC;
                    // }
                    
                    .color-a{
                        background-color: #4A90E2;
                    }
                    .color-b{
                        background-color: #F36BC2;
                    }
                    .color-c{
                        background-color: #FC7859;
                    }
                    .color-d{
                        background-color: #F5A623;
                    }
                    // .color-e{
                    //     background-color: #F5CB23;
                    // }
                    .color-void{
                        background-color: #878586;
                    }
                }
                .cell-expanded > i {
                    color: #38bc9d;
                    transform: rotate(90deg);
                }
                .hidden > i{
                    visibility: hidden;
                }
            }

            .bg-green-dark {
                background-color: #ebf8f5;
            }

            .bg-orange-dark {
                background-color: #ffe4ce;
            }

            .el-table__empty-block {
                .el-table__empty-text {
                    line-height: inherit;
                }

                .empty-img {
                    display: inline-block;
                    background: url("~@src/assets/images/home/icon_no_data.svg")
                        no-repeat;
                    width: 240px;
                    height: 228px;
                }

                .empty-data {
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 0px;
                }
            }

            .el-table-column--selection {
                border-right: none;
            }

            .recommend-dynamic {
                position: relative;
                z-index: 5;
            }
            .sm-drop{
                padding-left: 10px;
            }
            .cell-btn-wrap {
                padding: 0 10px;
                .apply-ticket {
                    color: #38bb9c;
                    font-size: 14px;
                    margin-right: 12px;
                }
                .orange {
                    color: #fc7859;
                }
                .option-btn {
                    margin-right: 20px;
                    &:last-child {
                        margin: 0;
                    }
                }
                .text-gray{
                    color: #999;
                    cursor: pointer;
                }
            }
        }

        .el-table__fixed {
            .recommend-dynamic {
                visibility: hidden;
            }
        }
        .work-table-footer .footer-left .work-table-btn {
            display: inline-block;
            height: 28px;
            width: 84px;
            margin-right: 10px;
            border-radius: 4px;
            color: #fff;
            border: none;
            outline: none;

            &.work-table-btn-orange {
                background-color: #fc7859;

                &:hover {
                    background-color: #ee6e4f;
                }
            }

            &.work-table-btn-primary {
                background-color: $primary;

                &:hover {
                    background-color: #36b495;
                }
            }

            &.work-table-btn-blue {
                min-width: 126px;
                background-color: #3c8ae2;
                cursor: pointer;
                &:hover {
                    background-color: #4a90e2;
                }
            }

            &[disabled] {
                background-color: #f8f8f8;
                border: 1px solid #dddddd;
                color: #999999;
                cursor: not-allowed;

                &:hover {
                    background-color: #f8f8f8;
                }
            }
        }
    }
}


.el-popover.talentpool-name-popover{
    padding: 15px;
    padding-right: 35px;
    .popover-card{
        font-size: 12px;
        letter-spacing: 0px;
        .row-l{
            display: inline-block;
            width: 56px;
            text-align: right;
        }
        .tip-icon{
            display: inline-block;
            // width: 35px;
            text-align: right;
            margin-right: 10px;
            height: 16px;
            line-height: 15px;
            background: #38BC9D;
            border-radius: 4px;
            font-size: 12px;
            color: white;
            padding: 0 4px;
        }
        .color-a{
            background-color: #4A90E2;
        }
        .color-b{
            background-color: #F36BC2;
        }
        .color-c{
            background-color: #FC7859;
        }
        // .color-d{
        //     background-color: #38AEBC;
        // }
        // .color-e{
        //     background-color: #F5CB23;
        // }
        // .color-f{
        //     background-color: #F36BC2;
        // }
        .occupy-flex{
            display: flex;
            .tip-occupy{
                width: 49px;
                margin-right: 13px;
            }
        }
        .card-tip{
            margin-bottom: 10px;
        }
        .card-tip:nth-last-of-type(1){
            margin-bottom: 0px;
        }
    }
}

</style>

<style lang="scss">
    @import "#/css/scss/variables.scss";
    .el-popover.el-time-popover{
        .popper__arrow {
            display: none;
        }
    }
    .recommend-time-dropdown {
        &.el-dropdown-menu.el-popper {
            width: 124px;
            margin: 0;
            padding: 0;
            text-align: center;
            top: 220px;
        }

        .el-dropdown-menu__item {
            color: #666;
            padding: 0;
        }

        .popper__arrow {
            display: none;
        }

        .checked {
            color: $primary;
            background-color: rgba($primary, 0.1);
        }
    }

    .header-name-popover{
        margin-left: -23px;
    }

    .el-tooltip__popper {
        max-width: 300px;
    }

    .label-tip.el-tooltip__popper.is-light {
        margin-left: -8px;
        max-width: 480px;
        line-height: 20px;
        border: none;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .popper__arrow {
            border: none;
        }
    }
    .filter-wrap {
        padding: 10px 20px;
        background-color: #ffffff;
        border-radius: 4px;
        .filter-area {
            border-top: 1px dashed #ccc;
            box-sizing: border-box;
            padding-top: 10px;
            margin-top: 10px;
            .filter-left-text {
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
            }
            .filter-cell {
                display: inline-block;
                margin-right: 20px;
            }
            .hot-light {
                color: #38bc9d;
            }
            &.no-top-border {
                border-top: 1px dashed transparent;
            }
        }
    }
    .el-table {
        .el-table__body-wrapper {
            overflow-y: auto !important;
        }
    }
</style>

