<template>
    <el-dialog
        title="开票详情"
        :visible.sync="dialogVisible"
        width="380px"
        :before-close="handleClose"
        custom-class="invoice-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        >
        <div class="dialog-body-wrap" v-loading="loading">
        <el-tabs 
            v-model="activeTab" 
            @tab-click="handleClick"
            >
            <el-tab-pane label="业绩" name="performance"></el-tab-pane>
            <el-tab-pane label="客户信息" name="infos"></el-tab-pane>
            <el-tab-pane label="操作日志" name="logs" v-if="isFinancial"></el-tab-pane>
        </el-tabs>

        <div class="tab-container" v-if="activeTab == 'performance'">
            <div class="block">
                <div class="row-line">
                    <div class="row-l">入职时间：</div>
                    <div class="row-r" v-text="formatDate(detail.entryDate)">2021-01-04</div>
                </div>
                <div class="row-line">
                    <div class="row-l">入职确认证明中的入职时间：</div>
                    <div class="row-r" v-text="formatDate(detail.actualEntryDate)">2021-01-04</div>
                </div>
                <div class="row-line">
                    <div class="row-l">过保(离职)日期：</div>
                    <div class="row-r" v-text="detail.applicationType==0 ? formatDate(detail.overWarrantyDate) : formatDate(detail.resignationDate)">2021-01-04</div>
                </div>
                <div class="row-line">
                    <div class="row-l">合同约定回款日期：</div>
                    <div class="row-r" v-text="formatDate(detail.contractAgreedRefundDate)">2021-01-04</div>
                </div>
            </div>
            <hr>
            <div class="block">
                <div class="p-line" 
                    v-for="(item, index) in offerSplits"
                    :key="'offerItem_' + index"
                    >
                    <div class="col-l ellipsis" 
                        v-text="item.name" 
                        :title="item.name">BD</div>
                    <div class="col-m ellipsis" 
                        :title="item.userName">姓名：
                        <span v-text="item.userName">jerry张攀</span>
                    </div>
                    <div class="col-r ellipsis" :title="'业绩金额：' + currencyFilter(computedPercent*item.charge)">业绩金额：<span v-text="currencyFilter(computedPercent*item.charge)">43,200.00</span></div>
                </div>
            </div>
            <hr>
            <div class="block">
                <div class="row-line">
                    <div class="row-l">开票比例：</div>
                    <div class="row-r" v-text="computedPercent*100 + '%'">10%</div>
                </div>
                <div class="row-line" v-if="detail.paymentMethodType != 2">
                    <div class="row-l">提供入职证明：</div>
                    <template v-if="hasAttach">
                        <div class="row-r green" @click="() => $emit('download-click', id, 0)">入职确认证明</div>
                    </template>
                    <template v-else>
                        <div class="row-r">无</div>
                    </template>
                </div>
                <div class="row-line" v-if="detail.applicationType == 1">
                    <div class="row-l">提供离职证明：</div>
                    <template v-if="hasLeavingAttach">
                        <div class="row-r green" @click="() => $emit('download-click', id, 3)">离职确认证明</div>
                    </template>
                    <template v-else>
                        <div class="row-r">无</div>
                    </template>
                </div>
                <div class="row-line">
                    <div class="row-l">提供付款通知函证明：</div>
                    <template v-if="hasPayAttach">
                        <div class="row-r green" @click="() => $emit('download-click', id, 2)">付款通知函确认证明</div>
                    </template>
                    <template v-else>
                        <div class="row-r">无</div>
                    </template>
                </div>
                <div class="row-line">
                    <div class="row-l">PM备注：</div>
                    <div class="row-r" v-text="detail.ownerRemarks ? detail.ownerRemarks: '无'">2021-01-04</div>
                </div>
                <div class="row-line" v-if="detail.applicationType == 1">
                    <div class="row-l">负数发票抵扣方式：</div>
                    <div class="row-r" v-text="detail.deductionTypeName">抵扣其它单</div>
                </div>
                <div class="row-line" v-if="detail.applicationType == 1 && detail.deductionType == 1">
                    <div class="row-l">负数发票关联订单：</div>
                    <div class="row-r">候选人 {{detail.deductionCandidateName}}——顾问 {{detail.deductionOwnerName}}</div>
                </div>
            </div>
        </div>

        <div class="tab-container" v-if="activeTab == 'infos'">
            <div class="block">
                <div class="row-line bold">收件人信息</div>
                <div class="row-line">
                    <div class="row-l">收件人：</div>
                    <div class="row-r" v-text="detail.expressReceiver">小明小明小明</div>
                </div>
                <div class="row-line">
                    <div class="row-l">联系电话：</div>
                    <div class="row-r" v-text="detail.expressPhone">18118778552</div>
                </div>
                <div class="row-line">
                    <div class="row-l">发票快递地址：</div>
                    <div class="row-r" v-text="detail.expressAddress">广东省深圳市南山区深圳湾科技生态园9栋B1座13层1312室</div>
                </div>
            </div>
            <hr>
            <div class="block">
                <div class="row-line bold">公司信息</div>
                <div class="row-line">
                    <div class="row-l">公司名称：</div>
                    <div class="row-r" v-text="detail.companyName">深圳八爪科技网络有限公司</div>
                </div>
                <div class="row-line">
                    <div class="row-l">纳税人识别号：</div>
                    <div class="row-r" v-text="detail.companyTaxpayerNo">678323724687</div>
                </div>
                <div class="row-line">
                    <div class="row-l">注册地址：</div>
                    <div class="row-r" v-text="detail.companyRegisterAddr">广东省深圳市南山区深圳湾科技生态园9栋B1座13层1312室</div>
                </div>
                <div class="row-line">
                    <div class="row-l">联系电话：</div>
                    <div class="row-r" v-text="detail.companyPhone">13609874657</div>
                </div>
                <div class="row-line">
                    <div class="row-l">开户银行：</div>
                    <div class="row-r" v-text="detail.companyBankofDeposit">中国人民银行</div>
                </div>
                <div class="row-line">
                    <div class="row-l">银行账号：</div>
                    <div class="row-r" v-text="detail.companyBankCardNo">1098238498745638</div>
                </div>
            </div>
        </div>

        <div class="tab-container" v-if="activeTab == 'logs'">
            <div class="block">
            <div class="body-scroll">
                <div class="record-item" 
                    v-for="(item, index) in operationLogs" 
                    :key="'operationLog_' + index"
                    >
                    <div class="pic">
                        <img :src="item.operatorAvatarUrl" alt="">
                    </div>
                    <div class="content-list">
                        <div class="item-row small">
                            <span class="gray" v-text="item.created">2021-04-08 12:38:34</span>
                        </div>
                        <div class="item-row ellipsis" :title="item.operatorName + '@' + item.operatorNickName">
                            <span class="black" v-text="item.operatorName">查明123</span>
                            <span class="gray">@{{item.operatorNickName}}</span>
                        </div>
                        <div class="item-row">
                            <span class="black" v-text="generateLogText(item)">申请开全款发票</span>
                        </div>
                    </div>
                </div>
                <div class="empty-data" v-if="operationLogs.length == 0">
                    <span class="empty-img "></span>
                    <p>暂无数据</p>
                </div>
            </div>
            </div>

        </div>
        </div>
    </el-dialog>
</template>

<script>
import moment from 'moment';
import { currency as currencyFilter } from "#/js/filters/number.js";
import floatingListService from "#/js/service/floatingListService.js";

export default {
    name: 'invoice-detail-dialog',
	components: {
	},
	data() {
		return {
            dialogVisible: false,
            activeTab: 'performance',
            loading: false,
            id: '',

            detail: {},
            offerSplits: [],
            hasAttach: false, // 是否有入职证明附件
            hasLeavingAttach: false, // 是否有离职证明附件
            hasPayAttach: false, // 是否有付款通知函附件
            operationLogs: [], // 操作日志
        };
    },
    computed: {
        computedPercent() {
            if(this.detail.paymentMethodType == '0') {
                return this.detail.applicationType == 1 ? -1: 1;
            } else {
                return this.detail.applicationType == 1 ? -this.detail.paymentProportion : +this.detail.paymentProportion;
            }
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isFinancial () {
            return this.userInfo.privilegeCodeList && this.userInfo.privilegeCodeList.includes('Financial');
        },
    },
    created() {},
	mounted() {},
	methods: {
        show(id) {
            this.id = id;
            this.activeTab = 'performance';

            this.loadData();
            this.dialogVisible = true;
        },
        handleClose() {
            this.dialogVisible = false;
        },
        handleClick(tab, event) {
            console.log(arguments)
            console.log(tab, event);
        },
        loadData() {
            this.loading = true;
            Promise.all([this.getItemDetail(), this.getItemOfferSplits(), this.getAttach(0), this.getAttach(2), this.getAttach(3), this.getRecordsList()]).then((result) => {
                console.log(result)
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                this.loading = false;
            });
        },
        getItemDetail() {
            return new Promise(resolve => {
                floatingListService
                .getInvoiceItemDetail(this.id)
                .then(res => {
                    this.detail = res || {};
                    resolve(true);
                });
            });
        },
        getItemOfferSplits() {
            return new Promise(resolve => {
                floatingListService
                    .getInvoiceItemOfferSplits(this.id)
                    .then(res => {
                        this.offerSplits = res || {};
                        this.offerSplits.sort((a, b) => a.name.localeCompare(b.name, 'zh-CN'));
                        resolve(true);
                    });
                });
        },
        getAttach(type) {
            return new Promise(resolve => {
                //  [Display(Name = "开票申请")]
                // Application = 0,
                // [Display(Name = "财务开票")]
                // FinanceInvoice = 1
                const params = {
                    invoiceId: this.id,
                    // attachmentType: 0,
                    attachmentType: type,
                };
                floatingListService
                .invoicingAttachmentsList(params)
                .then(res => {
                    // this.hasAttach = (res || []).length > 0;
                    if(type == 0) {
                        this.hasAttach = (res || []).length > 0;
                    } else if(type == 2) {
                        this.hasPayAttach = (res || []).length > 0;
                    } else if(type == 3) {
                        this.hasLeavingAttach = (res || []).length > 0;
                    }
                    resolve(true);
                })
            });
        },
        getRecordsList() {
            return new Promise(resolve => {
                floatingListService
                .getInvoiceOperationLogs(this.id)
                .then(res => {
                    this.operationLogs = res;
                    resolve(true);
                })
            });
        },
        generateLogText(logItem) {
            const actionsMap = ['创建', '重新申请', '编辑', '填写', '填写', '驳回', '取消', '移交', '确认', '作废'];
            const paymentMethodMap = ['全款', '首款', '尾款'];
            const applicationTypeMap = ['', '负数'];
            //    开票申请 开票信息 回款信息
            const ticketsType = ['开票申请', '开票申请', '开票申请', '开票信息', '回款信息', '开票申请', '开票申请', '开票申请', '开票申请', '开票申请'];
            // 提交了全款开票申请
            // 当前状态为开票中
            return `${actionsMap[+logItem.operationType]}了${paymentMethodMap[+logItem.paymentMethodType]}${applicationTypeMap[+logItem.applicationType]}${ticketsType[+logItem.operationType]}`;
            // return `${logItem.operationTypeName}${logItem.paymentMethodTypeName}${logItem.applicationTypeName}`;
        },
        formatDate(val) {
            if(val) return moment(val).format('YYYY-MM-DD');
        },
        currencyFilter(cellValue) {
            return currencyFilter(cellValue, "", 2);
        },
    }
}
</script>
<style lang="scss" scope>
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.invoice-dialog{
    .el-dialog__body{
        padding: 0;
        .el-tabs__header{
            margin: 0;
        }
        .el-tabs__nav{
            margin-left: 30px;
        }
        .tab-container{
            >hr{
                border-top: 1px dashed #ccc;
                border-bottom: 0;
                margin: 0;
            }
            .block{
                box-sizing: border-box;
                padding: 20px 5px 20px 30px;

                .row-line{
                    display: flex;
                    font-size: 14px;
                    line-height: 28px;
                    .row-l{
                        color: #666;
                    }
                    .row-r{
                        flex: 1;
                        color: #333;
                    }
                    .green{
                        color: #38BC9D;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
                .bold {
                    font-weight: bold;
                }
                .p-line{
                    font-size: 14px;
                    line-height: 24px;
                    color: #666;
                    .col-l{
                        display: inline-block;
                        width: 42px;
                        vertical-align: top;
                        font-weight: bold;
                    }
                    .col-m{
                        display: inline-block;
                        width: 134px;
                        vertical-align: top;
                        >span{
                            color: #333;
                        }
                    }
                    .col-r{
                        display: inline-block;
                        width: 162px;
                        vertical-align: top;
                        >span{
                            color: #333;
                        }
                    }

                }


                .body-scroll{
                    min-height: 400px;
                    max-height: 500px;
                    overflow-y: auto;
                    overflow-x: visible;
                    .record-item{
                        height: auto;
                        // background: #F6F6F6;
                        // display: flex;
                        // padding: 10px 10px 2px;
                        // margin-bottom: 10px;
                        box-sizing: border-box;
                        border-left: 1px dashed #ccc;
                        position: relative;
                        margin-left: 4px;
                        padding-bottom: 20px;
                        &::before{
                            content: '';
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            background: #38BB9C;
                            border-radius: 50%;
                            position: absolute;
                            left: -4.5px;
                            top: 16px;
                        }
                        .pic{
                            display: inline-block;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            overflow: hidden;
                            // background: blue;
                            margin-right: 10px;
                            margin-left: 14px;
                            vertical-align: top;
                            >img{
                                width: 40px;
                                height: 40px;
                            }
                        }
                        .content-list{
                            display: inline-block;
                            max-width: 240px;
                            height: auto;
                            vertical-align: top;
                            .item-row{
                                font-size: 14px;
                                line-height: 26px;
                                .black{
                                    color: #666;
                                }
                                .gray{
                                    color: #B9B9B9;
                                }
                            }
                            .small{
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                        // .item-l{
                        //     flex: 1;
                        //     >span{
                        //         background: #FFFFFF;
                        //         border: 1px solid #AFE4D8;
                        //         border-radius: 4px;
                        //         font-size: 12px;
                        //         color: #38BC9D;
                        //         // line-height: 40px;
                        //         margin-right: 10px;
                        //         padding: 7px 11px;
                        //         margin-bottom: 8px;
                        //         // word-break: normal;
                        //         display: inline-block;
                        //         box-sizing: border-box;
                        //     }
                        // }
                        // .item-r{
                        //     width: 80px;
                        //     text-align: right;
                        //     >a{
                        //         font-size: 14px;
                        //         color: #38BC9D;
                        //         line-height: 26px;
                        //         &:first-child{
                        //             margin-right: 10px;
                        //         }
                        //     }
                        // }
                    }

                    .empty-data{
                        text-align: center;
                        margin-top: 100px;
                        .empty-img{
                            display: inline-block;
                            background: url("~@src/assets/images/home/icon_no_data.svg") no-repeat;
                            background-size: contain;
                            width: 160px;
                            height: 160px;
                        }
                        >p{
                            font-size: 16px;
                            line-height: 21px;
                            margin-bottom: 0px;
                        }
                    }


                }
            }
            
        }
    }
    
}
</style>