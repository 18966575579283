<template>
    <el-dialog
        :title="title"
        :visible.sync="downloadDialogVisible"
        width="400px"
        :lock-scroll="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        custom-class="download-dialog"
        :before-close="handleDownloadDialogClose">
        <div class="download-row-wrap" v-loading="loading">
            <div
                class="download-row"
                v-for="(item, index) in attachments"
                :key="'file_' + index">
                <div class="row-l ell" v-text="item.fileName" :title="item.fileName">文件名称.doc</div>
                <div class="row-r" @click="handleDownload(item)">
                    <font-icon
                        class="icon-download"
                        href="#icon-download"
                    ></font-icon>
                </div>
            </div>

            <div class="empty-data" v-if="attachments.length == 0">
                <span class="empty-img "></span>
                <p>暂无数据</p>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import floatingListService from "#/js/service/floatingListService.js";

export default {
    name: 'attachment-dialog',
	components: {
	},
	data() {
		return {
            attachments: [],
            downloadDialogVisible: false,
            loading: false,
            title: '入职确认证明'
        };
    },
    created() { },
	mounted() { },
	methods: {
        
        show(id, attachmentType) {
            this.downloadDialogVisible = true;
            // this.form.invoiceId = id;
            // this.title = attachmentType == 0 ? '入职确认证明': '财务附件';
            if(attachmentType == 0) {
                this.title = '入职确认证明';
            } else if(attachmentType == 0) {
                this.title = '财务附件';
            } else if(attachmentType == 2) {
                this.title = '付款通知函确认证明';
            } else if(attachmentType == 3) {
                this.title = '离职确认证明';
            }
            this.getInvoicingAttachmentsList(id, attachmentType);
        },
        handleDownload(item) {
            if(item.attachmentDownloadUrl) {
                const url = item.attachmentDownloadUrl.replace(/^https?:/, location.protocol);
                location.href = url;
            }
        },
        // 获取附件列表
        getInvoicingAttachmentsList(invoiceId, attachmentType) {
            const params = {
                    invoiceId: invoiceId,
                    attachmentType: attachmentType,
                };
            this.loading = true;
            floatingListService
                .invoicingAttachmentsList(params)
                .then(res => {
                    this.attachments = res || [];
                }).finally(() => {
                    this.loading = false;
                });
        },
        handleDownloadDialogClose() {
            this.downloadDialogVisible = false;
        },
    }
}
</script>
<style lang="scss" scope>
.ell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.download-dialog {
    .el-dialog__body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }
    .download-row-wrap {
        max-height: 280px;
        min-height: 100px;
        overflow-y: auto;
        .download-row {
            height: 56px;
            box-sizing: border-box;
            border-bottom: 1px solid #f6f6f6;
            line-height: 55px;
            display: flex;
            box-sizing: border-box;
            padding-left: 20px;
            .row-l {
                flex: 1;
                max-width: 350px;
            }
            .row-r {
                width: 20px;
                .icon-download {
                    width: 16px;
                    height: 16px;
                    color: #666;
                    cursor: pointer;
                }
            }
        }

        .empty-data{
            text-align: center;
            // padding-bottom: 100px;
            // padding-top: 50px;
            .empty-img{
                display: inline-block;
                background: url("~@src/assets/images/home/icon_no_data.svg") no-repeat;
                background-size: contain;
                width: 160px;
                height: 160px;
            }
            >p{
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }

    }
}
</style>