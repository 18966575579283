export default {
    unApplyTitle: [
        { 
            prop: 'onboardDate',
            label: "入职时间", 
            minWidth: "120", 
            width: "auto", 
            showOverflowTooltip: true
        },
        { 
            prop: 'candidateName',
            label: "候选人", 
            minWidth: "120", 
            width: "auto", 
            showOverflowTooltip: true
        },
        { 
            prop: 'recommendOwnerName',
            label: "推荐者", 
            minWidth: "120", 
            width: "auto", 
            showOverflowTooltip: true
        },
        { 
            prop: 'customerName',
            label: "推荐客户", 
            minWidth: "120", 
            width: "auto", 
            showOverflowTooltip: true
        },
        { 
            prop: 'jobName',
            label: "推荐职位", 
            minWidth: "120", 
            width: "auto", 
            showOverflowTooltip: true
        },
        { 
            prop: 'serviceCharge',
            label: "服务费", 
            minWidth: "120", 
            width: "auto", 
            showOverflowTooltip: true
        },
        { 
            prop: 'currencyType',
            label: "币种", 
            minWidth: "100", 
            width: "auto", 
            showOverflowTooltip: true
        },
        // { 
        //     label: "操作", 
        //     minWidth: "130", 
        //     width: "auto", 
        //     showOverflowTooltip: true
        // },
    ],
    applyTitle: [
        { 
            prop: 'invoiceEntity',
            label: "合同主体", 
            minWidth: "auto", 
            width: "118", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'companyName',
            label: "客户名称", 
            minWidth: "auto", 
            width: "185", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'invoiceTypeName',
            label: "开票种类", 
            minWidth: "auto", 
            width: "106", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'invoiceReason',
            label: "开票原因", 
            minWidth: "auto", 
            width: "126", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'invoiceContent',
            label: "开票内容", 
            minWidth: "auto", 
            width: "126", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'coverCharge',
            label: "服务费", 
            minWidth: "auto", 
            width: "100", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'currencyType',
            label: "币种", 
            minWidth: "auto", 
            width: "100", 
            showOverflowTooltip: true
        },
        { 
            prop: 'created',
            label: "申请时间", 
            minWidth: "auto", 
            width: "106", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'pmLastOperationTime',
            label: "更新时间", 
            minWidth: "auto", 
            width: "206", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'responsibleUserName',
            label: "收款责任人", 
            minWidth: "auto", 
            width: "126", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'leaderName',
            label: "收款责任人Leader", 
            minWidth: "auto", 
            width: "166", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'candidateName',
            label: "候选人", 
            minWidth: "auto", 
            width: "186", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'applicationStatusName',
            label: "当前状态", 
            minWidth: "auto", 
            width: "146", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'ticketType',
            label: "付款方式", 
            minWidth: "auto", 
            width: "158", 
            showOverflowTooltip: true,
            labelClassName: "",
        },
        { 
            prop: 'invoiceAmount',
            label: "价税合计", 
            minWidth: "auto", 
            width: "166", 
            showOverflowTooltip: true,
            labelClassName: "bg-orange-dark"
        },
        { 
            prop: 'financeInvoiceDate',
            label: "开票日期", 
            minWidth: "auto", 
            width: "206", 
            showOverflowTooltip: true,
            labelClassName: "bg-orange-dark"
        },
        { 
            prop: 'invoiceNo',
            label: "发票号码", 
            minWidth: "auto", 
            width: "150", 
            showOverflowTooltip: true,
            labelClassName: "bg-orange-dark"
        },
        { 
            prop: 'expressNo',
            label: "快递单号", 
            minWidth: "auto", 
            width: "150", 
            showOverflowTooltip: true,
            labelClassName: "bg-orange-dark"
        },
        { 
            prop: 'financeInvoiceRemarks',
            label: "财务备注", 
            minWidth: "auto", 
            width: "136", 
            showOverflowTooltip: true,
            labelClassName: "bg-orange-dark"
        },
        { 
            prop: 'financeAttmatchs',
            label: "财务附件", 
            minWidth: "auto", 
            width: "136", 
            showOverflowTooltip: true,
            labelClassName: "bg-orange-dark"
        },
        { 
            prop: 'refundDate',
            label: "收款日期", 
            minWidth: "auto", 
            width: "206", 
            showOverflowTooltip: true,
            labelClassName: "bg-orange-dark"
        },
        { 
            prop: 'refundAmount',
            label: "回款金额", 
            minWidth: "auto", 
            width: "100", 
            showOverflowTooltip: true,
            labelClassName: "bg-orange-dark"
        },
    ],
    offerList: [
        { 
            prop: 'offerDate',
            label: "Offer时间", 
            minWidth: "180", 
            width: "auto", 
            showOverflowTooltip: true
        },
        { 
            prop: 'candidateName',
            label: "候选人姓名", 
            minWidth: "120", 
            width: "auto", 
            showOverflowTooltip: true
        },
        // { 
        //     prop: 'city',
        //     label: "城市", 
        //     minWidth: "120", 
        //     width: "auto", 
        //     showOverflowTooltip: true
        // },
        // { 
        //     prop: 'school',
        //     label: "学校", 
        //     minWidth: "180", 
        //     width: "auto", 
        //     showOverflowTooltip: true
        // },
        // { 
        //     prop: 'degree',
        //     label: "学历", 
        //     minWidth: "100", 
        //     width: "auto", 
        //     showOverflowTooltip: true
        // },
        // { 
        //     prop: 'yearOfExperience',
        //     label: "工作年限", 
        //     minWidth: "100", 
        //     width: "auto", 
        //     showOverflowTooltip: true
        // },
        // { 
        //     prop: 'title',
        //     label: "当前职位", 
        //     minWidth: "160", 
        //     width: "auto", 
        //     showOverflowTooltip: true
        // },
        // { 
        //     prop: 'company',
        //     label: "当前公司", 
        //     minWidth: "190", 
        //     width: "auto", 
        //     showOverflowTooltip: true
        // },
        {
            prop: 'job',
            label: "推荐至职位", 
            minWidth: "180", 
            width: "auto", 
            showOverflowTooltip: true
        },
        { 
            prop: 'customer',
            label: "推荐至客户", 
            minWidth: "130", 
            width: "auto", 
            showOverflowTooltip: true
        },
        { 
            prop: 'recommenderRealName',
            label: "推荐者", 
            minWidth: "120", 
            width: "auto", 
            showOverflowTooltip: true
        },
        { 
            prop: 'recommendPM',
            label: "推荐至PM", 
            minWidth: "120", 
            width: "auto", 
            showOverflowTooltip: true
        },
        { 
            prop: 'status',
            label: "最新状态", 
            minWidth: "160", 
            width: "auto", 
            showOverflowTooltip: true
        },
    ],
};