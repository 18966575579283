var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "work-table-container" }, [
    _c(
      "div",
      { staticClass: "work-table" },
      [
        [3, 4].includes(_vm.filterIndex)
          ? _c(
              "div",
              { staticClass: "filter-wrap" },
              [
                _c("floatingListBubble", {
                  ref: "bubble",
                  attrs: {
                    id: "bubble",
                    bubbleType: _vm.filterIndex,
                    bubbleCount: _vm.statusBubbleCount,
                    selectedUserIds: _vm.selectedUserIds,
                    perDate: _vm.performanceDate,
                    "export-loading": _vm.exportLoading,
                  },
                  on: {
                    "bubble-click": _vm.bubbleClick,
                    "bubble-clear": _vm.bubbleClear,
                    "timeLimit-change": _vm.timeLimitChange,
                    "member-change": _vm.memberChange,
                    "export-detail": _vm.exportDetail,
                    "select-change": _vm.selectChange,
                    "candidata-search": _vm.candidataSearch,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "select-container" }, [
          _c("div", { staticClass: "float-left" }, [
            _c("span", [
              _vm._v("共\r\n                    "),
              _c("span", { staticClass: "color-orange" }, [
                _vm._v(_vm._s(_vm.page.total)),
              ]),
              _vm._v("\r\n                    条记录\r\n                "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "select-tag-list" },
            _vm._l(_vm.applyLabelShowList, function (item, tag) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.applyLabelShowList.length > 0,
                      expression: "applyLabelShowList.length > 0",
                    },
                  ],
                  key: tag,
                  staticClass: "select-tag",
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        placement: "top-start",
                        effect: "light",
                        "popper-class": "tooltip-label",
                      },
                    },
                    [
                      item == "InvoiceAmount" ||
                      item == "invoiceNumber" ||
                      item == "courierNumber" ||
                      item == "InvoiceActualTaxAmount" ||
                      item == "CandidateName" ||
                      item == "CompanyName" ||
                      item == "candidateName" ||
                      item == "recommendOwnerName"
                        ? _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v(_vm._s(_vm.labelList[item][0].label))]
                          )
                        : _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("labelsTip")(_vm.labelList[item], item)
                                )
                              ),
                            ]
                          ),
                      _c(
                        "div",
                        { staticClass: "text-ellipsis" },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.searchParamTitle[item]
                                  ? `${_vm.searchParamTitle[item]}：`
                                  : ""
                              ) + " "
                            ),
                          ]),
                          _vm._l(_vm.labelList[item], function (param, index) {
                            return _c(
                              "span",
                              { key: index, staticClass: "content" },
                              [
                                _vm._v(
                                  "\r\n                                " +
                                    _vm._s(param.label) +
                                    "\r\n                                "
                                ),
                                _vm.labelList[item].length > 0 &&
                                index !== _vm.labelList[item].length - 1
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item == "performanceDate" ||
                                              item == "financeInvoiceDate"
                                              ? " ~ "
                                              : " , "
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c("span", {
                    staticClass: "btn-close",
                    on: {
                      click: function ($event) {
                        return _vm.handleCleanLabel(item)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.applyTableLoading,
                expression: "applyTableLoading",
              },
            ],
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              border: "",
              height: "700",
              "tooltip-effect": "light",
              data: _vm.applyTable,
              "expand-row-keys": _vm.expendsArray,
              "row-key": _vm.getRowKeys,
            },
            on: {
              "selection-change": _vm.handleApplySelectionChange,
              "expand-change": _vm.handleApplyExpandChange,
              "current-change": _vm.toggleRowExpansion,
            },
          },
          [
            [2].includes(_vm.filterIndex)
              ? [
                  _vm._l(_vm.applyTableTitle, function (item, index) {
                    return [
                      item.prop != "currencyType" ||
                      (item.prop == "currencyType" && _vm.isTsdnuser)
                        ? _c(
                            "el-table-column",
                            {
                              key: "applyTableTitle_0_" + index,
                              attrs: {
                                label: item.label,
                                width: item.width,
                                "min-width": item.minWidth,
                                "show-overflow-tooltip":
                                  item.showOverflowTooltip,
                                "label-class-name": item.labelClassName,
                                align:
                                  item.label == "服务费" ? "right" : "left",
                                "header-align": "left",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _vm._v(
                                          "\r\n                            " +
                                            _vm._s(
                                              _vm.columnFormatter(
                                                item.prop,
                                                row[item.prop]
                                              )
                                            ) +
                                            "\r\n                        "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "template",
                                { slot: "header" },
                                [
                                  item.prop == "candidateName"
                                    ? [
                                        _c("select-popover", {
                                          attrs: {
                                            enableCount: false,
                                            enableSearch: true,
                                            maxSelect: 5,
                                            selectItem: [],
                                            label: "candidateName",
                                            title: "候选人",
                                            type: "SearchOnly",
                                          },
                                          on: {
                                            selectChange:
                                              _vm.unApplySelectDataChange,
                                          },
                                        }),
                                      ]
                                    : item.prop == "recommendOwnerName"
                                    ? [
                                        _c("select-popover", {
                                          attrs: {
                                            enableCount: false,
                                            enableSearch: true,
                                            maxSelect: 5,
                                            selectItem: [],
                                            label: "recommendOwnerName",
                                            title: "推荐者",
                                            type: "SearchOnly",
                                          },
                                          on: {
                                            selectChange:
                                              _vm.unApplySelectDataChange,
                                          },
                                        }),
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          { staticClass: "table-slot" },
                                          [
                                            _vm._v(
                                              "\r\n                                    " +
                                                _vm._s(item.label) +
                                                "\r\n                                "
                                            ),
                                          ]
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  }),
                ]
              : [4].includes(_vm.filterIndex)
              ? [
                  _vm._l(_vm.applyTableTitle, function (item, index) {
                    return [
                      item.prop != "currencyType" ||
                      (item.prop == "currencyType" && _vm.isTsdnuser)
                        ? _c(
                            "el-table-column",
                            {
                              key: "applyTableTitle_1_" + index,
                              attrs: {
                                label: item.label,
                                width: item.width,
                                "min-width": item.minWidth,
                                "show-overflow-tooltip":
                                  item.showOverflowTooltip,
                                "label-class-name": item.labelClassName,
                                align:
                                  item.label == "服务费" ? "right" : "left",
                                "header-align": "left",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        item.prop == "yearOfExperience"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "table-slot table-slot-content",
                                                },
                                                [
                                                  _vm._v(
                                                    "\r\n                                    " +
                                                      _vm._s(
                                                        scope.row
                                                          .yearOfExperience +
                                                          "年"
                                                      ) +
                                                      "\r\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : item.prop == "recommendDate"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "table-slot table-slot-content",
                                                },
                                                [
                                                  _vm._v(
                                                    "\r\n                                    " +
                                                      _vm._s(
                                                        _vm._f("formatDate")(
                                                          scope.row
                                                            .recommendDate
                                                        )
                                                      ) +
                                                      "\r\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : item.prop == "recommendPM"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "center recommend-avatar",
                                                },
                                                [
                                                  _c("avatar", {
                                                    attrs: {
                                                      userId:
                                                        scope.row.jobOwnerId,
                                                      enableLink: false,
                                                      src: _vm._f(
                                                        "avatarUrlFilter"
                                                      )(
                                                        scope.row
                                                          .jobOwnerAvatarUrl
                                                      ),
                                                      size: "sm",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          : item.prop == "recommender"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "center recommend-avatar",
                                                },
                                                [
                                                  _c("avatar", {
                                                    attrs: {
                                                      userId: scope.row.ownerId,
                                                      enableLink: false,
                                                      src: _vm._f(
                                                        "avatarUrlFilter"
                                                      )(
                                                        scope.row
                                                          .recommenderAvatarUrl
                                                      ),
                                                      size: "sm",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          : item.prop == "status"
                                          ? [
                                              _vm.filterIndex == 1 &&
                                              scope.row.status == 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\r\n                                    推荐待反馈\r\n                                "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      "\r\n                                    " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatStatus"
                                                          )(
                                                            scope.row.status,
                                                            scope.row
                                                          )
                                                        ) +
                                                        "\r\n                                "
                                                    ),
                                                  ]),
                                            ]
                                          : [
                                              _vm._v(
                                                "\r\n                                " +
                                                  _vm._s(scope.row[item.prop]) +
                                                  "\r\n                            "
                                              ),
                                            ],
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("div", { staticClass: "table-slot" }, [
                                  _vm._v(
                                    "\r\n                                " +
                                      _vm._s(item.label) +
                                      "\r\n                            "
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm.isCFUser
                    ? _c("el-table-column", {
                        attrs: {
                          "header-align": "left",
                          "min-width": "120",
                          prop: "businessClassificationName",
                          "show-overflow-tooltip": true,
                          label: "业务分类",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\r\n                        " +
                                      _vm._s(
                                        scope.row.businessClassificationName ||
                                          "-"
                                      ) +
                                      "\r\n                    "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3270764519
                        ),
                      })
                    : _vm._e(),
                ]
              : [
                  _vm.isFinancial
                    ? _c("el-table-column", {
                        attrs: {
                          width: "42",
                          align: "center",
                          type: "selection",
                          "header-align": "center",
                        },
                      })
                    : _vm._e(),
                  _vm._l(
                    _vm.applyTableTitle.slice(0, 11),
                    function (item, index) {
                      return [
                        item.prop != "currencyType" ||
                        (item.prop == "currencyType" && _vm.isTsdnuser)
                          ? _c("el-table-column", {
                              key: "applyTableTitle" + index,
                              attrs: {
                                label: item.label,
                                width: item.width,
                                "min-width": item.minWidth,
                                "show-overflow-tooltip":
                                  item.showOverflowTooltip,
                                "label-class-name": item.labelClassName,
                                resizable: false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        !_vm.isFinancial ||
                                        item.label == "开票原因" ||
                                        item.label == "收款责任人" ||
                                        item.label == "申请时间" ||
                                        item.label == "币种"
                                          ? [
                                              _c(
                                                "div",
                                                { staticClass: "table-slot" },
                                                [
                                                  _vm._v(
                                                    "\r\n                                    " +
                                                      _vm._s(item.label) +
                                                      "\r\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : [
                                              item.label == "更新时间"
                                                ? _c(
                                                    "el-popover",
                                                    {
                                                      ref: "popoverTime",
                                                      refInFor: true,
                                                      attrs: {
                                                        placement: "bottom",
                                                        width: "100%",
                                                        trigger: "click",
                                                        "popper-class": `el-time-popover ${_vm.popoverClass}`,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "date-range",
                                                        },
                                                        [
                                                          _c("el-date-picker", {
                                                            staticClass:
                                                              "performance-date-input",
                                                            attrs: {
                                                              size: "small",
                                                              align: "left",
                                                              type: "daterange",
                                                              "value-format":
                                                                "yyyy-MM-dd",
                                                              editable: false,
                                                              clearable: false,
                                                              "range-separator":
                                                                "-",
                                                              "start-placeholder":
                                                                "开始日期",
                                                              "end-placeholder":
                                                                "结束日期",
                                                              "picker-options":
                                                                _vm.performanceDateOption,
                                                              "popper-class":
                                                                "performance-date-range",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.changePerformanceDate(
                                                                  "created"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.performanceDate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.performanceDate =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "performanceDate",
                                                            },
                                                          }),
                                                          _c("div", {
                                                            staticClass:
                                                              "date-input-icon",
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "table-slot",
                                                          attrs: {
                                                            slot: "reference",
                                                          },
                                                          slot: "reference",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\r\n                                        " +
                                                              _vm._s(
                                                                item.label
                                                              ) +
                                                              "\r\n                                        "
                                                          ),
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-arrow-down el-icon--right",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : item.label == "客户名称"
                                                ? _c("selectPopover", {
                                                    attrs: {
                                                      enableSearch: true,
                                                      enableCount: true,
                                                      selectItem: [],
                                                      type: "SearchOnly",
                                                      label: "CompanyName",
                                                      title: item.label,
                                                      arrowShow:
                                                        _vm.applyTableTitle
                                                          .length > 0,
                                                    },
                                                    on: {
                                                      selectChange:
                                                        _vm.selectDataChange,
                                                    },
                                                  })
                                                : item.label ==
                                                  "收款责任人Leader"
                                                ? _c("select-popover", {
                                                    attrs: {
                                                      enableSearch: true,
                                                      label:
                                                        "responsibleLeaders",
                                                      selectItem:
                                                        _vm.headerFilter
                                                          .responsibleLeaders,
                                                      title: item.label,
                                                      maxSelect: 5,
                                                    },
                                                    on: {
                                                      selectChange:
                                                        _vm.selectDataChange,
                                                    },
                                                  })
                                                : item.label == "服务费"
                                                ? _c("selectPopover", {
                                                    attrs: {
                                                      enableSearch: true,
                                                      enableCount: true,
                                                      selectItem: [],
                                                      type: "SearchOnly",
                                                      label: "InvoiceAmount",
                                                      title: item.label,
                                                      arrowShow:
                                                        _vm.applyTableTitle
                                                          .length > 0,
                                                    },
                                                    on: {
                                                      selectChange:
                                                        _vm.selectDataChange,
                                                    },
                                                  })
                                                : _c(
                                                    "el-dropdown",
                                                    {
                                                      staticClass:
                                                        "table-header-dropdown",
                                                      attrs: {
                                                        trigger: "click",
                                                        placement: "bottom",
                                                      },
                                                      on: {
                                                        command:
                                                          _vm.handleCommand,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "table-slot el-dropdown-link",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\r\n                                        " +
                                                              _vm._s(
                                                                item.label
                                                              ) +
                                                              "\r\n                                        "
                                                          ),
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-arrow-down el-icon--right",
                                                          }),
                                                        ]
                                                      ),
                                                      item.label == "合同主体"
                                                        ? _c(
                                                            "el-dropdown-menu",
                                                            {
                                                              staticClass:
                                                                "table-header-dropdown-menu",
                                                              attrs: {
                                                                slot: "dropdown",
                                                                "visible-arrow": false,
                                                              },
                                                              slot: "dropdown",
                                                            },
                                                            [
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.invoiceEntity ==
                                                                    ""
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "",
                                                                        "invoiceEntity"
                                                                      ),
                                                                  },
                                                                },
                                                                [_vm._v("全部")]
                                                              ),
                                                              _vm._l(
                                                                _vm.invoiceEntityArray,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-dropdown-item",
                                                                    {
                                                                      key:
                                                                        "entiry_" +
                                                                        index,
                                                                      class:
                                                                        _vm.invoiceEntity ==
                                                                        item
                                                                          ? "el-dropdown-item-selected"
                                                                          : "",
                                                                      attrs: {
                                                                        command:
                                                                          _vm.composeValue(
                                                                            item,
                                                                            "invoiceEntity"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\r\n                                            " +
                                                                          _vm._s(
                                                                            item
                                                                          ) +
                                                                          "\r\n                                        "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : item.label ==
                                                          "发票类型"
                                                        ? _c(
                                                            "el-dropdown-menu",
                                                            {
                                                              staticClass:
                                                                "table-header-dropdown-menu",
                                                              attrs: {
                                                                slot: "dropdown",
                                                                "visible-arrow": false,
                                                              },
                                                              slot: "dropdown",
                                                            },
                                                            [
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.invoiceType ==
                                                                    ""
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "",
                                                                        "invoiceType"
                                                                      ),
                                                                  },
                                                                },
                                                                [_vm._v("全部")]
                                                              ),
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.invoiceType ==
                                                                    "0"
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "0",
                                                                        "invoiceType"
                                                                      ),
                                                                  },
                                                                },
                                                                [_vm._v("普票")]
                                                              ),
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.invoiceType ==
                                                                    "1"
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "1",
                                                                        "invoiceType"
                                                                      ),
                                                                  },
                                                                },
                                                                [_vm._v("专票")]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : item.label ==
                                                          "开票内容"
                                                        ? _c(
                                                            "el-dropdown-menu",
                                                            {
                                                              staticClass:
                                                                "table-header-dropdown-menu",
                                                              attrs: {
                                                                slot: "dropdown",
                                                                "visible-arrow": false,
                                                              },
                                                              slot: "dropdown",
                                                            },
                                                            [
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.InvoiceContent ==
                                                                    ""
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "",
                                                                        "InvoiceContent"
                                                                      ),
                                                                  },
                                                                },
                                                                [_vm._v("全部")]
                                                              ),
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.InvoiceContent ==
                                                                    "服务费"
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "服务费",
                                                                        "InvoiceContent"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "服务费"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.InvoiceContent ==
                                                                    "招聘服务费"
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "招聘服务费",
                                                                        "InvoiceContent"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "招聘服务费"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.InvoiceContent ==
                                                                    "咨询服务费"
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "咨询服务费",
                                                                        "InvoiceContent"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "咨询服务费"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.InvoiceContent ==
                                                                    "其他人力资源服务费"
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "其他人力资源服务费",
                                                                        "InvoiceContent"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "其他人力资源服务费"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.InvoiceContent ==
                                                                    "信息服务费4"
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "信息服务费",
                                                                        "InvoiceContent"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "信息服务费"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.InvoiceContent ==
                                                                    "软件服务费"
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "软件服务费",
                                                                        "InvoiceContent"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "软件服务费"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  class:
                                                                    _vm.InvoiceContent ==
                                                                    "技术服务费"
                                                                      ? "el-dropdown-item-selected"
                                                                      : "",
                                                                  attrs: {
                                                                    command:
                                                                      _vm.composeValue(
                                                                        "技术服务费",
                                                                        "InvoiceContent"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "技术服务费"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                            ],
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        item.prop == "created"
                                          ? [
                                              _vm._v(
                                                "\r\n                                " +
                                                  _vm._s(
                                                    _vm.columnFormatter(
                                                      item.prop,
                                                      row.minApplicationTime
                                                    )
                                                  ) +
                                                  "\r\n                            "
                                              ),
                                            ]
                                          : item.prop == "coverCharge"
                                          ? [
                                              _vm._v(
                                                "\r\n                                " +
                                                  _vm._s(
                                                    _vm.columnFormatter(
                                                      "coverCharge",
                                                      row.invoiceAmount || 0
                                                    )
                                                  ) +
                                                  "\r\n                            "
                                              ),
                                            ]
                                          : item.prop == "invoiceReason"
                                          ? [
                                              row[item.prop] == 0
                                                ? _c("span", [
                                                    _vm._v("候选人离职"),
                                                  ])
                                                : _vm._e(),
                                              row[item.prop] == 1
                                                ? _c("span", [
                                                    _vm._v("客户信息有误"),
                                                  ])
                                                : _vm._e(),
                                              row[item.prop] == 2
                                                ? _c("span", [_vm._v("其他")])
                                                : _vm._e(),
                                            ]
                                          : item.prop == "pmLastOperationTime"
                                          ? [
                                              _vm._v(
                                                "\r\n                                " +
                                                  _vm._s(
                                                    _vm.columnFormatter(
                                                      "pmLastOperationTime",
                                                      row.minPMLastOperationTime
                                                    )
                                                  ) +
                                                  " ~ " +
                                                  _vm._s(
                                                    _vm.columnFormatter(
                                                      "pmLastOperationTime",
                                                      row.maxPMLastOperationTime
                                                    )
                                                  ) +
                                                  "\r\n                            "
                                              ),
                                            ]
                                          : item.prop == "invoiceTypeName"
                                          ? [
                                              _vm._v(
                                                "\r\n                                " +
                                                  _vm._s(row.invoiceTypeNames) +
                                                  "\r\n                            "
                                              ),
                                            ]
                                          : [
                                              _vm._v(
                                                "\r\n                                " +
                                                  _vm._s(row[item.prop]) +
                                                  "\r\n                            "
                                              ),
                                            ],
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ]
                    }
                  ),
                  _c("el-table-column", {
                    key: Math.random(),
                    attrs: {
                      align: "center",
                      "class-name": "hide-expand-cell",
                      width: "1",
                      type: "expand",
                      resizable: false,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row, $index }) {
                          return [
                            _vm.expandType == "candidate" &&
                            _vm.currentIndex == $index
                              ? [
                                  _c("apply-table-row-expand", {
                                    attrs: { "row-data": row },
                                    on: {
                                      "download-click": _vm.handleDownloadClick,
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _vm.expandType == "invoicingType" &&
                            _vm.currentIndex == $index
                              ? [
                                  _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.applySubTableLoading,
                                          expression: "applySubTableLoading",
                                        },
                                      ],
                                      ref: "multipleTableSub",
                                      staticClass: "sub-table",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        border: "",
                                        "tooltip-effect": "light",
                                        "show-header": false,
                                        data: _vm.applySubTable,
                                        "row-class-name": _vm.tableRowClassName,
                                        "expand-row-keys": _vm.subExpendsArray,
                                        "row-key": _vm.getRowKeys,
                                      },
                                    },
                                    [
                                      _vm.isFinancial
                                        ? _c("el-table-column", {
                                            attrs: {
                                              width: "42",
                                              align: "center",
                                              type: "selection",
                                              selectable: () => {
                                                return false
                                              },
                                              "header-align": "center",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.applyTableTitle.slice(0, 11),
                                        function (item, index) {
                                          return [
                                            item.prop != "currencyType" ||
                                            (item.prop == "currencyType" &&
                                              _vm.isTsdnuser)
                                              ? _c(
                                                  "el-table-column",
                                                  {
                                                    key:
                                                      "applyTableTitle_sub1_" +
                                                      index,
                                                    attrs: {
                                                      label: item.label,
                                                      width:
                                                        index == 0
                                                          ? +item.width - 1
                                                          : item.width,
                                                      "min-width":
                                                        item.minWidth,
                                                      "show-overflow-tooltip":
                                                        item.showOverflowTooltip,
                                                      "label-class-name":
                                                        item.labelClassName,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            row,
                                                          }) {
                                                            return [
                                                              item.prop ==
                                                              "created"
                                                                ? [
                                                                    _vm._v(
                                                                      "\r\n                                                " +
                                                                        _vm._s(
                                                                          _vm.columnFormatter(
                                                                            item.prop,
                                                                            row[
                                                                              item
                                                                                .prop
                                                                            ]
                                                                          )
                                                                        ) +
                                                                        "\r\n                                            "
                                                                    ),
                                                                  ]
                                                                : item.prop ==
                                                                  "coverCharge"
                                                                ? [
                                                                    _vm._v(
                                                                      "\r\n                                                " +
                                                                        _vm._s(
                                                                          _vm.columnFormatter(
                                                                            "coverCharge",
                                                                            row.invoiceAmount ||
                                                                              0
                                                                          )
                                                                        ) +
                                                                        "\r\n                                            "
                                                                    ),
                                                                  ]
                                                                : item.prop ==
                                                                  "invoiceReason"
                                                                ? [
                                                                    row[
                                                                      item.prop
                                                                    ] == 0
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "候选人离职"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    row[
                                                                      item.prop
                                                                    ] == 1
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "客户信息有误"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    row[
                                                                      item.prop
                                                                    ] == 2
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "其他"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                : [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        row[
                                                                          item
                                                                            .prop
                                                                        ]
                                                                      )
                                                                    ),
                                                                  ],
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "header" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "table-slot",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\r\n                                                " +
                                                                _vm._s(
                                                                  item.label
                                                                ) +
                                                                "\r\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          "class-name": "hide-expand-cell",
                                          width: "1",
                                          type: "expand",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _c("apply-table-row-expand", {
                                                    attrs: { "row-data": row },
                                                    on: {
                                                      "download-click":
                                                        _vm.handleDownloadClick,
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            align: "left",
                                            "show-overflow-tooltip": true,
                                            label:
                                              _vm.applyTableTitle[11].label,
                                            width:
                                              _vm.applyTableTitle[11].width,
                                            "min-width":
                                              _vm.applyTableTitle[11].minWidth,
                                            "class-name": "reset-expand-cell",
                                            prop: _vm.applyTableTitle[11].prop,
                                            "row-key": _vm.getRowKeys,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ row, $index }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "ex-cell",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.candidateClick(
                                                              row,
                                                              $index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "doc-icon",
                                                          attrs: {
                                                            src: require("@src/assets/images/icon/doc-icon.png"),
                                                            alt: "doc",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          _vm._s(
                                                            row[
                                                              _vm
                                                                .applyTableTitle[11]
                                                                .prop
                                                            ]
                                                          ) +
                                                            "\r\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "div",
                                              { staticClass: "table-slot" },
                                              [
                                                _vm._v(
                                                  "\r\n                                            " +
                                                    _vm._s(
                                                      _vm.applyTableTitle[11]
                                                        .label
                                                    ) +
                                                    "\r\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            prop: "recommendId",
                                            label: "推荐ID",
                                            width: "350",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "div",
                                              { staticClass: "table-slot" },
                                              [
                                                _vm._v(
                                                  "\r\n                                            推荐ID\r\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            align: "left",
                                            "show-overflow-tooltip": true,
                                            label:
                                              _vm.applyTableTitle[12].label,
                                            width:
                                              _vm.applyTableTitle[12].width,
                                            "min-width":
                                              _vm.applyTableTitle[12].minWidth,
                                            "class-name": "reset-expand-cell",
                                            prop: _vm.applyTableTitle[12].prop,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ row }) {
                                                  return [
                                                    _vm._v(
                                                      "\r\n                                        " +
                                                        _vm._s(
                                                          _vm.getInvoiceStatus(
                                                            row
                                                          )
                                                        ) +
                                                        "\r\n                                        "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "ex-cell",
                                                      },
                                                      [
                                                        row.hasEdit
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "tip-icon color-c",
                                                              },
                                                              [_vm._v("改")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "div",
                                              { staticClass: "table-slot" },
                                              [
                                                _vm._v(
                                                  "\r\n                                            " +
                                                    _vm._s(
                                                      _vm.applyTableTitle[12]
                                                        .label
                                                    ) +
                                                    "\r\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "left",
                                          "class-name": "reset-expand-cell",
                                          "show-overflow-tooltip": true,
                                          label: _vm.applyTableTitle[13].label,
                                          width: _vm.applyTableTitle[13].width,
                                          "min-width":
                                            _vm.applyTableTitle[13].minWidth,
                                          prop: _vm.applyTableTitle[13].prop,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _c("span", {
                                                    staticClass: "ex-cell",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.generateInvoiceTypeCell(
                                                          row,
                                                          "sub"
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "left",
                                          "show-overflow-tooltip": true,
                                          label: _vm.applyTableTitle[14].label,
                                          width: _vm.applyTableTitle[14].width,
                                          "min-width":
                                            _vm.applyTableTitle[14].minWidth,
                                          "class-name": "name-cell",
                                          prop: _vm.applyTableTitle[14].prop,
                                          "label-class-name": "bg-orange-dark",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return row.invoiceActualAmount
                                                  ? [
                                                      _vm._v(
                                                        "\r\n                                        " +
                                                          _vm._s(
                                                            _vm.columnFormatter(
                                                              "invoiceAmount",
                                                              (row.invoiceActualAmount ||
                                                                0) +
                                                                (row.invoiceTaxAmount ||
                                                                  0)
                                                            )
                                                          ) +
                                                          "\r\n                                        "
                                                      ),
                                                      _c(
                                                        "el-dropdown",
                                                        {
                                                          ref: "resumeOperation",
                                                          attrs: {
                                                            placement:
                                                              "bottom-start",
                                                            trigger: "hover",
                                                            "hide-on-click": false,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "operation-icon",
                                                            },
                                                            [
                                                              _c("font-icon", {
                                                                staticClass:
                                                                  "table-body-icon operation-icon-default",
                                                                attrs: {
                                                                  href: "#icon-ic_act_grey",
                                                                },
                                                              }),
                                                              _c("font-icon", {
                                                                staticClass:
                                                                  "table-body-icon operation-icon-hover",
                                                                attrs: {
                                                                  href: "#icon-ic_act_green",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "el-dropdown-menu",
                                                            {
                                                              staticClass:
                                                                "operation-menu",
                                                              attrs: {
                                                                slot: "dropdown",
                                                              },
                                                              slot: "dropdown",
                                                            },
                                                            [
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  attrs: {
                                                                    command:
                                                                      "addToTrackingList",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\r\n                                                    服务费 " +
                                                                      _vm._s(
                                                                        _vm.columnFormatter(
                                                                          "invoiceAmount",
                                                                          row.invoiceActualAmount ||
                                                                            0
                                                                        )
                                                                      ) +
                                                                      "\r\n                                                "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-dropdown-item",
                                                                {
                                                                  attrs: {
                                                                    command:
                                                                      "addToTrackingList",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\r\n                                                    税金 " +
                                                                      _vm._s(
                                                                        _vm.columnFormatter(
                                                                          "invoiceAmount",
                                                                          row.invoiceTaxAmount ||
                                                                            0
                                                                        )
                                                                      ) +
                                                                      "\r\n                                                "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : undefined
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._l(
                                        _vm.applyTableTitle.slice(15),
                                        function (item, index) {
                                          return _c(
                                            "el-table-column",
                                            {
                                              key: "applyTableTitle_2_" + index,
                                              attrs: {
                                                label: item.label,
                                                width: item.width,
                                                "min-width": item.minWidth,
                                                "show-overflow-tooltip":
                                                  item.showOverflowTooltip,
                                                "label-class-name":
                                                  item.labelClassName,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ row }) {
                                                      return [
                                                        [
                                                          "financeInvoiceDate",
                                                          "refundDate",
                                                          "refundAmount",
                                                        ].includes(item.prop)
                                                          ? [
                                                              _vm._v(
                                                                " \r\n                                            " +
                                                                  _vm._s(
                                                                    _vm.columnFormatter(
                                                                      item.prop,
                                                                      row[
                                                                        item
                                                                          .prop
                                                                      ]
                                                                    )
                                                                  ) +
                                                                  "\r\n                                        "
                                                              ),
                                                            ]
                                                          : item.prop ==
                                                            "financeAttmatchs"
                                                          ? [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell-btn-wrap",
                                                                },
                                                                [
                                                                  row.hasFinanceAttachments
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "apply-ticket",
                                                                          attrs:
                                                                            {
                                                                              href: "javascript:;",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.seeAttachments(
                                                                                  row.id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "查看附件"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "-"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              ),
                                                            ]
                                                          : [
                                                              _vm._v(
                                                                "\r\n                                            " +
                                                                  _vm._s(
                                                                    row[
                                                                      item.prop
                                                                    ]
                                                                  ) +
                                                                  "\r\n                                        "
                                                              ),
                                                            ],
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "table-slot",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\r\n                                            " +
                                                          _vm._s(item.label) +
                                                          "\r\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            label: "操作",
                                            width: _vm.isShrink ? "79" : "319",
                                            fixed: "right",
                                            "class-name": "btn-menu",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ row }) {
                                                  return [
                                                    _vm.isShrink &&
                                                    ((([0, 3, 9].includes(
                                                      row.applicationStatus
                                                    ) ||
                                                      row.isCanCreateNegative ||
                                                      row.isCanCreateBalance) &&
                                                      _vm.isFinancial) ||
                                                      (([0, 3, 19].includes(
                                                        row.applicationStatus
                                                      ) ||
                                                        row.isCanCreateNegative ||
                                                        row.isCanCreateBalance) &&
                                                        _vm.isPM))
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sm-drop",
                                                          },
                                                          [
                                                            _c(
                                                              "el-dropdown",
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "el-dropdown-link name-cell",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "operation-icon",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "font-icon",
                                                                          {
                                                                            staticClass:
                                                                              "table-body-icon-big operation-icon-default tip-margin",
                                                                            attrs:
                                                                              {
                                                                                href: "#icon-ic_act_grey",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "font-icon",
                                                                          {
                                                                            staticClass:
                                                                              "table-body-icon-big operation-icon-hover tip-margin",
                                                                            attrs:
                                                                              {
                                                                                href: "#icon-ic_act_green",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-dropdown-menu",
                                                                  {
                                                                    staticClass:
                                                                      "shrink-dropdown-menu",
                                                                    attrs: {
                                                                      slot: "dropdown",
                                                                    },
                                                                    slot: "dropdown",
                                                                  },
                                                                  [
                                                                    _vm.isFinancial
                                                                      ? [
                                                                          row.applicationStatus ==
                                                                          0
                                                                            ? _c(
                                                                                "el-dropdown-item",
                                                                                {
                                                                                  nativeOn:
                                                                                    {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.applicationConfirm(
                                                                                            row,
                                                                                            "isSub"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "确认开票信息"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          row.applicationStatus ==
                                                                          1
                                                                            ? _c(
                                                                                "el-dropdown-item",
                                                                                {
                                                                                  nativeOn:
                                                                                    {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.fillInvoiceInfo(
                                                                                            row,
                                                                                            "isSub"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "填写开票信息"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          row.applicationStatus ==
                                                                          9
                                                                            ? _c(
                                                                                "el-dropdown-item",
                                                                                {
                                                                                  nativeOn:
                                                                                    {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.fillBackInfo(
                                                                                            row,
                                                                                            "isSub"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "填写回款信息"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          row.applicationStatus ==
                                                                          1
                                                                            ? _c(
                                                                                "el-dropdown-item",
                                                                                {
                                                                                  nativeOn:
                                                                                    {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.rejectApply(
                                                                                            row,
                                                                                            "isSub"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "驳回"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          [
                                                                            0,
                                                                            3,
                                                                            9,
                                                                          ].includes(
                                                                            row.applicationStatus
                                                                          )
                                                                            ? _c(
                                                                                "el-dropdown-item",
                                                                                {
                                                                                  nativeOn:
                                                                                    {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.editApply(
                                                                                            row,
                                                                                            "isSub"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "修改"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      : _vm._e(),
                                                                    _vm.isPM
                                                                      ? [
                                                                          [
                                                                            0,
                                                                          ].includes(
                                                                            row.applicationStatus
                                                                          )
                                                                            ? _c(
                                                                                "el-dropdown-item",
                                                                                {
                                                                                  nativeOn:
                                                                                    {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.cancelApply(
                                                                                            row,
                                                                                            "isSub"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "取消申请"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          [
                                                                            0,
                                                                          ].includes(
                                                                            row.applicationStatus
                                                                          )
                                                                            ? _c(
                                                                                "el-dropdown-item",
                                                                                {
                                                                                  nativeOn:
                                                                                    {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.editApply(
                                                                                            row,
                                                                                            "isSub"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "修改"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          [
                                                                            3,
                                                                            19,
                                                                          ].includes(
                                                                            row.applicationStatus
                                                                          )
                                                                            ? _c(
                                                                                "el-dropdown-item",
                                                                                {
                                                                                  nativeOn:
                                                                                    {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.reApply(
                                                                                            row,
                                                                                            "isSub"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "重新申请"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          [
                                                                            29,
                                                                          ].includes(
                                                                            row.applicationStatus
                                                                          ) &&
                                                                          [
                                                                            0,
                                                                            1,
                                                                          ].includes(
                                                                            row.paymentMethodType
                                                                          ) &&
                                                                          row.isCanReCreateApplication
                                                                            ? _c(
                                                                                "el-dropdown-item",
                                                                                {
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.reCreateApply(
                                                                                          row,
                                                                                          "isSub"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "重新开票"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          [
                                                                            9,
                                                                            13,
                                                                          ].includes(
                                                                            row.applicationStatus
                                                                          ) &&
                                                                          row.applicationType ==
                                                                            0 &&
                                                                          row.isCanCreateNegative
                                                                            ? _c(
                                                                                "el-dropdown-item",
                                                                                {
                                                                                  nativeOn:
                                                                                    {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.handleApplyNegative(
                                                                                            row,
                                                                                            "isSub"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "申请负数发票"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          [
                                                                            1,
                                                                            9,
                                                                            13,
                                                                          ].includes(
                                                                            row.applicationStatus
                                                                          ) &&
                                                                          row.paymentMethodType ==
                                                                            1 &&
                                                                          row.applicationType ==
                                                                            0 &&
                                                                          row.isCanCreateBalance
                                                                            ? _c(
                                                                                "el-dropdown-item",
                                                                                {
                                                                                  nativeOn:
                                                                                    {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.handleApplyBalance(
                                                                                            row,
                                                                                            "isSub"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "申请尾款发票"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "cell-btn-wrap",
                                                          },
                                                          [
                                                            [
                                                              _vm.isFinancial
                                                                ? [
                                                                    row.applicationStatus ==
                                                                    0
                                                                      ? _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "apply-ticket",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:;",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.applicationConfirm(
                                                                                    row,
                                                                                    "isSub"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "确认开票信息"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    row.applicationStatus ==
                                                                    1
                                                                      ? _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "apply-ticket",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:;",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.fillInvoiceInfo(
                                                                                    row,
                                                                                    "isSub"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "填写开票信息"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    row.applicationStatus ==
                                                                    9
                                                                      ? _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "apply-ticket",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:;",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.fillBackInfo(
                                                                                    row,
                                                                                    "isSub"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "填写回款信息"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    row.applicationStatus ==
                                                                    1
                                                                      ? _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "apply-ticket orange",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:;",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.rejectApply(
                                                                                    row,
                                                                                    "isSub"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "驳回"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    [
                                                                      0, 3,
                                                                    ].includes(
                                                                      row.applicationStatus
                                                                    )
                                                                      ? _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "apply-ticket",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:;",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.editApply(
                                                                                    row,
                                                                                    "isSub"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "修改"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                : _vm._e(),
                                                              _vm.isPM
                                                                ? [
                                                                    [
                                                                      0,
                                                                    ].includes(
                                                                      row.applicationStatus
                                                                    )
                                                                      ? _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "apply-ticket",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:;",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.cancelApply(
                                                                                    row,
                                                                                    "isSub"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "取消申请"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    [
                                                                      0,
                                                                    ].includes(
                                                                      row.applicationStatus
                                                                    )
                                                                      ? _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "apply-ticket",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:;",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.editApply(
                                                                                    row,
                                                                                    "isSub"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "修改"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    [
                                                                      3, 19,
                                                                    ].includes(
                                                                      row.applicationStatus
                                                                    )
                                                                      ? _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "apply-ticket",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:;",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.reApply(
                                                                                    row,
                                                                                    "isSub"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "重新申请"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    [
                                                                      29,
                                                                    ].includes(
                                                                      row.applicationStatus
                                                                    ) &&
                                                                    [
                                                                      0, 1,
                                                                    ].includes(
                                                                      row.paymentMethodType
                                                                    ) &&
                                                                    row.isCanReCreateApplication
                                                                      ? _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "apply-ticket",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:;",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.reCreateApply(
                                                                                    row,
                                                                                    "isSub"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "重新开票"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    [
                                                                      9, 13,
                                                                    ].includes(
                                                                      row.applicationStatus
                                                                    ) &&
                                                                    row.applicationType ==
                                                                      0 &&
                                                                    row.isCanCreateNegative
                                                                      ? _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "apply-ticket orange",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:;",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.handleApplyNegative(
                                                                                    row,
                                                                                    "isSub"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "申请负数发票"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    [
                                                                      1, 9, 13,
                                                                    ].includes(
                                                                      row.applicationStatus
                                                                    ) &&
                                                                    row.paymentMethodType ==
                                                                      1 &&
                                                                    row.applicationType ==
                                                                      0 &&
                                                                    row.isCanCreateBalance
                                                                      ? _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "apply-ticket",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:;",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.handleApplyBalance(
                                                                                    row,
                                                                                    "isSub"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "申请尾款发票"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                          ],
                                                          2
                                                        ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "div",
                                              { staticClass: "table-slot" },
                                              [_vm._v("操作")]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "left",
                      "show-overflow-tooltip": true,
                      label: _vm.applyTableTitle[11].label,
                      width: _vm.applyTableTitle[11].width,
                      "min-width": _vm.applyTableTitle[11].minWidth,
                      "class-name": "reset-expand-cell",
                      resizable: false,
                      prop: _vm.applyTableTitle[11].prop,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            !_vm.isFinancial
                              ? [
                                  _c("div", { staticClass: "table-slot" }, [
                                    _vm._v(
                                      "\r\n                                " +
                                        _vm._s(_vm.applyTableTitle[11].label) +
                                        "\r\n                            "
                                    ),
                                  ]),
                                ]
                              : _c("selectPopover", {
                                  attrs: {
                                    enableSearch: true,
                                    enableCount: true,
                                    selectItem: [],
                                    type: "SearchOnly",
                                    label: "CandidateName",
                                    title: _vm.applyTableTitle[11].label,
                                    arrowShow: _vm.applyTableTitle.length > 0,
                                  },
                                  on: { selectChange: _vm.selectDataChange },
                                }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function ({ row, $index }) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "ex-cell",
                                on: {
                                  click: function ($event) {
                                    return _vm.candidateClick(row, $index)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "doc-icon",
                                  attrs: {
                                    src: require("@src/assets/images/icon/doc-icon.png"),
                                    alt: "doc",
                                  },
                                }),
                                _vm._v(
                                  _vm._s(row[_vm.applyTableTitle[11].prop]) +
                                    "\r\n                        "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "recommendId",
                        label: "推荐ID",
                        resizable: false,
                        width: "350",
                      },
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", { staticClass: "table-slot" }, [
                          _vm._v(
                            "\r\n                            推荐ID\r\n                        "
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      align: "left",
                      "show-overflow-tooltip": true,
                      label: _vm.applyTableTitle[12].label,
                      width: _vm.applyTableTitle[12].width,
                      "min-width": _vm.applyTableTitle[12].minWidth,
                      resizable: false,
                      "class-name": "reset-expand-cell",
                      prop: _vm.applyTableTitle[12].prop,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            !_vm.isFinancial
                              ? [
                                  _c("div", { staticClass: "table-slot" }, [
                                    _vm._v(
                                      "\r\n                                " +
                                        _vm._s(_vm.applyTableTitle[12].label) +
                                        "\r\n                            "
                                    ),
                                  ]),
                                ]
                              : [
                                  _c(
                                    "el-dropdown",
                                    {
                                      staticClass: "table-header-dropdown",
                                      attrs: {
                                        trigger: "click",
                                        placement: "bottom",
                                      },
                                      on: { command: _vm.handleCommand },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "table-slot el-dropdown-link",
                                        },
                                        [
                                          _vm._v(
                                            "\r\n                                    " +
                                              _vm._s(
                                                _vm.applyTableTitle[12].label
                                              ) +
                                              "\r\n                                    "
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          staticClass:
                                            "table-header-dropdown-menu",
                                          attrs: {
                                            slot: "dropdown",
                                            "visible-arrow": false,
                                          },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              class:
                                                _vm.ApplicationStatus == ""
                                                  ? "el-dropdown-item-selected"
                                                  : "",
                                              attrs: {
                                                command: _vm.composeValue(
                                                  "",
                                                  "ApplicationStatus"
                                                ),
                                              },
                                            },
                                            [_vm._v("全部")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              class:
                                                _vm.ApplicationStatus == "0"
                                                  ? "el-dropdown-item-selected"
                                                  : "",
                                              attrs: {
                                                command: _vm.composeValue(
                                                  "0",
                                                  "ApplicationStatus"
                                                ),
                                              },
                                            },
                                            [_vm._v("待确认")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              class:
                                                _vm.ApplicationStatus == "1"
                                                  ? "el-dropdown-item-selected"
                                                  : "",
                                              attrs: {
                                                command: _vm.composeValue(
                                                  "1",
                                                  "ApplicationStatus"
                                                ),
                                              },
                                            },
                                            [_vm._v("已确认待开票")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              class:
                                                _vm.ApplicationStatus == "9"
                                                  ? "el-dropdown-item-selected"
                                                  : "",
                                              attrs: {
                                                command: _vm.composeValue(
                                                  "9",
                                                  "ApplicationStatus"
                                                ),
                                              },
                                            },
                                            [_vm._v("待回款")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              class:
                                                _vm.ApplicationStatus == "13"
                                                  ? "el-dropdown-item-selected"
                                                  : "",
                                              attrs: {
                                                command: _vm.composeValue(
                                                  "13",
                                                  "ApplicationStatus"
                                                ),
                                              },
                                            },
                                            [_vm._v("已回款")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              class:
                                                _vm.ApplicationStatus == "3"
                                                  ? "el-dropdown-item-selected"
                                                  : "",
                                              attrs: {
                                                command: _vm.composeValue(
                                                  "3",
                                                  "ApplicationStatus"
                                                ),
                                              },
                                            },
                                            [_vm._v("已驳回")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              class:
                                                _vm.ApplicationStatus == "19"
                                                  ? "el-dropdown-item-selected"
                                                  : "",
                                              attrs: {
                                                command: _vm.composeValue(
                                                  "19",
                                                  "ApplicationStatus"
                                                ),
                                              },
                                            },
                                            [_vm._v("已取消")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              class:
                                                _vm.ApplicationStatus == "29"
                                                  ? "el-dropdown-item-selected"
                                                  : "",
                                              attrs: {
                                                command: _vm.composeValue(
                                                  "29",
                                                  "ApplicationStatus"
                                                ),
                                              },
                                            },
                                            [_vm._v("已作废")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { staticClass: "ex-cell" }, [
                              row.hasEdit
                                ? _c(
                                    "span",
                                    { staticClass: "tip-icon color-c" },
                                    [_vm._v("改")]
                                  )
                                : _vm._e(),
                              row.refApplicationStatus.includes(0)
                                ? _c(
                                    "span",
                                    { staticClass: "tip-icon color-a" },
                                    [_vm._v("待确认")]
                                  )
                                : _vm._e(),
                              row.refApplicationStatus.includes(1)
                                ? _c(
                                    "span",
                                    { staticClass: "tip-icon color-b" },
                                    [_vm._v("待开票")]
                                  )
                                : _vm._e(),
                              row.refApplicationStatus.includes(9)
                                ? _c(
                                    "span",
                                    { staticClass: "tip-icon color-c" },
                                    [_vm._v("待回款")]
                                  )
                                : _vm._e(),
                              row.refApplicationStatus.includes(3)
                                ? _c(
                                    "span",
                                    { staticClass: "tip-icon color-d" },
                                    [_vm._v("已驳回")]
                                  )
                                : _vm._e(),
                              row.refApplicationStatus.includes(13)
                                ? _c("span", { staticClass: "tip-icon" }, [
                                    _vm._v("已回款"),
                                  ])
                                : _vm._e(),
                              row.refApplicationStatus.includes(19)
                                ? _c(
                                    "span",
                                    { staticClass: "tip-icon color-d" },
                                    [_vm._v("已取消")]
                                  )
                                : _vm._e(),
                              row.refApplicationStatus.every((it) => it == 29)
                                ? _c(
                                    "span",
                                    { staticClass: "tip-icon color-void" },
                                    [_vm._v("已作废")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "left",
                      "show-overflow-tooltip": true,
                      label: _vm.applyTableTitle[13].label,
                      width: _vm.applyTableTitle[13].width,
                      "min-width": _vm.applyTableTitle[13].minWidth,
                      "class-name": "reset-expand-cell",
                      resizable: false,
                      prop: _vm.applyTableTitle[13].prop,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _vm.isFinancial
                              ? _c(
                                  "el-dropdown",
                                  {
                                    staticClass: "table-header-dropdown",
                                    attrs: {
                                      trigger: "click",
                                      placement: "bottom",
                                    },
                                    on: { command: _vm.handleCommand },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "table-slot el-dropdown-link table-header-tip-cell",
                                      },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              offset: 5,
                                              width: "156",
                                              trigger: "hover",
                                              "visible-arrow": false,
                                              placement: "bottom-start",
                                              "popper-class":
                                                "talentpool-name-popover talentpool-popover",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "popover-card" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card-tip" },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "row-l" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "tip-icon",
                                                          },
                                                          [_vm._v("尾")]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\r\n                                            尾款\r\n                                        "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "card-tip" },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "row-l" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "tip-icon color-a",
                                                          },
                                                          [_vm._v("负")]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\r\n                                            负数发票\r\n                                        "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "card-tip" },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "row-l" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "tip-icon color-b",
                                                          },
                                                          [_vm._v("取消")]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\r\n                                            取消申请\r\n                                        "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "card-tip" },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "row-l" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "tip-icon color-c",
                                                          },
                                                          [_vm._v("驳回")]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\r\n                                            驳回申请\r\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "icon-question",
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              },
                                              [
                                                _c("font-icon", {
                                                  staticClass:
                                                    "table-header-tip-icon question-hover",
                                                  attrs: {
                                                    href: "#icon-talent_ql",
                                                  },
                                                }),
                                                _c("font-icon", {
                                                  staticClass:
                                                    "table-header-tip-icon question-no-hover",
                                                  attrs: {
                                                    href: "#icon-talent_qd",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          "\r\n                                " +
                                            _vm._s(
                                              _vm.applyTableTitle[13].label
                                            ) +
                                            "\r\n                                "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right",
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        staticClass:
                                          "table-header-dropdown-menu",
                                        attrs: {
                                          slot: "dropdown",
                                          "visible-arrow": false,
                                        },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            class:
                                              _vm.PaymentMethodType == ""
                                                ? "el-dropdown-item-selected"
                                                : "",
                                            attrs: {
                                              command: _vm.composeValue(
                                                "",
                                                "PaymentMethodType"
                                              ),
                                            },
                                          },
                                          [_vm._v("全部")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            class:
                                              _vm.PaymentMethodType == "0"
                                                ? "el-dropdown-item-selected"
                                                : "",
                                            attrs: {
                                              command: _vm.composeValue(
                                                "0",
                                                "PaymentMethodType"
                                              ),
                                            },
                                          },
                                          [_vm._v("全款")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            class:
                                              _vm.PaymentMethodType == "1"
                                                ? "el-dropdown-item-selected"
                                                : "",
                                            attrs: {
                                              command: _vm.composeValue(
                                                "1",
                                                "PaymentMethodType"
                                              ),
                                            },
                                          },
                                          [_vm._v("首款")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            class:
                                              _vm.PaymentMethodType == "2"
                                                ? "el-dropdown-item-selected"
                                                : "",
                                            attrs: {
                                              command: _vm.composeValue(
                                                "2",
                                                "PaymentMethodType"
                                              ),
                                            },
                                          },
                                          [_vm._v("尾款")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            class:
                                              _vm.PaymentMethodType == "-1"
                                                ? "el-dropdown-item-selected"
                                                : "",
                                            attrs: {
                                              command: _vm.composeValue(
                                                "-1",
                                                "PaymentMethodType"
                                              ),
                                            },
                                          },
                                          [_vm._v("负数发票")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", { staticClass: "table-slot" }, [
                                  _c(
                                    "span",
                                    { staticClass: "table-header-tip-cell" },
                                    [
                                      _vm._v(
                                        "\r\n                                " +
                                          _vm._s(
                                            _vm.applyTableTitle[13].label
                                          ) +
                                          "\r\n                                "
                                      ),
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            offset: 5,
                                            width: "156",
                                            trigger: "hover",
                                            "visible-arrow": false,
                                            placement: "bottom-start",
                                            "popper-class":
                                              "talentpool-name-popover talentpool-popover",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "popover-card" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "card-tip" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "row-l" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "tip-icon",
                                                        },
                                                        [_vm._v("尾")]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\r\n                                            尾款\r\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "card-tip" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "row-l" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "tip-icon color-a",
                                                        },
                                                        [_vm._v("负")]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\r\n                                            负数发票\r\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "card-tip" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "row-l" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "tip-icon color-b",
                                                        },
                                                        [_vm._v("取消")]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\r\n                                            取消申请\r\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "card-tip" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "row-l" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "tip-icon color-c",
                                                        },
                                                        [_vm._v("驳回")]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\r\n                                            驳回申请\r\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "icon-question",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _c("font-icon", {
                                                staticClass:
                                                  "table-header-tip-icon question-hover",
                                                attrs: {
                                                  href: "#icon-talent_ql",
                                                },
                                              }),
                                              _c("font-icon", {
                                                staticClass:
                                                  "table-header-tip-icon question-no-hover",
                                                attrs: {
                                                  href: "#icon-talent_qd",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function ({ row, $index }) {
                          return [
                            _c("span", {
                              staticClass: "ex-cell",
                              class: {
                                "cell-expanded":
                                  _vm.expandType == "invoicingType" &&
                                  _vm.currentIndex == $index,
                                hidden: row.refApplicationCount == 1,
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.generateInvoiceTypeCell(row)
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  row.refApplicationCount > 1 &&
                                    _vm.handCellExpand(
                                      row,
                                      "invoicingType",
                                      $index
                                    )
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "left",
                      "show-overflow-tooltip": true,
                      label: _vm.applyTableTitle[14].label,
                      width: _vm.applyTableTitle[14].width,
                      "min-width": _vm.applyTableTitle[14].minWidth,
                      "class-name": "name-cell",
                      prop: _vm.applyTableTitle[14].prop,
                      "label-class-name": "bg-orange-dark",
                      resizable: false,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function (scope) {
                            return [
                              !_vm.isFinancial
                                ? [
                                    _c("div", { staticClass: "table-slot" }, [
                                      _vm._v(
                                        "\r\n                                " +
                                          _vm._s(
                                            _vm.applyTableTitle[14].label
                                          ) +
                                          "\r\n                            "
                                      ),
                                    ]),
                                  ]
                                : _c("selectPopover", {
                                    attrs: {
                                      enableSearch: true,
                                      enableCount: true,
                                      selectItem: [],
                                      type: "SearchOnly",
                                      label: "InvoiceActualTaxAmount",
                                      title: _vm.applyTableTitle[14].label,
                                      arrowShow: _vm.applyTableTitle.length > 0,
                                    },
                                    on: { selectChange: _vm.selectDataChange },
                                  }),
                            ]
                          },
                        },
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return row.invoiceActualAmount
                              ? [
                                  _vm._v(
                                    "\r\n                        " +
                                      _vm._s(
                                        _vm.columnFormatter(
                                          "invoiceAmount",
                                          (row.invoiceActualAmount || 0) +
                                            (row.invoiceTaxAmount || 0)
                                        )
                                      ) +
                                      "\r\n                        "
                                  ),
                                  _c(
                                    "el-dropdown",
                                    {
                                      ref: "resumeOperation",
                                      attrs: {
                                        placement: "bottom-start",
                                        trigger: "hover",
                                        "hide-on-click": false,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "operation-icon" },
                                        [
                                          _c("font-icon", {
                                            staticClass:
                                              "table-body-icon operation-icon-default",
                                            attrs: {
                                              href: "#icon-ic_act_grey",
                                            },
                                          }),
                                          _c("font-icon", {
                                            staticClass:
                                              "table-body-icon operation-icon-hover",
                                            attrs: {
                                              href: "#icon-ic_act_green",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          staticClass: "operation-menu",
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: "addToTrackingList",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\r\n                                    服务费 " +
                                                  _vm._s(
                                                    _vm.columnFormatter(
                                                      "invoiceAmount",
                                                      row.invoiceActualAmount ||
                                                        0
                                                    )
                                                  ) +
                                                  "\r\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: "addToTrackingList",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\r\n                                    税金 " +
                                                  _vm._s(
                                                    _vm.columnFormatter(
                                                      "invoiceAmount",
                                                      row.invoiceTaxAmount || 0
                                                    )
                                                  ) +
                                                  "\r\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._l(_vm.applyTableTitle.slice(15), function (item, index) {
                    return _c(
                      "el-table-column",
                      {
                        key: "applyTableTitle_2_" + index,
                        attrs: {
                          label: item.label,
                          width: item.width,
                          "min-width": item.minWidth,
                          "show-overflow-tooltip": item.showOverflowTooltip,
                          "label-class-name": item.labelClassName,
                          resizable: false,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  ["refundAmount"].includes(item.prop)
                                    ? [
                                        _vm._v(
                                          " \r\n                            " +
                                            _vm._s(
                                              _vm.columnFormatter(
                                                item.prop,
                                                row[item.prop]
                                              )
                                            ) +
                                            "\r\n                        "
                                        ),
                                      ]
                                    : item.prop == "financeInvoiceDate"
                                    ? [
                                        _vm._v(
                                          "\r\n                            " +
                                            _vm._s(
                                              row.minFinanceInvoiceDate
                                                ? `${_vm.columnFormatter(
                                                    "financeInvoiceDate",
                                                    row.minFinanceInvoiceDate
                                                  )} ~ ${_vm.columnFormatter(
                                                    "financeInvoiceDate",
                                                    row.maxFinanceInvoiceDate
                                                  )}`
                                                : ""
                                            ) +
                                            "\r\n                        "
                                        ),
                                      ]
                                    : item.prop == "refundDate"
                                    ? [
                                        _vm._v(
                                          "\r\n                            " +
                                            _vm._s(
                                              row.minRefundDate
                                                ? `${_vm.columnFormatter(
                                                    "refundDate",
                                                    row.minRefundDate
                                                  )} ~ ${_vm.columnFormatter(
                                                    "refundDate",
                                                    row.maxRefundDate
                                                  )}`
                                                : ""
                                            ) +
                                            "\r\n                        "
                                        ),
                                      ]
                                    : item.prop == "financeAttmatchs"
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "cell-btn-wrap" },
                                          [
                                            row.hasFinanceAttachments
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass: "apply-ticket",
                                                    attrs: {
                                                      href: "javascript:;",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.seeAttachments(
                                                          row.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("查看附件")]
                                                )
                                              : _c("span", [_vm._v("-")]),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "\r\n                            " +
                                            _vm._s(row[item.prop]) +
                                            "\r\n                        "
                                        ),
                                      ],
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [
                            !_vm.isFinancial
                              ? [
                                  _c("div", { staticClass: "table-slot" }, [
                                    _vm._v(
                                      "\r\n                                " +
                                        _vm._s(item.label) +
                                        "\r\n                            "
                                    ),
                                  ]),
                                ]
                              : [
                                  item.label == "开票日期"
                                    ? _c(
                                        "el-popover",
                                        {
                                          ref: "financeInvoiceTime",
                                          refInFor: true,
                                          attrs: {
                                            placement: "bottom",
                                            width: "100%",
                                            trigger: "click",
                                            "popper-class": `el-time-popover ${_vm.popoverClass}`,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "date-range" },
                                            [
                                              _c("el-date-picker", {
                                                staticClass:
                                                  "performance-date-input",
                                                attrs: {
                                                  size: "small",
                                                  align: "left",
                                                  type: "daterange",
                                                  "value-format": "yyyy-MM-dd",
                                                  editable: false,
                                                  clearable: false,
                                                  "range-separator": "-",
                                                  "start-placeholder":
                                                    "开始日期",
                                                  "end-placeholder": "结束日期",
                                                  "picker-options":
                                                    _vm.financeInvoicePerformanceDateOption,
                                                  "popper-class":
                                                    "performance-date-range",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.changePerformanceDate(
                                                      "financeInvoiceDate"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.financeInvoiceDate,
                                                  callback: function ($$v) {
                                                    _vm.financeInvoiceDate = $$v
                                                  },
                                                  expression:
                                                    "financeInvoiceDate",
                                                },
                                              }),
                                              _c("div", {
                                                staticClass: "date-input-icon",
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "table-slot",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _vm._v(
                                                "\r\n                                    " +
                                                  _vm._s(item.label) +
                                                  "\r\n                                    "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : item.label == "发票号码" ||
                                      item.label == "快递单号"
                                    ? _c("selectPopover", {
                                        attrs: {
                                          enableSearch: true,
                                          enableCount: true,
                                          selectItem: [],
                                          type: "SearchOnly",
                                          label:
                                            item.label == "发票号码"
                                              ? "invoiceNumber"
                                              : "courierNumber",
                                          title: item.label,
                                          arrowShow:
                                            _vm.applyTableTitle.length > 0,
                                        },
                                        on: {
                                          selectChange: _vm.selectDataChange,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                          ],
                          2
                        ),
                      ],
                      2
                    )
                  }),
                ],
            _c(
              "el-table-column",
              {
                attrs: {
                  label: "操作",
                  width: _vm.isShrink ? "80" : "320",
                  fixed: "right",
                  "class-name": "btn-menu",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row, $index }) {
                      return [
                        _vm.isShrink &&
                        ((([0, 1, 9].includes(row.applicationStatus) ||
                          row.isCanCreateNegative ||
                          row.isCanCreateBalance) &&
                          _vm.isFinancial) ||
                          (([0, 1, 3, 9, 13, 19, 29].includes(
                            row.applicationStatus
                          ) ||
                            row.isCanCreateNegative ||
                            row.isCanCreateBalance) &&
                            _vm.isPM))
                          ? _c(
                              "div",
                              { staticClass: "sm-drop" },
                              [
                                _c(
                                  "el-dropdown",
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "el-dropdown-link name-cell",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "operation-icon" },
                                          [
                                            _c("font-icon", {
                                              staticClass:
                                                "table-body-icon-big operation-icon-default tip-margin",
                                              attrs: {
                                                href: "#icon-ic_act_grey",
                                              },
                                            }),
                                            _c("font-icon", {
                                              staticClass:
                                                "table-body-icon-big operation-icon-hover tip-margin",
                                              attrs: {
                                                href: "#icon-ic_act_green",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        staticClass: "shrink-dropdown-menu",
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _vm.isFinancial
                                          ? [
                                              row.refApplicationCount > 1
                                                ? _c(
                                                    "el-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handCellExpand(
                                                            row,
                                                            "invoicingType",
                                                            $index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("展开明细")]
                                                  )
                                                : _vm._e(),
                                              row.refApplicationCount == 1
                                                ? [
                                                    row.applicationStatus == 0
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.fillInvoiceInfo(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "确认开票信息"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    row.applicationStatus == 1
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.fillInvoiceInfo(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "填写开票信息"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    row.applicationStatus == 9
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.fillBackInfo(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "填写回款信息"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    row.applicationStatus == 1
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.rejectApply(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("驳回")]
                                                        )
                                                      : _vm._e(),
                                                    [0, 3].includes(
                                                      row.applicationStatus
                                                    )
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editApply(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("修改")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                        _vm.isPM
                                          ? [
                                              row.refApplicationCount == 1
                                                ? [
                                                    [0].includes(
                                                      row.applicationStatus
                                                    )
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.cancelApply(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("取消申请")]
                                                        )
                                                      : _vm._e(),
                                                    [0].includes(
                                                      row.applicationStatus
                                                    )
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editApply(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("修改")]
                                                        )
                                                      : _vm._e(),
                                                    [3, 19].includes(
                                                      row.applicationStatus
                                                    )
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.reApply(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("重新申请")]
                                                        )
                                                      : _vm._e(),
                                                    [9, 13].includes(
                                                      row.applicationStatus
                                                    ) &&
                                                    row.applicationType == 0 &&
                                                    row.isCanCreateNegative
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleApplyNegative(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "申请负数发票"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    [1, 9, 13].includes(
                                                      row.applicationStatus
                                                    ) &&
                                                    row.paymentMethodType ==
                                                      1 &&
                                                    row.isCanCreateBalance
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleApplyBalance(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "申请尾款发票"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                              [29].includes(
                                                row.applicationStatus
                                              ) && row.isCanReCreateApplication
                                                ? _c(
                                                    "el-dropdown-item",
                                                    {
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.reApply(
                                                            row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("重新开票")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "cell-btn-wrap" },
                              [
                                _vm.filterIndex == 2
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "apply-ticket",
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleApplyTicket(
                                                $index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "申请开票\r\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _vm.isFinancial
                                        ? [
                                            row.refApplicationCount > 1
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "apply-ticket orange",
                                                    attrs: {
                                                      href: "javascript:;",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handCellExpand(
                                                          row,
                                                          "invoicingType",
                                                          $index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("展开明细")]
                                                )
                                              : _vm._e(),
                                            row.refApplicationCount == 1
                                              ? [
                                                  row.applicationStatus == 0
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "apply-ticket",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.applicationConfirm(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("确认开票信息")]
                                                      )
                                                    : _vm._e(),
                                                  row.applicationStatus == 1
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "apply-ticket",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.fillInvoiceInfo(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("填写开票信息")]
                                                      )
                                                    : _vm._e(),
                                                  row.applicationStatus == 9
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "apply-ticket",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.fillBackInfo(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("填写回款信息")]
                                                      )
                                                    : _vm._e(),
                                                  row.applicationStatus == 1
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "apply-ticket orange",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.rejectApply(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("驳回")]
                                                      )
                                                    : _vm._e(),
                                                  [0, 3].includes(
                                                    row.applicationStatus
                                                  )
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "apply-ticket",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editApply(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("修改")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _vm.isPM
                                        ? [
                                            row.refApplicationCount > 1
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "apply-ticket orange",
                                                    attrs: {
                                                      href: "javascript:;",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handCellExpand(
                                                          row,
                                                          "invoicingType",
                                                          $index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("展开明细")]
                                                )
                                              : _vm._e(),
                                            row.refApplicationCount == 1
                                              ? [
                                                  [0].includes(
                                                    row.applicationStatus
                                                  )
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "apply-ticket",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.cancelApply(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("取消申请")]
                                                      )
                                                    : _vm._e(),
                                                  [0].includes(
                                                    row.applicationStatus
                                                  )
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "apply-ticket",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editApply(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("修改")]
                                                      )
                                                    : _vm._e(),
                                                  [3, 19].includes(
                                                    row.applicationStatus
                                                  )
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "apply-ticket",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.reApply(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("重新申请")]
                                                      )
                                                    : _vm._e(),
                                                  [9, 13].includes(
                                                    row.applicationStatus
                                                  ) &&
                                                  row.applicationType == 0 &&
                                                  row.isCanCreateNegative
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "apply-ticket orange",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleApplyNegative(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("申请负数发票")]
                                                      )
                                                    : _vm._e(),
                                                  [1, 9, 13].includes(
                                                    row.applicationStatus
                                                  ) &&
                                                  row.paymentMethodType == 1 &&
                                                  row.isCanCreateBalance
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "apply-ticket",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleApplyBalance(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("申请尾款发票")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                            [29].includes(
                                              row.applicationStatus
                                            ) && row.isCanReCreateApplication
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass: "apply-ticket",
                                                    attrs: {
                                                      href: "javascript:;",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.reCreateApply(
                                                          row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("重新开票")]
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _vm.isOfferList && _vm.filterIndex == 4
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "option-btn text-gray",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.performanceEdit(
                                                      row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("编辑业绩")]
                                            ),
                                            _vm.isCFUser
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "option-btn text-gray",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.businessClassficationEdit(
                                                          row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("编辑业务分类")]
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticClass: "table-slot" }, [_vm._v("操作")]),
                ]),
              ],
              2
            ),
            _c("template", { slot: "empty" }, [
              _c("div", [
                _c("span", { staticClass: "empty-img" }),
                _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
              ]),
            ]),
          ],
          2
        ),
        _c("table-page-tab", {
          attrs: {
            filterIndex: _vm.filterIndex,
            pageTabs: _vm.pageTabJson,
            unApplyNumber: _vm.unApplyNumber,
          },
        }),
        _c(
          "div",
          { staticClass: "work-table-footer" },
          [
            _vm.filterIndex == 3 && _vm.isFinancial
              ? _c("div", { staticClass: "footer-left" }, [
                  _c("div", { staticClass: "footer-select" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.allSelect,
                          expression: "allSelect",
                        },
                      ],
                      attrs: { hidden: "", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.allSelect)
                          ? _vm._i(_vm.allSelect, null) > -1
                          : _vm.allSelect,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.allSelect,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.allSelect = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.allSelect = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.allSelect = $$c
                          }
                        },
                      },
                    }),
                    _c("span", {
                      staticClass: "all-select-checkbox",
                      on: { click: _vm.handleAllSelect },
                    }),
                    _c("span", [_vm._v("全选")]),
                    _c("span", { staticClass: "select-count" }, [
                      _vm._v("已选择\r\n                        "),
                      _c("span", { staticClass: "color-orange" }, [
                        _vm._v(_vm._s(_vm.multipleCount)),
                      ]),
                      _vm._v(
                        "\r\n                        条记录\r\n                    "
                      ),
                    ]),
                  ]),
                  _vm.filterIndex == 3 && _vm.multipleCount > 0
                    ? _c(
                        "button",
                        {
                          staticClass: "work-table-btn work-table-btn-blue",
                          attrs: {
                            loading: _vm.exportNoticeLetterLoading,
                            disabled: _vm.exportNoticeLetterLoading,
                          },
                          on: { click: _vm.generateInform },
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.exportNoticeLetterLoading,
                                expression: "exportNoticeLetterLoading",
                              },
                            ],
                            staticClass: "el-icon-loading",
                          }),
                          _vm._v("生成付款通知函\r\n                "),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "el-pagination",
              {
                staticClass: "el-pagination-workTable",
                attrs: {
                  "current-page": _vm.page.current + 1,
                  "page-sizes": [20, 30, 50],
                  "page-size": _vm.page.size,
                  layout: "total, sizes, prev, pager, next, slot",
                  total: _vm.page.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              },
              [
                _c("span", { staticClass: "pagination-text" }, [
                  _c(
                    "span",
                    [
                      _vm._v("前往"),
                      _c("el-input", {
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handlePagerJump.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.pagerJump,
                          callback: function ($$v) {
                            _vm.pagerJump = $$v
                          },
                          expression: "pagerJump",
                        },
                      }),
                      _vm._v("页"),
                    ],
                    1
                  ),
                  _c("span", { on: { click: _vm.handlePagerJump } }, [
                    _vm._v("跳转"),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
        _c("attachment-dialog", { ref: "attachmentDialog" }),
        _c("ticket-info-dialog", { ref: "ticketInfoDialog" }),
        _c("back-charge-dialog", { ref: "backChargeDialog" }),
        _c("offer-dialog", {
          ref: "offerDialog",
          attrs: { from: "offerList" },
        }),
        _c("business-classfication-dialog", {
          ref: "businessClassficationDialog",
          on: { refresh: _vm.getOfferListRecommendations },
        }),
        _c("invoice-detail-dialog", {
          ref: "invoiceDetailDialog",
          on: { "download-click": _vm.handleDownloadClick },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }