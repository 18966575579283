<template>
    <div class="row-expand-container" v-loading="loading">
        <div class="expand-main">
            <div class="col-cell">
                <div class="cell-row">入职时间：<span v-text="formatDate(detail.entryDate)">2021-01-04</span></div>
                <!-- <div class="cell-row">入职确认证明中的入职时间：<span v-text="detail.applicationType==0 ? formatDate(detail.actualEntryDate): formatDate(detail.resignationDate)">2021-01-04</span></div>
                <div class="cell-row">过保(离职)日期：<span v-text="formatDate(detail.overWarrantyDate)">2021-01-04</span></div> -->
                <div class="cell-row">入职确认证明中的入职时间：<span v-text="formatDate(detail.actualEntryDate)">2021-01-04</span></div>
                <div class="cell-row">过保(离职)日期：<span v-text="detail.applicationType==0 ? formatDate(detail.overWarrantyDate) : formatDate(detail.resignationDate)">2021-01-04</span></div>
                <div class="cell-row">合同约定回款日期：<span v-text="formatDate(detail.contractAgreedRefundDate)">2021-01-04</span></div>
            </div>
            <div class="col-cell">
                <div class="cell-row"
                    v-for="(item, index) in offerSplits"
                    :key="'offerItem_' + index"
                    >
                    <div class="col-l">
                        <span class="bold ellipsis" v-text="item.name" :title="item.name">BD</span>
                    </div>
                    <div class="col-m ellipsis" :title="item.userName">
                        姓名：<span v-text="item.userName">jerry张攀</span>
                    </div>
                    <div class="col-r ellipsis" :title="'业绩金额：' + currencyFilter(computedPercent*item.charge)">
                        <!-- 这里要乘以比例 -->
                        业绩金额：<span v-text="currencyFilter(computedPercent*item.charge)">43,200.00</span>
                    </div>
                </div>
            </div>
            <div class="col-cell">
                <div class="cell-row">
                    开票比例：
                    <span v-text="computedPercent*100 + '%'">100%</span>
                </div>
                <div class="cell-row" v-if="detail.paymentMethodType != 2">
                    提供入职证明：
                    <template v-if="hasAttach">
                        <a href="javascript:;" @click="() => $emit('download-click', rowData.id)">入职确认证明</a>
                    </template>
                    <template v-else>
                        无
                    </template>
                </div>
                <div class="cell-row ellipsis" :title="detail.ownerRemarks">
                    PM备注：<span v-text="detail.ownerRemarks ? detail.ownerRemarks: '无'">ID69123781974194714017499</span>
                </div>
                
                <!-- 负数发票才显示 -->
                <div class="cell-row" v-if="detail.applicationType == 1">
                    负数发票抵扣方式：
                    <span v-text="detail.deductionTypeName">抵扣其它单</span>
                </div>
                <div class="cell-row" v-if="detail.applicationType == 1 && detail.deductionType == 1">
                    负数发票关联订单：
                    <span>候选人 {{detail.deductionCandidateName}}——顾问 {{detail.deductionOwnerName}}</span>
                </div>
            </div>
        </div>

        <el-popover
            placement="bottom"
            trigger="hover"
            popper-class="operation-search"
            >
            <span class="use-operation-btn" slot="reference" v-if="isFinancial">
                查看操作日志
            </span>
            <div class="list-dialog" >
                <div class="list-dialog-body">
                    <div class="body-scroll">
                        <div class="record-item" 
                            v-for="(item, index) in operationLogs" 
                            :key="'operationLog_' + index"
                            >
                            <div class="pic">
                                <img :src="item.operatorAvatarUrl" alt="">
                            </div>
                            <div class="content-list">
                                <div class="item-row small">
                                    <span class="gray" v-text="item.created">2021-04-08 12:38:34</span>
                                </div>
                                <div class="item-row ellipsis" :title="item.operatorName + '@' + item.operatorNickName">
                                    <span class="black" v-text="item.operatorName">查明123</span>
                                    <span class="gray">@{{item.operatorNickName}}</span>
                                </div>
                                <div class="item-row">
                                    <span class="black" v-text="generateLogText(item)">申请开全款发票</span>
                                </div>
                            </div>
                        </div>
                        <div class="empty-data" v-if="operationLogs.length == 0">
                            <span class="empty-img "></span>
                            <p>暂无数据</p>
                        </div>
                    </div>

                </div>
            </div>
        </el-popover>
    </div>
</template>

<script>
import moment from 'moment';
import { currency as currencyFilter } from "#/js/filters/number.js";
import floatingListService from "#/js/service/floatingListService.js";

export default {
    name: 'apply-table-row-expand',
    props: {
        rowData: {
            type: Object,
            default: () => {}
        }
    },
	components: {
	},
	data() {
		return {
            detail: {},
            offerSplits: [],
            loading: false,
            hasAttach: false, // 是否有附件
            operationLogs: [], // 操作日志
        };
    },
    computed: {
        computedPercent() {
            if(this.detail.paymentMethodType == '0') {
                return this.detail.applicationType == 1 ? -1: 1;
            } else {
                return this.detail.applicationType == 1 ? -this.detail.paymentProportion : +this.detail.paymentProportion;
            }
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isFinancial () {
            return this.userInfo.privilegeCodeList && this.userInfo.privilegeCodeList.includes('Financial');
        },
    },
    created() {
        // this.getItemDetail();
        // this.getItemOfferSplits();
        this.loadData();
    },
	mounted() {
    },
	methods: {
        loadData() {
            this.loading = true;
            Promise.all([this.getItemDetail(), this.getItemOfferSplits(), this.getAttach(), this.getRecordsList()]).then((result) => {
                console.log(result)
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                this.loading = false;
            });
            
        },
        currencyFilter(cellValue) {
            return currencyFilter(cellValue, "", 2);
        },
        getItemDetail() {
            return new Promise(resolve => {
                floatingListService
                .getInvoiceItemDetail(this.rowData.id)
                .then(res => {
                    this.detail = res || {};
                    resolve(true);
                });
            });
        },
        getItemOfferSplits() {
            return new Promise(resolve => {
                floatingListService
                    .getInvoiceItemOfferSplits(this.rowData.id)
                    .then(res => {
                        this.offerSplits = res || {};
                        this.offerSplits.sort((a, b) => a.name.localeCompare(b.name, 'zh-CN'));
                        resolve(true);
                    });
                });
        },
        getAttach() {
            return new Promise(resolve => {
                //  [Display(Name = "开票申请")]
                // Application = 0,
                // [Display(Name = "财务开票")]
                // FinanceInvoice = 1
                const params = {
                    invoiceId: this.rowData.id,
                    attachmentType: 0,
                };
                floatingListService
                .invoicingAttachmentsList(params)
                .then(res => {
                    this.hasAttach = (res || []).length > 0;
                    resolve(true);
                })
            });
        },
        getRecordsList() {
            return new Promise(resolve => {
                floatingListService
                .getInvoiceOperationLogs(this.rowData.id)
                .then(res => {
                    this.operationLogs = res;
                    resolve(true);
                })
            });
        },
        generateLogText(logItem) {
            const actionsMap = ['创建', '重新申请', '编辑', '填写', '填写', '驳回', '取消', '移交', '确认', '作废'];
            const paymentMethodMap = ['全款', '首款', '尾款'];
            const applicationTypeMap = ['', '负数'];
            const ticketsType = ['开票申请', '开票申请', '开票申请', '开票信息', '回款信息', '开票申请', '开票申请', '开票申请', '开票申请', '开票申请'];
            // 提交了全款开票申请
            // 当前状态为开票中
            return `${actionsMap[+logItem.operationType]}了${paymentMethodMap[+logItem.paymentMethodType]}${applicationTypeMap[+logItem.applicationType]}${ticketsType[+logItem.operationType]}`;
            // return `${logItem.operationTypeName}${logItem.paymentMethodTypeName}${logItem.applicationTypeName}`;
        },
        formatDate(val) {
            if(val) return moment(val).format('YYYY-MM-DD');
        },
        
    }
}
</script>
<style lang="scss" scope>
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.row-expand-container{
    background: #EBEEF5;
    box-sizing: border-box;
    padding: 29px 0;
    .expand-main{
        margin: 0 auto;
        margin-left: 350px;
        width: 1156px;
        // background: #fafafa;
        display: flex;
        flex-flow: row nowrap;
        .col-cell{
            width: 374px;
            box-sizing: border-box;
            padding: 0 24px;
            &:nth-child(2){
                box-sizing: border-box;
                border-left: 1px solid #ccc;
                border-right: 1px solid #ccc;
            }
            &:last-child{
                flex: 1;
            }
            .cell-row{
                font-size: 14px;
                line-height: 32px;
                span{
                    color: #3b3b3b;
                }
                .bold{
                    color: #666;
                    font-weight: bold;
                    margin-right: 25px;
                    display: inline-block;
                    max-width: 45px;
                    vertical-align: top;
                }
                a{
                    color: #38bb9c;
                }
                .col-l{
                    display: inline-block;
                    width: 42px;
                    vertical-align: top;
                }
                .col-m{
                    display: inline-block;
                    width: 134px;
                    vertical-align: top;
                }
                .col-r{
                    display: inline-block;
                    width: 136px;
                    vertical-align: top;
                }
            }
        }
    }
    .use-operation-btn{
        position: absolute;
        top: 57px;
        right: 184px;
        color: $primary;
        font-size: 14px;
        cursor: pointer;
    }
}

.operation-search{
    padding: 0;
    .list-dialog{
        width: 320px;
        max-height: 393px;
        background: white;
        // box-shadow: 0px 5px 18px 0px rgba(198, 199, 198, 0.8);
        border-radius: 6px;
        .dialog-title{
            font-size: 14px;
            font-weight: bold;
            color: #666;
            line-height: 50px;
            padding-left: 20px;
            height: 52px;
            box-sizing: border-box;
            border-bottom: 2px solid #ddd;
        }
        .list-dialog-body{
            box-sizing: border-box;
            padding: 20px 5px 20px 20px;
            .body-scroll{
                max-height: 352px;
                overflow-y: auto;
                overflow-x: visible;
                .record-item{
                    height: auto;
                    // background: #F6F6F6;
                    // display: flex;
                    // padding: 10px 10px 2px;
                    // margin-bottom: 10px;
                    box-sizing: border-box;
                    border-left: 1px dashed #ccc;
                    position: relative;
                    margin-left: 4px;
                    padding-bottom: 20px;
                    &::before{
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        background: #38BB9C;
                        border-radius: 50%;
                        position: absolute;
                        left: -4.5px;
                        top: 16px;
                    }
                    .pic{
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        // background: blue;
                        margin-right: 10px;
                        margin-left: 14px;
                        vertical-align: top;
                        >img{
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .content-list{
                        display: inline-block;
                        max-width: 200px;
                        height: auto;
                        vertical-align: top;
                        .item-row{
                            font-size: 14px;
                            line-height: 26px;
                            .black{
                                color: #666;
                            }
                            .gray{
                                color: #B9B9B9;
                            }
                        }
                        .small{
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                    // .item-l{
                    //     flex: 1;
                    //     >span{
                    //         background: #FFFFFF;
                    //         border: 1px solid #AFE4D8;
                    //         border-radius: 4px;
                    //         font-size: 12px;
                    //         color: #38BC9D;
                    //         // line-height: 40px;
                    //         margin-right: 10px;
                    //         padding: 7px 11px;
                    //         margin-bottom: 8px;
                    //         // word-break: normal;
                    //         display: inline-block;
                    //         box-sizing: border-box;
                    //     }
                    // }
                    // .item-r{
                    //     width: 80px;
                    //     text-align: right;
                    //     >a{
                    //         font-size: 14px;
                    //         color: #38BC9D;
                    //         line-height: 26px;
                    //         &:first-child{
                    //             margin-right: 10px;
                    //         }
                    //     }
                    // }
                }

                .empty-data{
                    text-align: center;
                    .empty-img{
                        display: inline-block;
                        background: url("~@src/assets/images/home/icon_no_data.svg") no-repeat;
                        background-size: contain;
                        width: 160px;
                        height: 160px;
                    }
                    >p{
                        font-size: 16px;
                        line-height: 21px;
                        margin-bottom: 0px;
                    }
                }


            }
        }
    }
}
</style>
