var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.downloadDialogVisible,
        width: "400px",
        "lock-scroll": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "custom-class": "download-dialog",
        "before-close": _vm.handleDownloadDialogClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.downloadDialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "download-row-wrap",
        },
        [
          _vm._l(_vm.attachments, function (item, index) {
            return _c(
              "div",
              { key: "file_" + index, staticClass: "download-row" },
              [
                _c(
                  "div",
                  {
                    staticClass: "row-l ell",
                    attrs: { title: item.fileName },
                    domProps: { textContent: _vm._s(item.fileName) },
                  },
                  [_vm._v("文件名称.doc")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "row-r",
                    on: {
                      click: function ($event) {
                        return _vm.handleDownload(item)
                      },
                    },
                  },
                  [
                    _c("font-icon", {
                      staticClass: "icon-download",
                      attrs: { href: "#icon-download" },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm.attachments.length == 0
            ? _c("div", { staticClass: "empty-data" }, [
                _c("span", { staticClass: "empty-img" }),
                _c("p", [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }