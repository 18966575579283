var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "填写开票信息",
        visible: _vm.ticketDialogVisible,
        width: "550px",
        "lock-scroll": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "custom-class": "ticket-dialog",
        "before-close": _vm.handleTicketDialogClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.ticketDialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ticketDialogForm",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "102px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "开票日期", prop: "financeInvoiceDate" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "请选择开票日期",
                },
                model: {
                  value: _vm.form.financeInvoiceDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "financeInvoiceDate", $$v)
                  },
                  expression: "form.financeInvoiceDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发票号", prop: "invoiceNo" } },
            [
              _c(
                "div",
                { staticClass: "input-add-wrap" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "最大输入50位", maxlength: "50" },
                    model: {
                      value: _vm.form.invoiceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "invoiceNo", $$v)
                      },
                      expression: "form.invoiceNo",
                    },
                  }),
                  _c("i", {
                    staticClass: "el-icon-circle-plus-outline",
                    attrs: { title: "添加" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.addDomain(_vm.domain)
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._l(_vm.form.invoiceNos, function (domain, index) {
            return _c(
              "el-form-item",
              {
                key: domain.key,
                attrs: {
                  label: "发票号" + (index + 2),
                  prop: "invoiceNos." + index + ".value",
                  rules: {
                    required: true,
                    message: "发票号不能为空",
                    trigger: "blur",
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "input-add-wrap" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "最大输入50位", maxlength: "50" },
                      model: {
                        value: domain.value,
                        callback: function ($$v) {
                          _vm.$set(domain, "value", $$v)
                        },
                        expression: "domain.value",
                      },
                    }),
                    _c("i", {
                      staticClass: "el-icon-delete red",
                      attrs: { title: "删除" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeDomain(domain)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          _c(
            "el-form-item",
            { attrs: { label: "快递号", prop: "expressNo" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写快递号", maxlength: "20" },
                model: {
                  value: _vm.form.expressNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "expressNo", $$v)
                  },
                  expression: "form.expressNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "价税合计" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.form.chargeTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "chargeTotal", $$v)
                  },
                  expression: "form.chargeTotal",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "金额", prop: "invoiceActualAmount" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写金额", maxlength: "12" },
                model: {
                  value: _vm.form.invoiceActualAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "invoiceActualAmount", $$v)
                  },
                  expression: "form.invoiceActualAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "税额", prop: "invoiceTaxAmount" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写税额", maxlength: "12" },
                model: {
                  value: _vm.form.invoiceTaxAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "invoiceTaxAmount", $$v)
                  },
                  expression: "form.invoiceTaxAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "备注信息",
                  maxlength: "100",
                  "show-word-limit": "",
                  autosize: { minRows: 4, maxRows: 6 },
                },
                model: {
                  value: _vm.form.financeInvoiceRemarks,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "financeInvoiceRemarks", $$v)
                  },
                  expression: "form.financeInvoiceRemarks",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上传附件" } },
            [
              _c(
                "el-upload",
                {
                  ref: "attachmentUpload",
                  staticClass: "apply-upload",
                  attrs: {
                    action: "https://jsonplaceholder.typicode.com/posts/",
                    accept:
                      ".txt, .gif, .jpg, .png, .doc, .docx, .pdf, .zip, .rar",
                    multiple: false,
                    limit: 5,
                    "file-list": _vm.fileList,
                    "on-remove": _vm.handleRemove,
                    "on-exceed": _vm.handleExceed,
                    "http-request": _vm.handleUpload,
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("点击上传")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(
                        "只能上传txt、gif、jpg、png、doc、docx、pdf、zip、rar文件，且不超过2.0M"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.holdLoading },
              on: { click: _vm.handleTicketDialogClose },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.holdLoading },
              on: { click: _vm.handleHold },
            },
            [_vm._v("提 交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }