var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "row-expand-container",
    },
    [
      _c("div", { staticClass: "expand-main" }, [
        _c("div", { staticClass: "col-cell" }, [
          _c("div", { staticClass: "cell-row" }, [
            _vm._v("入职时间："),
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(_vm.formatDate(_vm.detail.entryDate)),
                },
              },
              [_vm._v("2021-01-04")]
            ),
          ]),
          _c("div", { staticClass: "cell-row" }, [
            _vm._v("入职确认证明中的入职时间："),
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.formatDate(_vm.detail.actualEntryDate)
                  ),
                },
              },
              [_vm._v("2021-01-04")]
            ),
          ]),
          _c("div", { staticClass: "cell-row" }, [
            _vm._v("过保(离职)日期："),
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.detail.applicationType == 0
                      ? _vm.formatDate(_vm.detail.overWarrantyDate)
                      : _vm.formatDate(_vm.detail.resignationDate)
                  ),
                },
              },
              [_vm._v("2021-01-04")]
            ),
          ]),
          _c("div", { staticClass: "cell-row" }, [
            _vm._v("合同约定回款日期："),
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.formatDate(_vm.detail.contractAgreedRefundDate)
                  ),
                },
              },
              [_vm._v("2021-01-04")]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-cell" },
          _vm._l(_vm.offerSplits, function (item, index) {
            return _c(
              "div",
              { key: "offerItem_" + index, staticClass: "cell-row" },
              [
                _c("div", { staticClass: "col-l" }, [
                  _c(
                    "span",
                    {
                      staticClass: "bold ellipsis",
                      attrs: { title: item.name },
                      domProps: { textContent: _vm._s(item.name) },
                    },
                    [_vm._v("BD")]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-m ellipsis",
                    attrs: { title: item.userName },
                  },
                  [
                    _vm._v("\n                    姓名："),
                    _c(
                      "span",
                      { domProps: { textContent: _vm._s(item.userName) } },
                      [_vm._v("jerry张攀")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-r ellipsis",
                    attrs: {
                      title:
                        "业绩金额：" +
                        _vm.currencyFilter(_vm.computedPercent * item.charge),
                    },
                  },
                  [
                    _vm._v("\n                    业绩金额："),
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(
                            _vm.currencyFilter(
                              _vm.computedPercent * item.charge
                            )
                          ),
                        },
                      },
                      [_vm._v("43,200.00")]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "col-cell" }, [
          _c("div", { staticClass: "cell-row" }, [
            _vm._v("\n                开票比例：\n                "),
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(_vm.computedPercent * 100 + "%"),
                },
              },
              [_vm._v("100%")]
            ),
          ]),
          _vm.detail.paymentMethodType != 2
            ? _c(
                "div",
                { staticClass: "cell-row" },
                [
                  _vm._v("\n                提供入职证明：\n                "),
                  _vm.hasAttach
                    ? [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: () =>
                                _vm.$emit("download-click", _vm.rowData.id),
                            },
                          },
                          [_vm._v("入职确认证明")]
                        ),
                      ]
                    : [_vm._v("\n                    无\n                ")],
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "cell-row ellipsis",
              attrs: { title: _vm.detail.ownerRemarks },
            },
            [
              _vm._v("\n                PM备注："),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.detail.ownerRemarks ? _vm.detail.ownerRemarks : "无"
                    ),
                  },
                },
                [_vm._v("ID69123781974194714017499")]
              ),
            ]
          ),
          _vm.detail.applicationType == 1
            ? _c("div", { staticClass: "cell-row" }, [
                _vm._v(
                  "\n                负数发票抵扣方式：\n                "
                ),
                _c(
                  "span",
                  {
                    domProps: {
                      textContent: _vm._s(_vm.detail.deductionTypeName),
                    },
                  },
                  [_vm._v("抵扣其它单")]
                ),
              ])
            : _vm._e(),
          _vm.detail.applicationType == 1 && _vm.detail.deductionType == 1
            ? _c("div", { staticClass: "cell-row" }, [
                _vm._v(
                  "\n                负数发票关联订单：\n                "
                ),
                _c("span", [
                  _vm._v(
                    "候选人 " +
                      _vm._s(_vm.detail.deductionCandidateName) +
                      "——顾问 " +
                      _vm._s(_vm.detail.deductionOwnerName)
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom",
            trigger: "hover",
            "popper-class": "operation-search",
          },
        },
        [
          _vm.isFinancial
            ? _c(
                "span",
                {
                  staticClass: "use-operation-btn",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [_vm._v("\n            查看操作日志\n        ")]
              )
            : _vm._e(),
          _c("div", { staticClass: "list-dialog" }, [
            _c("div", { staticClass: "list-dialog-body" }, [
              _c(
                "div",
                { staticClass: "body-scroll" },
                [
                  _vm._l(_vm.operationLogs, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "operationLog_" + index,
                        staticClass: "record-item",
                      },
                      [
                        _c("div", { staticClass: "pic" }, [
                          _c("img", {
                            attrs: { src: item.operatorAvatarUrl, alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "content-list" }, [
                          _c("div", { staticClass: "item-row small" }, [
                            _c(
                              "span",
                              {
                                staticClass: "gray",
                                domProps: { textContent: _vm._s(item.created) },
                              },
                              [_vm._v("2021-04-08 12:38:34")]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "item-row ellipsis",
                              attrs: {
                                title:
                                  item.operatorName +
                                  "@" +
                                  item.operatorNickName,
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "black",
                                  domProps: {
                                    textContent: _vm._s(item.operatorName),
                                  },
                                },
                                [_vm._v("查明123")]
                              ),
                              _c("span", { staticClass: "gray" }, [
                                _vm._v("@" + _vm._s(item.operatorNickName)),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "item-row" }, [
                            _c(
                              "span",
                              {
                                staticClass: "black",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.generateLogText(item)
                                  ),
                                },
                              },
                              [_vm._v("申请开全款发票")]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  _vm.operationLogs.length == 0
                    ? _c("div", { staticClass: "empty-data" }, [
                        _c("span", { staticClass: "empty-img" }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }