<template>
    <el-dialog 
        title="填写开票信息" 
        :visible.sync="ticketDialogVisible"
        width="550px"
        :lock-scroll="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        custom-class="ticket-dialog"
        :before-close="handleTicketDialogClose"
        >
        <el-form ref="ticketDialogForm" :model="form" :rules="rules" label-width="102px">
             <!-- ref="form" :model="form" label-width="80px" -->
            <el-form-item label="开票日期" prop="financeInvoiceDate">
                <el-date-picker
                    style="width: 100%"
                    v-model="form.financeInvoiceDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开票日期"
                    >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="发票号" prop="invoiceNo">
                <div class="input-add-wrap">
                    <el-input v-model="form.invoiceNo" placeholder="最大输入50位" maxlength="50"></el-input>
                    <i class="el-icon-circle-plus-outline" @click.prevent="addDomain(domain)" title="添加"></i> 
                </div>
            </el-form-item>
            <el-form-item
                v-for="(domain, index) in form.invoiceNos"
                :label="'发票号' + (index + 2)"
                :key="domain.key"
                :prop="'invoiceNos.' + index + '.value'"
                :rules="{
                    required: true, message: '发票号不能为空', trigger: 'blur',
                }"
            >
                <div class="input-add-wrap">
                    <el-input v-model="domain.value" placeholder="最大输入50位" maxlength="50"></el-input>
                    <i class="el-icon-delete red" @click.prevent="removeDomain(domain)" title="删除"></i> 
                </div>
            </el-form-item>

            <el-form-item label="快递号" prop="expressNo">
                <el-input v-model="form.expressNo" placeholder="请填写快递号" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="价税合计">
                <el-input v-model="form.chargeTotal" disabled></el-input>
            </el-form-item>
            <el-form-item label="金额" prop="invoiceActualAmount">
                <el-input v-model="form.invoiceActualAmount" placeholder="请填写金额" maxlength="12"></el-input>
            </el-form-item>
            <el-form-item label="税额" prop="invoiceTaxAmount">
                <el-input v-model="form.invoiceTaxAmount" placeholder="请填写税额" maxlength="12"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input 
                    type="textarea"
                    placeholder="备注信息"
                    maxlength="100"
                    show-word-limit
                    :autosize="{ minRows: 4, maxRows: 6}"
                    v-model="form.financeInvoiceRemarks" 
                    >
                </el-input>
            </el-form-item>
            <el-form-item label="上传附件">
                <el-upload
                    class="apply-upload"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    accept=".txt, .gif, .jpg, .png, .doc, .docx, .pdf, .zip, .rar"
                    ref="attachmentUpload"
                    :multiple="false"
                    :limit="5"
                    :file-list="fileList"
                    :on-remove="handleRemove"
                    :on-exceed="handleExceed"
                    :http-request="handleUpload" >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传txt、gif、jpg、png、doc、docx、pdf、zip、rar文件，且不超过2.0M</div>
                </el-upload>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleTicketDialogClose" :loading="holdLoading">取消</el-button>
            <el-button type="primary" @click="handleHold" :loading="holdLoading">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
import floatingListService from "#/js/service/floatingListService.js";
export default {
    name: 'ticket-info-dialog',
	components: {
	},
	data() {
		return {
            invoiceAmount: undefined, // 服务费（顾问申请的开票金额）
            form: {
                invoiceId: '',
                financeInvoiceDate: '',
                invoiceNo: '',
                invoiceNos: [],
                expressNo: '',
                chargeTotal: '',
                invoiceActualAmount: '',
                invoiceTaxAmount: '',
                financeInvoiceRemarks: '',
                // attachments: [],
                FinanceInvoiceAttachments: [],
            },
            fileList: [],
            rules: {
                financeInvoiceDate: [
                    { required: true, message: '请选择开票日期', trigger: 'blur' },
                ],
                invoiceNo: [
                    { required: true, message: '请填写发票号', trigger: 'blur' },
                    // { validator: this.validateinvoiceNo, trigger: ['blur', 'change'] },
                ],
                expressNo: [
                    { required: true, message: '请填写快递号', trigger: 'blur' }
                ],
                // chargeTotal: [
                //     { required: true, message: '请填写价税合计', trigger: 'blur' }
                // ],
                invoiceActualAmount: [
                    { required: true, message: '请填写开票金额', trigger: 'blur' },
                    { validator: this.validateAmount, trigger: ['blur', 'change'] }
                ],
                invoiceTaxAmount: [
                    { required: true, message: '请填写税额', trigger: 'blur' },
                    { validator: this.validateAmount, trigger: ['blur', 'change'] }
                ],
            },
            ticketDialogVisible: false,
            type: '',
            isApplyNegative: false, // 是否为负数发票

            holdLoading: false,
        };
    },
    created() {
    },
	mounted() {},
    watch: {
        'form.invoiceActualAmount': {
            handler(newName,oldName){
                this.getTotal();
            },
            deep:true //为true，表示深度监听，这时候就能监测到a值变化
        },
        'form.invoiceTaxAmount':{
            handler(newName,oldName){
                this.getTotal();
            },
            deep:true
        },
        fileList(val) {
            // this.form.attachments = val.map(item => item.FileId);
            this.form.FinanceInvoiceAttachments = val.map(item => item.FileId);
        },
    },
    
	methods: {
        show(id, type, isApplyNegative, invoiceAmount, invoiceType) {
            console.log(invoiceAmount)
            this.ticketDialogVisible = true;
            this.form.invoiceId = id;
            this.form.invoiceNos = [];
            this.type = type;
            this.isApplyNegative = isApplyNegative;
            this.fileList = [];
            this.invoiceAmount = invoiceAmount;

            if (invoiceType == 2) {
                this.rules.expressNo[0].required = false;
            }
        },
        validateinvoiceNo(rule, value, callback) {
            if(value.length != 8) {
                callback('正确的发票号为8位');
            }
            callback();
        },
        validateAmount(rule, value, callback) {
            let matchSalary = Number(value);
            if(isNaN(matchSalary)) {
                callback('必须为数字格式');
            } else {
                const regPos = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/; // 非负
                const regNeg = /^\-\d+(\.\d+)?$/; // 负数
                if(this.isApplyNegative) {
                    if(matchSalary == 0) {
                        callback();
                    }
                    if(!regNeg.test(matchSalary)){
                        callback('负数发票必须为负数');
                    }
                } else {
                    if(!regPos.test(matchSalary)){
                        callback('正数发票必须为零或者正数');
                    }
                }
                callback();
            }
        },
        handleTicketDialogClose() {
            this.$refs.ticketDialogForm.resetFields();
            this.ticketDialogVisible = false;
            this.type = '';
            this.isApplyNegative = false;
        },
        handleHold() {
            this.$refs['ticketDialogForm'].validate((valid) => {
                if (valid) {
                    this.fillInvoice();
                }
            });
        },
        fillInvoice() {
            // {
            //     invoiceId: '', // 申请单号
            //     invoiceNo: '', // 发票号
            //     expressNo: '', // 快递单号
            //     invoiceActualAmount: '', // 开票金额
            //     invoiceTaxAmount: '', // 税务金额
            //     financeInvoiceRemarks: '', // 
            //     financeInvoiceDate: '', // 开票日期
            // };
            this.holdLoading = true;

            if(this.form.chargeTotal != this.invoiceAmount) {
                shortTips(`价税合计不等于顾问申请的开票金额`);
                this.holdLoading = false;
                return;
            }
            const params = {
                ...this.form,
            };
            if(this.form.invoiceNos.length>0) {
                params.invoiceNo = this.form.invoiceNo + '、' + this.form.invoiceNos.map(it=> it.value).join("、");
            }
            delete params.invoiceNos;

            floatingListService
                .fillInvoice(params)
                .then((res) => {
                    shortTips(`操作成功！`);
                })
                .finally(() => {
                    this.holdLoading = false;
                    if(this.type) {
                        this.$parent.updateSubTable();
                    } else {
                        this.$parent.applySubTable = [];
                        this.$parent.expendsArray = [];
                        this.$parent.getApplyList();
                    }
                    this.$refs.ticketDialogForm.resetFields();
                    this.ticketDialogVisible = false;
                });
        },
        getTotal() {
            this.form.chargeTotal = (parseFloat(this.form.invoiceActualAmount || 0) + parseFloat(this.form.invoiceTaxAmount || 0)).toFixed(2);
        },

        handleExceed(files, fileList) {
            shortTips(`最多只能上传5个文件！`);
        },
        handleUpload({file, onSuccess}) {
            // console.log(file.type)
            let fileType = [".txt",".gif",".jpg",".png",".doc",".docx",".pdf",".zip",".rar"];
            var isType = false;
            var fileEnd = file.name.substring(file.name.lastIndexOf("."));
            // console.log(fileEnd)
            if(fileType && fileType.length>0){
                for(var i = 0; i < fileType.length; i++){
                    if(fileType[i ]== fileEnd){
                        isType = true;
                        break;
                    }
                }
            }
            if(!isType){
                shortTips("只能上传txt、gif、jpg、png、doc、docx、pdf、zip、rar文件");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }

            const fileName = file.name.slice(0, file.name.lastIndexOf("."));
            if(fileName.length > 50) {
                shortTips("文件名长度不能超过50！");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }
            
            if(file.size / 1024 / 1024 > 2) {
                shortTips("文件大小不得超过2M");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }

            if(this.fileList.findIndex(item => item.name == file.name) >= 0) {
                shortTips("不要重复上传同一文件！");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }
            
            let formData = new FormData();
            formData.append(`file`, file);

            floatingListService.invoicingAttachmentsAdd(formData).then(res => {
                this.fileList.push({
                    name: file.name,
                    size: file.size,
                    FileId: res,
                })
            }).catch(err => {
                this.$refs.attachmentUpload.uploadFiles.pop();
                if(err.error) shortTips(err.error.description);
            });
        },
        handleRemove(file, fileList) {
            const removeIndex = this.fileList.findIndex(item => item.FileId == file.FileId);
            this.fileList.splice(removeIndex, 1);
        },


        removeDomain(item) {
            var index = this.form.invoiceNos.indexOf(item)
            if (index !== -1) {
                this.form.invoiceNos.splice(index, 1)
            }
        },
        addDomain() {
            if(this.form.invoiceNos.length>=5) {
                return shortTips(`最多能添加5个开票号！`)
            }
            this.form.invoiceNos.push({
                value: '',
                key: Date.now()
            });
        },
    },
}
</script>
<style lang="scss" scope>
.ticket-dialog{
    .el-dialog__body{
        padding-right: 44px;
        max-height: 700px;
        overflow-y: auto;
    }
}
.apply-upload{
    width: 390px;
    .el-upload--text{
        >button{
            width: 100px;
            height: 40px;
            border-radius: 4px;
        }
    }
    .el-upload__input{
        display: none;
    }
}
.input-add-wrap{
    widows: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    >.el-input{
        flex: 1;
    }
    >i {
        display: inline-block;
        width: 42px;
        height: 42px;
        line-height: 42px;
        margin-left: 10px;
        cursor: pointer;
        color: $primary;
        text-align: center;
        font-size: 28px;
        &.red{
            color: $red;
        }
    }

}
</style>